import Immutable, { fromJS, Map as imMap } from 'immutable';

import topicReducer from '../../../reducers/topic';
import { routeRangeSerializer } from '../utils/serializers';

/**
 * Initial state for the vehicle routes.
 * Structure looks like:
 *  { [vehicle_id]: {
        trips: {
          [time-range]: {
            display: <bool>,
            timelineStart: <timestamp>,
            timelineEnd: <timestamp>,
            rawTripData: [
              { location: { lon: <lon>, lat: <lat> },
                timestamp: <timestamp>
             }
            ],
            tripDataFetchedAt: <timestamp>,
            rawDistData: [
              { 'max:odometer': <number>, 'min:odometer': <number> }
            ],
            distDataFetchedAt: <timestamp>,
            rawFuelData: [
              { 'interval:1h': { [<ISO8601>]: { 'group_by:vehicle_id': { [<vehicle_id>]: { 'sum:fuel_consumed': <number> }}}}}
            ],
            fuelDataFetchedAt: <timestamp>
            rawBehaviorData: [
              TODO: fill in
            ],
            behaviorDataFetchedAt: <timestamp>
          }
        },
        timezone: <timezone string, ex. "America/Los_Angeles">,
        panelDisplay: enum('trips', 'curLoc')
      }
 *  }
 * TODO: allow state to be passed in and write tests
 */
const reducers = Object.assign({}, topicReducer, {
  '@@redux/init': () => Immutable.Map(),
  RECEIVED_ROUTES(state, action) {
    const { vehicle_id, startTime, endTime, response } = action;
    return state.mergeIn([vehicle_id, 'trips', routeRangeSerializer(startTime, endTime)], fromJS({
        tripDataFetchedAt: Date.now(),
        rawTripData: response.map(m => ({
          location: m.metrics.position.value.location,
          timestamp: m.updateTime
        }))
    }));
  },
  SET_ROUTE_DISPLAY(state, {vehicle_id, startTime, endTime, display}) {
    // Only one trip can be selected at a time, so we need to filter currently
    // selected trips and switch them off.
    const newState = state.mergeIn([vehicle_id, 'trips'], state.getIn([vehicle_id, 'trips'], imMap()).filter(t => t.get('display')).map(t => t.merge(fromJS({ display: false }))));
    // Now turn on selected trip
    return newState.mergeIn([vehicle_id, 'trips', routeRangeSerializer(startTime, endTime)], fromJS({ display }));
  },
  SET_TIMELINE(state, {vehicle_id, startTime, endTime, timelineStart, timelineEnd}) {
    return state.mergeIn([vehicle_id, 'trips', routeRangeSerializer(startTime, endTime)], fromJS({ timelineStart, timelineEnd }));
  },
  SET_PANEL_DISPLAY(state, {vehicle_id, panelDisplay}) {
    return state.setIn([vehicle_id, 'panelDisplay' ], panelDisplay);
  },
  RECEIVED_DIST_TRAVELED(state, action) {
    const { vehicle_id, startTime, endTime, response } = action;
    return state.mergeIn([vehicle_id, 'trips', routeRangeSerializer(startTime, endTime)], fromJS({
      distDataFetchedAt: Date.now(),
      rawDistData: response.results
    }));
  },
  RECEIVED_FUEL_CONSUMED(state, action) {
    const { vehicle_id, startTime, endTime, response } = action;
    return state.mergeIn([vehicle_id, 'trips', routeRangeSerializer(startTime, endTime)], fromJS({
      fuelDataFetchedAt: Date.now(),
      rawFuelData: response.results
    }));
  },
  RECEIVED_BEHAVIOR(state, action) {
    const { vehicle_id, startTime, endTime, response } = action;
    return state.mergeIn([vehicle_id, 'trips', routeRangeSerializer(startTime, endTime)], fromJS({
      behaviorDataFetchedAt: Date.now(),
      rawBehaviorData: response.results
    }));
  },
  RECEIVED_TIMEZONE_FROM_LOCATION(state, {vehicle_id, response, usersTimezone}) {
    let timezone;
    if (typeof response === 'string') {
      timezone = response;
    }
    else {
      console.warn(response);  // eslint-disable-line no-console
      timezone = usersTimezone;
    }
    return state.mergeIn([vehicle_id], fromJS({ timezone }));
  }
});

export default function routesReducer(state=Immutable.Map(), action) {
  const handler = reducers[action.type];
  if (handler) {
    return handler(state, action);
  } else {
    return state;
  }
}
