import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
// import { Polyline, Marker } from 'react-google-maps';
import { sortedIndexBy, values } from 'lodash';

import MapBundledControl from './MapBundledControl';
import { components as coreComponents } from '@au/core';
import BehaviorMarker from './BehaviorMarker';
import { routeRangeDeserializer } from '../stores/routes/utils/serializers';
import { sharedTripSelectors, colorTrips, SharedTripsNetworkWrapper } from './utils/assetTrips';
import { avgDrivingScoreSelector } from '../stores/routes/utils/selectors';

const { elements: { BinaryFontButton, AuComponent, AuMap, AutoIntl } } = coreComponents;

import styles from '../css/components/asset_trips_map.module.scss';
// import excessiveSpeedingImg from '../images/speeding.svg';
// import hardAccelerationImg from '../images/hard_acceleration.svg';
// import hardBrakingImg from '../images/hard_braking.svg';
// import grayFlagImg from '../images/flag_gray.svg';
// import flagImg from '../images/flag.svg';
// import grayVehicleImg from '../images/vehicle_gray.svg';
// import vehicleImg from '../images/vehicle.svg';

const UNFOCUSED_STROKE_COLOR = '#D4D4D4';

// TODO: write debug function that prints a warning if the distance reported
// differs by around 10% or more from the distanced plotted on the map.

// NOTE: this is deprecated!
export default class NetworkWrapper extends SharedTripsNetworkWrapper {

  static propTypes = {
    vehicle_id: T.string,
    trips: IPT.map,
    timezone: T.string,
  }

  // Separates the fetch requests from the route map to avoid re-renders
  render() {
    const { vehicle_id, trips, timezone, actions, groupId } = this.props;
    if (!timezone) {
      return false;
    }

    // Fetch any missing routes
    trips.forEach((trip, key) => {
      const [startTime, endTime] = routeRangeDeserializer(key);

      if (!trip.has('rawTripData')) {
        actions.fetchRoutes(vehicle_id, startTime, endTime, groupId);
      }
      // * TODO: bring back
      // if (!trip.has('rawDistData')) {
      //   actions.fetchDistanceTraveled(vehicle_id, startTime, endTime);
      // }
    });
    const assetTrips = colorTrips(timezone, trips).filter(t => t.has('rawTripData'));
    return <AssetTripsMap {...this.props} trips={assetTrips} />;
  }
}

export class AssetTripsMap extends AuComponent {

  static propTypes = {
    vehicle_id: T.string,
    trips: IPT.map,
    timezone: T.string,
    geofences: T.object,
    shared: T.object,
    interactiveLegend: T.bool
  }

  static defaultProps = {
    shared: {},
    interactiveLegend: true
  }

  state = {
    zoom: 0,
    showTypeSPEED: true,
    showTypeACCELERATE: true,
    showTypeDECELERATE: true
  }

  componentDidUpdate(prevProps) {
    if (this._map) {
      const { trips } = this.props;
      const prevTrips = prevProps.trips;
      // Process new incoming data
      trips.forEach((trip, key) => sharedTripSelectors.get(key)(trip));
      // Check if we need to refit the zoom to new content
      if (trips.size > 0 && !trips.keySeq().equals(prevTrips.keySeq())) {
        this.zoomToContent();
      }
    }
  }

  componentDidMount() {
    window.addEventListener('trips.mapZoomToContent', this.zoomToContent);
  }

  componentWillUnmount() {
    window.removeEventListener('trips.mapZoomToContent', this.zoomToContent);
  }

  onMapMounted = this.onMapMounted.bind(this);
  onMapMounted(map) {
    if (map) {
      this._map = map;
      this.props.trips.forEach((trip, key) => {
        // Prep selectors
        sharedTripSelectors.get(key)(trip);
      });
      setTimeout(() => this.zoomToContent(), 0);
    }
  }

  zoomToContent = this.zoomToContent.bind(this);
  zoomToContent() {
    // Zooms to all displayed trips
    if (!this._map) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    for (let [key, trip] of this.props.trips.entries()) {
      const paths = sharedTripSelectors.get(key)(trip);
      for (let loc of paths.processedTripPath) {
        bounds.extend(loc);
      }
    }

    if (!bounds.isEmpty()) {
      // Trigger a Google maps resize event. This is needed if mounted to a
      // hidden div.
      window.dispatchEvent(new Event('resize'));

      this._map.fitBounds(bounds);
    }
  }

  handleZoomChanged = this.handleZoomChanged.bind(this);
  handleZoomChanged(zoom) {
    this.setState({ zoom });
  }

  // TODO: remove zoom control on landscape?

  // Start: function I am keeping around if we need to use these for a better
  // performing animation of the vehicle.
  onMarkerMounted(marker) {
    if (marker && this._marker !== marker) {
      this._marker = marker;
      //this.animateSubTrip(marker, key);
      //this.animateSubTripViaState(key);
    }
  }

  animateSubTripViaState(key) {
    const trip = this.props.trips.get(key);
    const subTrip = sharedTripSelectors.get(key)(trip).processedSubTripPath;
    this._animateSubTripViaState(key, subTrip);
  }

  _animateSubTripViaState(key, subTrip, idx=0) {
    if (idx > subTrip.length - 1) {
      return;
    }
    this.setState({ [key]: subTrip[idx++] });
    setTimeout(() => this._animateSubTripViaState(key, subTrip, idx), 200);
  }

  animateSubTrip(marker, key) {
    const trip = this.props.trips.get(key);
    const subTrip = sharedTripSelectors.get(key)(trip).processedSubTripPath;
    const gMarker = marker.state.__SECRET_MARKER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
    this._animateSubTrip(gMarker, subTrip);
  }

  _animateSubTrip(marker, subTrip, idx=0) {
    if (idx > subTrip.length - 1) {
      return;
    }
    marker.setPosition(subTrip[idx++]);
    setTimeout(() => this._animateSubTrip(marker, subTrip, idx), 200);
  }
  // End
  
  renderPolyline() {
  // renderPolyline(key, strokeColor, path, zIndex=2) {
    // return (
    //   <Polyline
    //     key={key}
    //     path={path}
    //     visible={true}
    //     editable={false}
    //     draggable={false}
    //     options={{
    //       strokeColor,
    //       strokeWeight: 4,
    //       strokeOpacity: 1,
    //       zIndex
    //     }}
    //   />
    // );

    return false;
  }

  toggleState(key) {
    this.setState(prevState => ({ [key]: !prevState[key] }));
  }

  renderLegendItem(stateKey, imageUrl, displayId, value='') {
    const { interactiveLegend } = this.props;
    const checked = this.state[stateKey];

    return (
      <div className={styles.item}>
        { interactiveLegend &&
          <BinaryFontButton
            initial="check-square"
            next="square-o"
            onClick={() => this.toggleState(stateKey)}
            onInitial={checked}
          />
        }
        {/* <img className={styles.icon} src={imageUrl} /> */}
        <AutoIntl displayId={displayId} className={styles.label} />
        <span className={styles.value}>{value}</span>
      </div>
    );
  }

  renderLegend(drivingScore) {
    return (
      <div key="legend" className={styles.legend}>
        <div className={styles.header}>
          <AutoIntl displayId="au.trips.map.legend.drivingScore" />
          <div className={styles.score}>{ drivingScore }</div>
        </div>
        {/* { this.renderLegendItem('showTypeSPEED',      excessiveSpeedingImg, 'au.trips.map.legend.excessiveSpeeding', eventCounters['SPEED'] || 0) }
        { this.renderLegendItem('showTypeACCELERATE', hardAccelerationImg,  'au.trips.map.legend.hardAcceleration',  eventCounters['ACCELERATE'] || 0) }
        { this.renderLegendItem('showTypeDECELERATE', hardBrakingImg,       'au.trips.map.legend.hardBraking',       eventCounters['DECELERATE'] || 0) } */}
      </div>
    );
  }

  mouseOverBehavior(key) {
    this.setState(prevState => {
      if (prevState.showInfoWindowFor) {
        // Was hovering over another behavior
        clearTimeout(this._infoWindowTimeout);
        this._infoWindowTimeout = null;
      }
      return { showInfoWindowFor: key };
    });
  }

  mouseOutBehavior() {
    this._infoWindowTimeout = setTimeout(() => {
      clearTimeout(this._infoWindowTimeout);
      this._infoWindowTimeout = null;
      this.setState({ showInfoWindowFor: null });
    }, 2000);
    // TODO: create global timeout for all hover events
  }

  render() {
    const { vehicle_id, trips, shared } = this.props;

    const numPlayback = values(shared).filter(x => x.playbackTime).length;

    const eventCounters = {};
    const otherElements = [];

    // for (let [key, geofence] of geofences.entries()) {
    //   const { gCir, gPoly, gRect }  = sharedGeoFenceSelectors.run(geofence).toJS();
    //   otherElements.push(
    //     <GeoFenceOutline key={key} gCir={gCir} gPoly={gPoly} gRect={gRect} />
    //   );
    // }

    for (let key of trips.keySeq().toArray().sort()) {
      const trip = trips.get(key);
      const paths = sharedTripSelectors.get(key)(trip);
      const playbackTime = (shared[key] || {}).playbackTime;

      if (paths.processedTripPath.length <= 1) {
        continue;
      }

      // TODO: handle small number of close points as a single marker on the map
      // Need design specs for this

      // Determine colors
      // const flagImgUrl = numPlayback ? grayFlagImg : flagImg;
      // const vehicleImgUrl = numPlayback ? grayVehicleImg : vehicleImg;
      const rowStrokeColor = trips.getIn([key, 'color']);
      let tripStrokeColor, subTripStrokeColor;
      if (numPlayback && !playbackTime) {
        // This trip is not being played back
        tripStrokeColor = UNFOCUSED_STROKE_COLOR;
        subTripStrokeColor = UNFOCUSED_STROKE_COLOR;
      } else {
        tripStrokeColor = paths.processedSubTripPath ? UNFOCUSED_STROKE_COLOR : rowStrokeColor;
        subTripStrokeColor = rowStrokeColor;
      }

      otherElements.push(
        this.renderPolyline(key, tripStrokeColor, paths.processedTripPath, 2)
      );
      if (paths.processedSubTripPath) {
        otherElements.push(
          this.renderPolyline(key+'subtrip', subTripStrokeColor, paths.processedSubTripPath, 3)
        );
      }

      // otherElements.push(
      //   <Marker
      //     key={key+'car'}
      //     ref={m => this.onMarkerMounted(m, key)} // TODO: remove if we are not going to do direct modifications
      //     position={paths.processedTripPath[0]}
      //     icon={{
      //       url: vehicleImgUrl,
      //       size: new google.maps.Size(23, 27),
      //       origin: new google.maps.Point(0, 0),
      //       anchor: new google.maps.Point(11, 27),
      //       scaledSize: new google.maps.Size(23, 27)
      //     }}
      //     zIndex={3} /* should be above the flag marker (zIndex=2) */
      //   />
      // );

      // Add flag marker
      // otherElements.push(
      //   <Marker
      //     key={key+'flag'}
      //     position={paths.processedTripPath[paths.processedTripPath.length-1]}
      //     icon={{
      //       url: flagImgUrl,
      //       size: new google.maps.Size(24, 24),
      //       origin: new google.maps.Point(0, 0),
      //       anchor: new google.maps.Point(12, 24),
      //       scaledSize: new google.maps.Size(24, 24)
      //     }}
      //     zIndex={2} /* should be below the car marker (zIndex=3) */
      //   />
      // );

      // Calculate playback position
      if (playbackTime) {
        // Improvement: create better timeseries datastructure instead of doing rapid binary searches
        const search = paths.processedSubTrip || paths.processedTrip;
        // I could find the difference in time and pick the closer value, but for now keeping it simple
        let playbackIdx = sortedIndexBy(search, { timestamp: playbackTime}, x => x.timestamp);

        // Don't go past the last known point
        if (playbackIdx === search.length) {
          playbackIdx = search.length - 1;
        }

        // Add vehicle marker
        // const playbackPosition = search[playbackIdx].location;
        // otherElements.push(
        //   <Marker
        //     key={key+'playbackcar'}
        //     ref={m => this.onMarkerMounted(m, key)} // TODO: remove if we are not going to do direct modifications
        //     position={playbackPosition}
        //     icon={{
        //       url: `/images/vehicle.svg`,
        //       size: new google.maps.Size(23, 27),
        //       origin: new google.maps.Point(0, 0),
        //       anchor: new google.maps.Point(11, 27),
        //       scaledSize: new google.maps.Size(23, 27)
        //     }}
        //     zIndex={5} /* should be on top of all other markers */
        //   />
        // );
      }

      // Place behavior markers
      const rawBehaviorData = trip.get('rawBehaviorData');
      if (!rawBehaviorData || !paths.processedTrip) {
        continue;
      }

      // Use negative zIndex for behaviors markers to they are below all other
      // markers, also to keep order of events along route.
      let zIndex = -rawBehaviorData.size;
      for (let behavior of rawBehaviorData.toJS()) {
        const { kind, id: behavior_id } = behavior;

        // Check if we should show the marker
        if (!this.state[`showType${kind}`]) {
          continue;
        }

        if (!(kind in eventCounters)) {
          eventCounters[kind] = 0;
        }
        // increment event counter
        eventCounters[kind]++;

        // For now pick the first location even if it might be that the next is
        // actually closer to the alert in time.
        // TODO: If we use this method improve by selecting the closest point in
        // time.
        let idx = sortedIndexBy(paths.processedTrip, { timestamp: new Date(behavior.event_start).getTime() }, x => x.timestamp);
        if (idx === paths.processedTrip.length) {
          idx--;
        }
        const location = paths.processedTrip[idx].location;

        otherElements.push(
          <BehaviorMarker
            key={`${behavior_id}`}
            showTooltip={this.state.showInfoWindowFor === behavior_id}
            kind={kind}
            onMouseOver={() => this.mouseOverBehavior(behavior_id)}
            onMouseOut={() => this.mouseOutBehavior(behavior_id)}
            duration={behavior.duration}
            event_start={behavior.event_start}
            timezone={this.props.timezone}
            zIndex={zIndex++}
            {...location}
          />
        );
      }
    }

    const drivingScore = avgDrivingScoreSelector.get(vehicle_id)({ trips });
    otherElements.push(this.renderLegend(drivingScore, eventCounters));

    // My attempt to try to fix Chrome rendering issues where portions of trips
    // would be rendered in th wrong place on the map randomly.
    // This is still an issue, but it doesn't seem to happen as often
    /*
    setTimeout(() => {
      if (this._map) {
        google.maps.event.trigger(this._map, 'resize');
      }
    }, 20);
    */
    return (
      <AuMap
        key={`asset-trips-map_${vehicle_id}`}
        otherElements={otherElements}
        onMapMounted={this.onMapMounted}
        zoom={this.state.zoom}
        onZoomChanged={() => this.handleZoomChanged(this._map.getZoom())}
        customZoomControl={<MapBundledControl
          map={this.state}
          handleZoomChanged={this.handleZoomChanged}
        />}
      />
    );
  }
}
