// import React from 'react';
// import * as T from 'prop-types';

// import AuComponent from '@au/core/lib/components/elements/AuComponent';

export class AuClusterRenderer {

  render({ count, position }) {
    const color = "#6384AA";
    const svg = window.btoa(`
  <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity=".7" r="70" />    
  </svg>`);

    return new google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(70, 70),
      },
      label: {
        text: String(count),
        color: '#FFFFFF',
        fontSize: "14px",
      },
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    })
  }

}

/*export default class AuMarkerClusterer extends AuComponent {

  static propTypes = {
    type: T.oneOf(['circles', 'places'])
  };

  static defaultProps = {
    type: 'places'
  }

  render() {
    let { type, minimumClusterSize, zoomOnClick, gridSize, maxZoom, onClick } = this.props;

    const commonMarkerClusterProps = {
      averageCenter: true,
      gridSize: gridSize,
      maxZoom: maxZoom,
      minimumClusterSize: minimumClusterSize,
      styles: clusterIconStyles[type]
    };

    let markerClusterProps;
    if (onClick) {
      markerClusterProps = {
        ...commonMarkerClusterProps,
        onClick: onClick,
        zoomOnClick: false
      };
    }
    else {
      markerClusterProps = {
        ...commonMarkerClusterProps,
        zoomOnClick: zoomOnClick
      };
    }

    // return (
    //   <MarkerClusterer {...markerClusterProps}>
    //     {this.props.children}
    //   </MarkerClusterer>
    // );

    return false;
  }
}*/