import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';

import Reducer from './reducers/index';

let store,
    uninitialized = true;
if (uninitialized) {
  // Taken from index.js FIXME: stay DRY
  const middlewares = [thunkMiddleware];
  if (process.env.NODE_ENV === 'development') {
    const loggerMiddleware = createLogger({
      logger: {
        log: function() {
           if (window.reduxLogEnabled) {
            console.log.apply(this, arguments); // eslint-disable-line no-console
           }
        }
      }
    });
    middlewares.push(loggerMiddleware);
  }

  store = createStore(Reducer, applyMiddleware(...middlewares));
  uninitialized = false;
}

export default store;
