import { breadcrumbsConnectDefaults } from '../utils/ConnectDefaults';
import { VEHICLE_ID_PROP_NAME } from '../constants';
import injectParams from '../utils/injectParams';
import Asset from '../components/Asset';
import PropProvider from '../PropProvider';

export default injectParams(breadcrumbsConnectDefaults(
  Asset,
  (state, ownProps) => PropProvider(state).Asset(ownProps[VEHICLE_ID_PROP_NAME]),
  'au.section.title.vehicles'
));
