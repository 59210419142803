import { createSelector } from 'reselect';
import { sortedIndexBy, sortedLastIndexBy } from 'lodash';
import moment from 'moment-timezone';

import { immutableSelectorCreator, genKeyedSelector } from '@au/core/lib/selectors/general';
import { unifyCoordinates } from '@au/core/lib/utils/maps';
import { components as coreComponents } from '@au/core';

import { routeRangeSerializer, routeRangeDeserializer } from '../../stores/routes/utils/serializers';

const { elements: { AuComponent } } = coreComponents;

const DAYS_BACK = 7;
const DAY_SHIFT = Object.freeze([0, 1]);
const DEFAULT_TRIP_COLOR = '#425772';

// Memoized functions for calculating trip meta data
export function genTripSelector() {

  // Converts the raw data return from fields into the basic form the other
  // selectors receive.
  const processRawTripSelector = immutableSelectorCreator(
    [ trip => trip.get('rawTripData') ],
    rawTripData => {
      let processedTripData = rawTripData.toJS();

      // Fields endpoint ORs existance of fields, filter out docs that have partial data
      processedTripData = processedTripData.filter(x => x.timestamp && x.location);

      // Reorder by timestamp
      processedTripData.sort((a,b) => a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0);

      // Remove duplication locations in a row
      let prevLoc = { lat: 0, lon: 0 };
      for (let r of processedTripData) {
        if (r.location.lat === prevLoc.lat && r.location.lon === prevLoc.lon) {
          delete r.location;
        } else {
          prevLoc = r.location;
        }
      }
      processedTripData = processedTripData.filter(x => x.location);

      // Concert timestamp to milliseconds to use lodash's search functions
      for (let r of processedTripData) {
        r.timestamp = (new Date(r.timestamp)).getTime();
      }

      // Make the coordinates Google maps compatible
      unifyCoordinates(processedTripData.map(x => x.location));

      return processedTripData;
    }
  );

  // Slices the processed trip data based on timeline timestmap range
  const processSubTripSelector = createSelector(
    [ processRawTripSelector, trip => trip.get('timelineStart'), trip => trip.get('timelineEnd') ],
    (processedTripData, timelineStart, timelineEnd) => {
      if (!timelineStart || !timelineEnd) {
        return;
      }

      // Find indices of the processed path
      const subTripStartIdx = sortedIndexBy(processedTripData, {timestamp: timelineStart}, t => t.timestamp);
      const subTripEndIdx = sortedLastIndexBy(processedTripData, {timestamp: timelineEnd}, t => t.timestamp);

      if (subTripStartIdx === 0 && subTripEndIdx === processedTripData.length) {
        // If the user selects the full timeline then that is the same as no selection
        return;
      }

      return processedTripData.slice(subTripStartIdx, subTripEndIdx);
    }
  );


  return createSelector(
    [ processRawTripSelector, processSubTripSelector ],
    (processedTrip, processedSubTrip) => ({
      processedTrip,
      processedTripPath: processedTrip.map(x => x.location),
      processedSubTrip,
      processedSubTripPath: processedSubTrip ? processedSubTrip.map(x => x.location) : undefined
    })
  );
}

export function colorTrips(timezone, trips) {
  // Add in the appropriate trip color for each time range
  let coloredTrips = trips.asMutable();
  for (let range of calculateTimeRanges({timezone})) {
    if (coloredTrips.has(range.toString())) {
      const tripWithColor = coloredTrips.get(range.toString()).set('color', range.color);
      coloredTrips.set(range.toString(), tripWithColor);
    }
  }
  return coloredTrips.asImmutable();
}

export function getLatestTrip(timezone, trips) {
  // Finds the latests trip with GPS points. Returns false if trip rows are not
  // created or every row doesn't have a 'rawTripData' entry. Only returns an
  // array if we have a valid latest trip or no valid trip data exists.
  // Valid trip data includes both a timestamp and location.

  // Check that we have a timezone
  if (!timezone) {
    return false;
  }

  // Check that we have all trips and their behaviors
  for (let range of calculateTimeRanges({timezone})) {
    const trip = trips.get(range.toString());
    if (!trip || !trip.has('rawTripData')) {
      return false;
    }
  }

  for (let key of trips.keySeq().toArray().sort().reverse()) {
    const trip = trips.get(key);
    const rawTripData = trip.get('rawTripData');

    if (!rawTripData) {
      continue;
    }
    if (rawTripData.size <= 2) {
      continue;
    }
    const hasAllKeys = rawTripData.reduce(
      (hasKeys, map) => hasKeys && map.has('timestamp') && map.has('location'),
      true
    );
    if (!hasAllKeys) {
      continue;
    }

    return [trip, key];
  }

  return [];
}

// Shared trip selector that multiple components can use (AssetTripsMap & AssetTripsTable).
// Automatically creates a new selector for a new key.
export const sharedTripSelectors = genKeyedSelector(genTripSelector, key => key.toString());


function rangeToString() {
  return routeRangeSerializer(this.startTime, this.endTime);
}

export const calculateTimeRanges = createSelector(
  // I need to pass an object to trigger equality check on the arguments. The
  // date argument ensures this is only recalculated every hour at most.
  [ params => params.timezone, () => Math.floor(Date.now() / (1000*60*60)) ],
  timezone => {
    // timezone is expected in the form of IANA naming conection (e.g. "America/Los_Angeles")
    // zeit being the moment object we are manipulating for each shift
    const zeit = moment.tz(Date.now(), timezone).startOf('day').subtract(1, 'days');
    const ranges = [];

    for (let i=0; i<DAYS_BACK; ++i) {
      ranges.push({
        startTime: zeit.hour(DAY_SHIFT[0]).toDate(),
        endTime: zeit.hour(DAY_SHIFT[1]).toDate(),
        color: DEFAULT_TRIP_COLOR,
        toString: rangeToString
      });

      // Go back for 1 day
      zeit.subtract(1, 'day');
    }
    return ranges;
  }
);

const initializedAllTrips = {};
export class SharedTripsNetworkWrapper extends AuComponent {
  // Inherited component that will load use the vehicle location to get the
  // timezone for the trips and creates the trip slots in the store.

  // Separates the fetch requests from the route map to avoid re-renders, reusability
  // and separation of concerns.

  componentDidMount() {
    const timezone = this.props.timezone;
    this.getTimezone(this.props);
    if (timezone) {
      this.initializeTimezone(timezone);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { actions, vehicle_id, timezone: currentTimezone } = this.props;

    // If we don't current have the timezone check if next props has location and fetch it
    if (!currentTimezone) {
      this.getTimezone(nextProps);
    }

    // If the timezone changes initialize (add trip entries)
    if (currentTimezone !== nextProps.timezone) {
      this.initializeTimezone(nextProps.timezone);
    }

    // If we have loaded all the trips set the display true on the latest
    if (nextProps.timezone && nextProps.trips && !initializedAllTrips[vehicle_id]) {
      const latestTripDetails = getLatestTrip(nextProps.timezone, nextProps.trips);
      if (latestTripDetails) {
        const latestTripKey = latestTripDetails[1];
        if (latestTripKey) {
          const [ startTime, endTime ] = routeRangeDeserializer(latestTripKey);
          actions.setRouteDisplay(vehicle_id, startTime, endTime);
        }
        else {
          actions.setPanelDisplay(vehicle_id, 'curLoc');
        }
        initializedAllTrips[vehicle_id] = true;
      }
    }
  }

  getTimezone({ vehicleLocation, timezone, actions, vehicle_id }) {
    if (!timezone && vehicleLocation) {
      actions.fetchTimezoneFromLocation(vehicle_id, vehicleLocation.toJS(), moment.tz.guess());
    }
  }

  initializeTimezone(timezone) {
    // Called once the wrapper gets the timezone for all the trips
    const { actions, vehicle_id, trips } = this.props;

    // Create trip entries in store and local state
    for (let range of calculateTimeRanges({timezone})) {
      if (!trips.has(range.toString())) {
        // Need to check if trip already exist since the user could have visited this page before
        actions.setRouteDisplay(vehicle_id, range.startTime, range.endTime, false);
      }
    }
  }


}
