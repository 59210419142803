import React, { Component } from 'react';
import cn from 'classnames';
import * as T from 'prop-types';
import routeStore from '../stores/routes/init';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { VEHICLE_ID_PROP_NAME } from '../constants';
import { getTabs } from '../utils/asset';
import Navigation from '@au/core/lib/components/elements/Navigation';
import AssetData from '../containers/AssetData';
import AssetMetrics from '../containers/AssetMetrics';
import AssetCurrentLocation from '../containers/AssetCurrentLocation';
import LatestAssetTrip from '../containers/LatestAssetTrip';
import styles from '../css/components/asset.module.scss';

export function getAssetTabs(vehicle_id, notifications) {
  const visibleTabs = getTabs(vehicle_id);
  [
    {
      key: 'AssetMetrics',
      component: AssetMetrics,
      className: cn({
        [styles.warning]: notifications
      })
    },
    {
      key: 'CurrentLocation',
      component: AssetCurrentLocation,
    },
    {
      key: 'LatestAssetTrip',
      component: LatestAssetTrip,
      store: routeStore
    },
    {
      key: 'AssetData',
      component: AssetData,
    }
  ].forEach(tc => {
    if(visibleTabs.has(tc.key)) {
      visibleTabs.set(tc.key, {...visibleTabs.get(tc.key), ...tc});
    }
  });

  return visibleTabs;
}

export default class Asset extends Component {

  static propTypes = {
    [VEHICLE_ID_PROP_NAME]: T.string.isRequired,
    notifications: T.bool
  };

  static defaultProps = {
    notifications: false
  }

  getNavLinks(vehicle_id, selectedTab, notifications, store) {
    const links = [];
    getAssetTabs(vehicle_id, notifications, store).forEach((tc, key) => {
      if (key === selectedTab) {
        links.push({ labelId: tc.linkLabelId, destination: tc.linkDestination, onClick: e => e.preventDefault(), selected: true, className: tc.className });
      }
      else {
        links.push({ labelId: tc.linkLabelId, destination: tc.linkDestination, onClick: e => { e.preventDefault(); this.props.actions.openVehicleDetails(vehicle_id, key); }, className: tc.className });
      }
    });

    return links;
  }
  render() {
    let { [VEHICLE_ID_PROP_NAME]: vehicle_id, vehicleLoaded, children, popoutContained, selectedTab, vin, notifications, location, groupId } = this.props;

    if (!vehicleLoaded) {
      return false;
    }

    if (popoutContained) {
      const tabChildren = getAssetTabs(vehicle_id, notifications);
      const tabDef = tabChildren.has(selectedTab) ? tabChildren.get(selectedTab): tabChildren.get('AssetMetrics');
      const TabChildComponent = tabDef.component;
      const tabChildProps = { [VEHICLE_ID_PROP_NAME]: vehicle_id };
      if(tabDef.key == 'LatestAssetTrip') {
        tabChildProps.store = tabDef.store;
        tabChildProps.vehicleLocation = location;
        tabChildProps.groupId = groupId;
      }
      children = <TabChildComponent {...tabChildProps} />;
    }

    let navLinks = this.getNavLinks(vehicle_id, selectedTab, notifications);

    return (
      <div className={styles.container}>
        <div className={styles.header_container}>
          <div className={styles.header_inner}>
            <AutoIntl displayId="au.vehicle.vin" className={styles.header} tag="h2" />
            <div className={styles.sub_header}>{vin}</div>
          </div>
        </div>
        <div>
          <Navigation
            navLinks={navLinks}
          />
        </div>
        {children}
      </div>
    );
  }
}
