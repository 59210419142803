import { VEHICLE_ID_PROP_NAME } from '../constants';
import injectParams from '../utils/injectParams';
import PropProvider from '../PropProvider';
import { breadcrumbsConnectDefaults } from '../utils/ConnectDefaults';
import AssetData from '../components/AssetData';

export default injectParams(breadcrumbsConnectDefaults(
  AssetData,
  (state, ownProps) => PropProvider(state).AssetData(ownProps[VEHICLE_ID_PROP_NAME]),
  'au.section.title.vehicles',
  'details'
));
