export function getTabs(vehicle_id) {
  let tabs = new Map();
  [
    {
      key: 'AssetMetrics',
      isVisible: true,
      linkLabelId: "au.vehicle.section.title.metrics",
      linkDestination: `/vehicles/${vehicle_id}/metrics`,
    },
    {
      key: 'CurrentLocation',
      isVisible: true,
      linkLabelId: "au.vehicle.section.title.map",
      linkDestination: `/vehicles/${vehicle_id}/current-location`
    },
    {
      key: 'LatestAssetTrip',
      isVisible: true,
      linkLabelId: "au.vehicle.section.title.trips",
      linkDestination: `/vehicles/${vehicle_id}/latest-trip`
    },
    {
      key: 'AssetData',
      isVisible: true,
      linkLabelId: "au.vehicle.section.title.data",
      linkDestination: `/vehicles/${vehicle_id}/data`
    }
  ].filter(tc => tc.isVisible).forEach(tc => tabs.set(tc.key, tc));

  return tabs;
}