export default function AuSpecs(builder) {
  const s = builder;

  const suggestionFieldSpec = s.map({
    target: s.string(),
    items: s.mapOf(s.map({
      count: s.number(),
      target: s.string(),
      mapsTo: s.string()
    })),
    count: s.number(),
    mapped: s.bool(),
    mapsWith: s.string()
  });

  const geofenceSpec = s.map({
    id: s.string(),
    fleet_id: s.string(),
    name: s.string(),
    timezone: s.string(),
    bounding_polygon: s.collection(s.map({
      lat: s.number(),
      lon: s.number()
    })),
    data: s.map({})
  });

  const assetSpec = s.map({
    oil_temp: s.string(),
    oil_level: s.string(),
    coolant_temp: s.string(),
    location: s.map({
      lon: s.number(),
      lat: s.number(),
    }),
    activity_status: s.string(),
    oil_level_status: s.string(),
    ignition_status: s.string(),
    timestamp: s.string(),
    location_uncertainty: s.number(),
    speed: s.number(),
    avg_speed: s.number(),
    odometer: s.number(),
    fuel_consumed: s.number(),
    fuel_level: s.number(),
    model_year: s.string(),
    model: s.string(),
    tire_pressure: s.string(),
    fleet_name: s.string(),
    vin: s.string(),
    imei: s.string(),
    make: s.string(),
    vehicle_id: s.string(),
    tenant_id: s.string(),
    max_speed: s.number(),
    overspeed: s.number(),
    fleet_id: s.string(),
    service_required: s.string(),
    needs_service: s.bool(),
    pid_plugged_in: s.string(),
    distance_travelled: s.number(),
    heading: s.number(),
    geofenceHistory: s.mapOf(s.map({
      data: s.collection(s.map({
        direction: s.string(),
        duration: s.number(),
        timestamp: s.number()
      }))
    }))
  });
  return {
    persistentState: s.map({
      groupId: s.string(),
      settings: s.map({
        partition: s.string(),
        partitions: s.mapOf(s.map({
          accountId: s.string(),
          groupId: s.string(),
          sourceId: s.string()
        }))
      })
    }, 'persistentState'),
    theVehiclesResults: s.map({
      results: s.required(s.map({
        'last:vehicle_id': s.required(s.mapOf(s.map({
          speed: s.number(),
          avg_speed: s.number(),
          fuel_level: s.number(),
          odometer: s.number(),
          ignition_status: s.string(),
        })))
      }))
    }, 'theVehiclesResults'),
    storeState: s.map({
      nav: s.map({
        open: s.bool(),
        responsive: s.bool(),
      }),
      fleets: s.mapOf(s.map({
        fleet_id: s.string(),
        fleet_name: s.string(),
        fleet_count: s.number()
      })),
      users: s.mapOf(s.map({
        userId: s.string(),
        username: s.string(),
        email: s.string(),
        permissions: s.collection(s.map({
          entity: s.string(),
          rights: s.collection(s.string())
        })),
        scope: s.string(),
        password: s.string(),
        lastLogin: s.string(),
      })),
      devices: s.mapOf(s.map({
        deviceId: s.string(),
        vehicleId: s.string(),
        serialNumber: s.string(),
        vehicleName: s.string(),
        lastUpdate: s.string(),
        active: s.bool(),
      })),
      mobileApps: s.mapOf(s.map({
        appId: s.string(),
        vehicleId: s.string(),
        phoneNumber: s.string(),
        vehicleName: s.string(),
        lastUpdate: s.string(),
        active: s.bool(),
      })),
      tableState: s.mapOf(s.map({
        sort: s.map({
          columnId: s.string(),
          direction: s.string(),
        }),
        filter: s.string(),
      })),
      tableDefs: s.mapOf(s.map({
        entity: s.string(),
        tableId: s.string(),
        tableTitleId: s.string(),
        tablePkField: s.string(),
        height: s.number(),
        width: s.number(),
        filterPlaceholderId: s.string(),
        filter: s.string(),
        filterDisplay: s.bool(),
        useFilterEntity: s.bool(),
        downloadDisplay: s.bool(),
        columnDefs: s.collection(s.map({
          property: s.string(),
          labelId: s.string(),
          searchable: s.bool(),
          sortable: s.bool(),
          width: s.number(),
          flexGrow: s.number(),
          display: s.bool(),
          unitType: s.string(),
          unitLabel: s.string(),
          className: s.string(),
          formatter: s.anything() // string, dict
        })),
        sort: s.map({
          columnId: s.string(),
          direction: s.string(),
        }),
      })),
      analytics: s.map({
        timeBack: s.string(),
        fuelEconomy: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
        distanceTraveled: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
        fuelConsumed: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
      }),
      dashboardAnalytics: s.map({
        timeBack: s.string(),
        fuelEconomy: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
        distanceTraveled: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
        fuelConsumed: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
      }),
      systemOfUnits: s.string(),
      locale: s.string(),
      map: s.map({
        center: s.map({
          lat: s.number(),
          lng: s.number(),
        }),
        zoom: s.number(),
        bounds: s.map({
          north: s.number(),
          south: s.number(),
          east: s.number(),
          west: s.number(),
        }),
        initialBounds: s.map({
          north: s.number(),
          south: s.number(),
          east: s.number(),
          west: s.number(),
        }),
        mmapZoom: s.number(),
        hoveredAsset: s.string(),
        hoveredInTime: s.number(),
        fullScreen: s.bool(),
      }),
      assetAnalytics: s.mapOf(s.map({
        fuelEconomy: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
        distanceTraveled: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
        fuelConsumed: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
      })),
      geofences: s.mapOf(geofenceSpec),
      fetchTopics: s.mapOf(s.map({
        isFetching: s.bool(),
        startedFetchAt: s.number(),
        finishedFetchAt: s.number(),
        status: s.string(),
        version: s.number()
      })),
      streamTopics: s.mapOf(s.map({
        isStreaming: s.bool(),
        startedStreamingAt: s.number(),
        finishedStreamingAt: s.number(),
        status: s.string()
      })),
      screenWidth: s.string(),
      suggestions: s.mapOf(suggestionFieldSpec),
      filteredEntities: s.map({
        assets: s.anything(), // Is a set
        alerts: s.anything() // Is a set
      }),
      entities: s.map({
        assets: s.mapOf(assetSpec),
        alerts: s.mapOf(s.map({
          kind: s.string(),
          message: s.string(),
          vehicle_id: s.string(),
          vin: s.string(),
          id: s.string(),
          timestamp: s.string(),
        })),
      })
    }, 'StoreState')
  };
}
