import React from 'react';
import { connect } from 'react-redux';

import PropProvider from '../PropProvider';
import ConnectedSelectInputSourceDialog from './SelectInputSourceDialog';
import { defaultMapDispatchToProps, defaultMergeProps, defaultConnectEqualityChecks } from '../utils/ConnectDefaults';
import { observableSelector } from '../utils/selectors';
import ConnectedAsset from './Asset';

import Popout from '../components/Popout';
import ScreenWidthDetector from '../components/ScreenWidthDetector';

// Popout compatible components
const popoutComponents = {
  ConnectedAsset
};

class App extends React.PureComponent {

  state = {
    ready: false
  }

  componentDidMount() {
    const { groupId, sourceId, actions } = this.props;

    if (!groupId || !sourceId) {
      return actions.showGroupsDialog();
    }
    
    this.setState({ ready: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.ready && !prevState.ready) {
      this.fetchTelemetry();
      this.interval = setInterval(this.fetchTelemetry, 5000);
    } else if (this.state.ready && this.props.groupId !== prevProps.groupId) {
      this.fetchTelemetry();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchTelemetry = this.fetchTelemetry.bind(this);
  fetchTelemetry() {
    this.props.actions.loadGroupVehicles();
  }

  renderPopout() {
    const { popout } = this.props;
    const PopoutChildComponent = popout && popoutComponents[popout.componentName];

    if (popout && PopoutChildComponent) {
      return (
        <Popout {...popout.props} onCloseClick={this.props.actions.closePopout}>
          <PopoutChildComponent {...popout.componentProps} popoutContained={true} />
        </Popout>
      );
    }

    return false;
  }

  render() {
    const { actions, children, screenWidth, showGroupsDialog } = this.props;

    return (
      <div>
        <ScreenWidthDetector screenWidth={screenWidth} onChange={actions.screenWidthChanged} />
        { this.renderPopout() }
        { children }
        { showGroupsDialog && <ConnectedSelectInputSourceDialog />}
      </div>
    );
  }
}

const appSelector = observableSelector(state => PropProvider(state).App());

export default connect(
  appSelector,
  defaultMapDispatchToProps, defaultMergeProps, defaultConnectEqualityChecks
)(App);
