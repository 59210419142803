// after polish and more options this should move to au-core or the SDK

/**
 * AutoBuffer is a time based buffer that releases an array of objects at a max
 * frequency (default: 200ms).  This is the shortest amount of time between
 * buffer dumps.  If dumping takes over half the time of the current frequency
 * then the frequency period is doubled.  Else it will half until it hits the
 * max.  The intention is to buffer longer if the system is heavily loaded.
 */
export default class AutoBuffer {
  constructor(callback, maxFreq=200) {
    // Create buffer and start dumping interval
    this._lastEmptyDuration = 0;
    this._curFreq = maxFreq;
    this._lastRan = 0;
    this._buffer = [];
    this._callback = callback;

    this._interval = setInterval(
      this.emptyBuffer.bind(this),
      maxFreq
    );
  }

  push(data) {
    this._buffer.push(data);
  }

  emptyBuffer() {
    // If it takes twice as long to run than the current frequency double it,
    // otherwise halve it limited to max.
    if (this._lastEmptyDuration*2 >= this._curFreq) {
      this._curFreq *= 2;
      return;
    }
    else if (this._curFreq > this._maxFreq) {
      this._curFreq /= 2;
    }
    // else is stable at maxFreq

    // Should run if enough time has past since since last ran
    const shouldRun = ((Date.now() - this._lastRan) / this._curFreq) > 1;

    if (shouldRun && this._buffer.length) {
      const start = Date.now();
      this._callback(this._buffer);
      this._buffer.length = 0;
      this._lastRan = Date.now();
      this._lastEmptyDuration = this._lastRan - start;
    }
  }

  stop() {
    clearInterval(this._interval);
  }
}
