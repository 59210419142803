import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import Slider from 'rc-slider';

import styles from '../css/components/map_bundled_control.module.scss';

export default class MapBundledControl extends React.Component {

  static propTypes = {
    map: T.object.isRequired,
    align: T.oneOf(['bottomLeft', 'bottomRight']),
    zoomMin: T.number,
    zoomMax: T.number,
    zoomStep: T.number,
  }

  static defaultProps = {
    align: 'bottomRight',
    zoomMin: 1,
    zoomMax: 22,
    zoomStep: 1,
  }

  constructor(props) {
    super(props);

    this.state = { zoom: props.map.zoom };
  }

  static getDerivedStateFromProps(props, state) {
    const { zoom } = props.map;

    if (!state.dragging && state.zoom !== zoom) {
      return { zoom };
    }

    return null;
  }

  handleZoomInClick = this.handleZoomInClick.bind(this);
  handleZoomInClick() {
    const nextZoom = this.state.zoom + 1;

    if (nextZoom <= this.props.zoomMax) {
      this.setState({ zoom: nextZoom });
      this.props.handleZoomChanged(nextZoom);
    }
  }

  handleZoomOutClick = this.handleZoomOutClick.bind(this);
  handleZoomOutClick() {
    const nextZoom = this.state.zoom - 1;

    if (nextZoom >= this.props.zoomMin) {
      this.setState({ zoom: nextZoom });
      this.props.handleZoomChanged(nextZoom);
    }
  }

  handleSliderChange = this.handleSliderChange.bind(this);
  handleSliderChange(value) {
    this.setState({ zoom: value });
  }

  handleDragStart = this.handleDragStart.bind(this);
  handleDragStart(value) {
    this.setState({
      dragging: true,
      zoom: value
    });
  }

  handleDragEnd = this.handleDragEnd.bind(this);
  handleDragEnd(value) {
    this.setState({
      dragging: false,
      zoom: value
    });
    this.props.handleZoomChanged(value);
  }

  render() {
    let { zoomMin, zoomMax, zoomStep, align, className } = this.props;
    const { zoom } = this.state;
    const classes = cn(
      styles.container,
      styles[align],
      className
    );

    return (
      <div className={classes}>
        <div className={styles.zoom}>
          <div className={styles.zoomOut} onClick={this.handleZoomOutClick}></div>
          <Slider className={styles.slider}
            min={zoomMin}
            max={zoomMax}
            step={zoomStep}
            value={zoom}
            onChange={this.handleSliderChange}
            onBeforeChange={this.handleDragStart}
            onAfterChange={this.handleDragEnd}
          />
          <div className={styles.zoomIn} onClick={this.handleZoomInClick}></div>
          <output className={styles.output}>{zoom * 10}%</output>
        </div>
      </div>
    );
  }
}
