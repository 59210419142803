import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';

import AuComponent from '@au/core/lib/components/elements/AuComponent';
import CommonGlobalNavigation from '@au/core/lib/components/objects/GlobalNavigation';

import shared from '../shared';
import Breadcrumbs from './Breadcrumbs';
import { setPartition } from '../utils/linkHelper';

import styles from '../css/components/global_navigation.module.scss';

export default class GlobalNavigation extends AuComponent {

  static propTypes = {
    pageTitle: T.string,
    screenWidth: T.string,
    accountId: T.string,
    accountName: T.string,
    partition: T.string,
    accounts: IPT.map,
    actions: T.shape({
      setAccountId: T.func.isRequired
    }).isRequired
  }

  get partition() {
    const { partition } = this.props;
    return shared.config.partitions ? shared.config.partitions[partition].label : '';
  }

  componentDidMount() {
    const { accountId, actions } = this.props;
    this.props.actions.loadAccounts().then(resp => {
      const accounts = resp.items ?? [];
      // currently selected account
      const account = accounts.find(account => account.id === accountId);

      if (!account && accounts[0]?.id) {
        // reset accountId
        actions.setAccountId(accounts[0].id);
      }
    });
  }

  getPartitions() {
    return shared.config.partitions ? Object.entries(shared.config.partitions).map(([value, partition]) =>
      ({ displayString: partition.label, value })
    ) : [];
  }

  generateNavLinks() {
    const isDevEnv = process.env.NODE_ENV === 'development';
  
    const navLinks = [
      { labelId: 'au.section.title.dashboard',
        destination: '/dashboard' 
      },
      { labelId: 'au.section.title.vehicles',
        destination: '/vehicles' 
      },
      // { labelId: 'au.section.title.scenarios',
      //   destination: '/scenarios',
      //   isVisible: isDevEnv
      // },
      { labelId: 'au.section.title.component_gallery', 
        destination: '/component_gallery',
        isVisible: isDevEnv
      }
    ];
  
    return navLinks;
  }

  renderBreadcrumbs() {
    const { screenWidth, pageTitle, pageTitleId } = this.props;

    if (screenWidth === 'desktop' || !pageTitleId) {
      return false;
    }

    return (
      <Breadcrumbs pageTitle={pageTitle} pageTitleId={pageTitleId} />
    );
  }

  render() {
    const { accounts, accountId, accountName, actions } = this.props;
    const showMenu = Boolean(accounts || accountId || accountName);

    return (
      <CommonGlobalNavigation
        navLinks={this.generateNavLinks()}
        breadCrumbs={this.renderBreadcrumbs()}
        logoLink={<Link to="/" />}
        navLinkClassName={styles.navlink}
        accountId={accountId}
        accountName={accountName}
        accounts={accounts}
        partition={this.partition}
        partitions={this.getPartitions()}
        confidentialClient={shared.usingCustomCreds}
        setAccountId={actions.setAccountId}
        setPartition={setPartition}
        showMenu={showMenu}
      >
        { this.props.children }
      </CommonGlobalNavigation>
    );
  }

}