import React from 'react';
import cn from 'classnames';

import AuButton from '@au/core/lib/components/elements/AuButton';
// import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuDropDown from '@au/core/lib/components/elements/AuDropDown';
import { toMouseScenario } from '../utils/scenario';

import styles from '../css/components/scenario_runner.module.scss';

export default class ScenarioRunner extends React.Component {

  state = { running: false }

  selectOption = this.selectOption.bind(this);
  selectOption(scenarioId) {
    const { scenarios, onSelect } = this.props;

    this.setState({ scenarioId });

    onSelect(scenarios.getIn([scenarioId, 'waypoints']).toJS());
  }

  runScenario = this.runScenario.bind(this);
  runScenario() {
    const { vin, scenarios } = this.props;
    const scenario = scenarios.get(this.state.scenarioId).toJS();

    const mouseScenario = toMouseScenario(vin, scenario);

    const request = {
      method: 'POST',
      body: JSON.stringify(mouseScenario),
      headers: {
        'Content-Type': 'application/json'
      }
    };

    // TODO: handle failure with a banner or something
    fetch('http://localhost:5000/v1alpha/mouse/scenario', request)
      .then(resp => resp.json())
      .then(() => this.setState({ running: true }));
  }

  componentDidMount() {
    this._statusTimer = setInterval(this.setRunningStatus, 5000);
    this.setRunningStatus();
  }

  setRunningStatus = this.setRunningStatus.bind(this)
  setRunningStatus() {
    fetch('http://localhost:5000/v1alpha/mouse/scenario:status')
      .then(resp => resp.json())
      .then(data => this.setState({ running: data.message === 'simulation is running' }));
  }

  render() {
    const { className, scenarios } = this.props;

    const options = scenarios.map(scenario => ({
      displayString: scenario.get('name'),
      val: scenario.get('id')
    })).toJS();

    return (
      <div className={cn(styles.container, className)}>
        <AuDropDown
          placeholder="Select a scenario"
          options={options}
          selectOption={this.selectOption}
          className={styles.dropdown}
          disabled={this.state.running}
        />
        <AuButton
          className={styles.button}
          onClick={this.runScenario}
          disabled={this.state.running}
        >
          &#9658;
        </AuButton>
      </div>
    );
  }

}
