import React from 'react';
import AuStatGrid from './AuStatGrid';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import styles from '../css/components/current_location_stats.module.scss';

class CurrentLocationStats extends React.Component {
  renderAStat(labelId, value) {
    return <div className={this.props.fullScreen ? styles.compact : styles.full }>
      <AutoIntl displayId={labelId} className={styles.label} tag="div" />
      <div className={styles.value}>{value}</div>
    </div>;
  }

  render() {
    let { asset } = this.props;
    const gps = asset.lat + ", " + asset.lon;

    return (
      <div className={styles.container}>
        <AuStatGrid className={styles.details_container} numColumns={4}>
          {this.renderAStat("au.vehicle.speed", asset.speed || "-")}
          {this.renderAStat("au.vehicle.engineSpeed", asset.engine_speed)}
          {this.renderAStat("au.vehicle.fuelLevel", asset.fuel_level)}
          {this.renderAStat("au.vehicle.odometer", asset.odometer)}
        </AuStatGrid>
        <div className={styles.gps_container}>
          <AutoIntl displayId="au.vehicle.gps" className={styles.gps_header} tag="div" />
          <div className={styles.gps}>{gps}</div>
        </div>
      </div>
    );
  }
}

export default CurrentLocationStats;