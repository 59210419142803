import { connect } from 'react-redux';
import { observableSelector } from '../utils/selectors';
import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';

import PropProvider from '../PropProvider';
import DashboardStats from '../components/DashboardStats';

const dashboardStatsSelector = observableSelector(state => PropProvider(state).DashboardStats());

export default connect(
  dashboardStatsSelector,
  defaultMapDispatchToProps
)(DashboardStats);
