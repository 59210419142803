import Immutable, { fromJS } from 'immutable';

import { auPersist } from '@au/core/lib/utils/stateDelegators';
import AuSpec from '../utils/AuSpec';
import AuSpecs from '../AuSpecs';

/*
 * This file contains init reducer which describes initial state of the store.
 */

export default {
  "REDUX_INIT": (state) => {
    let initialState = (state || Immutable.Map()).mergeDeep(fromJS({
      routing: {},
      fetchTopics: {},
      fetchSubscriptions: {},
      popout: {},
      services: {},
      settings: {},
      activities: {},
      tableState: {
        //intentionally empty. populated by each table to store table state
      },
      map: {
        center: { lat: 38.0000, lng: -97.0000},
        hoveredAsset: null,
        hoveredInTime: null,
        zoom: 5,
        bounds: null,
        visibleBounds: null,
        mmapZoom: 16,
        fullScreen: false,
      },
      analytics: {
        timeBack: '7'
        //intentionally empty. populated from the analytics rest call
      },
      assetAnalytics: {
        //intentionally empty. populated by pull from specific asset
      },
      filteredEntities: {
        assets: null, // Will be Immutable.Set()
        alerts: null // Will be Immutable.Set()
      },
      entities: {
        //intentionally empty. populated from the rest api
        fleets: { },
        assets: { },
        alerts: { }
      },
      dashboardAnalytics: {
        //intentionally empty. populated from the dashboard analytics query
      },
      geofencesMap: {
        center: { lat: 37.4427659, lng: -122.161493, _initial: true }, // Palo Alto HQ
        zoom: 14
      },
      geofences: {
        //intentionally empty. populated from the dashboard analytics query
      }
    }))
      .setIn(['filteredEntities', 'assets'], Immutable.Set())
      .setIn(['filteredEntities', 'alerts'], Immutable.Set());

    // Deep merge with localStorage
    const persistentState = auPersist.read();
    // Assert that our state is what it should be
    const spec = AuSpecs(AuSpec.builder({strict: true})).persistentState;
    if (spec.valid(persistentState.toJS())) {
      initialState = initialState.mergeDeep(persistentState);
    } else {
      // The state no longer matches!
      console.warn('Local storage state is not up to spec! Resetting local storage state'); // eslint-disable-line no-console
      auPersist.clear();
    }

    // Post alerts filtering, make sure old filters linger
    const appliedPath = ['globalFilters', 'applied'];
    const noAlertsApplied = initialState
      .getIn(appliedPath, Immutable.Map())
      .filter(a => a.get('entityType') !== 'alerts');
    initialState = initialState.setIn(appliedPath, noAlertsApplied);

    return initialState;
  }
};
