import React from 'react';
import * as T from 'prop-types';

import TMC from '@autonomic/browser-sdk';
import AuInput from '@au/core/lib/components/elements/AuInput';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import Modal from '@au/core/lib/components/objects/Modal';
import AuButton, { BUTTON_TYPE_SECONDARY, BUTTON_TYPE_PLAIN } from '@au/core/lib/components/elements/AuButton';

import { validUuid } from '../utils/validationRules';

import styles from '../css/components/select_input_source_dialog.module.scss';

export default class SelectInputSourceDialog extends React.Component {

  static propTypes = {
    groupId: T.string,
    sourceId: T.string,
    actions: T.shape({
      showGroupsDialog: T.func.isRequired,
      hideGroupsDialog: T.func.isRequired,
      clearAssets: T.func.isRequired,
      setGroupId: T.func.isRequired,
      setSourceId: T.func.isRequired
    }).isRequired
  }

  state = {
    groupName: null,
    groupId: this.props.groupId ?? '',
    sourceId: this.props.sourceId ?? '',
    errors: {},
    showErrors: false
  }

  endpoint = new TMC.services.Inventory().groups;

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(e) {
    this.setState({ [e.target.name]: e.target.value?.trim() }, this.validate);
  }

  componentDidUpdate(prevProps, prevState) { 
    const { groupId } = this.state;

    if (prevState.groupId !== groupId) {
      if (validUuid(this.state.groupId) === null) {
        this.lookupGroup();
      } else {
        this.setState({ groupName: null });
      }
    }
  }

  lookupGroup() {
    const { groupId } = this.state;

    this.endpoint.get(groupId).then(resp => {
      const groupName = resp.data.displayName;
      if (groupName) {
        this.setState({ groupName })
      }
    }, error => {
      if (error.status === 404) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            'groupId': {
              errDisplayId: 'au.validation.notFound',
              fieldDisplayId: 'au.inputSource.groupId'
            }
          },
          showErrors: true
        }));
      }
      else {
        throw error;
      }
      
    });
  }

  validate = this.validate.bind(this);
  validate() {
    const { groupId, sourceId } = this.state;
    const errors = {};

    if (!sourceId) {
      errors['sourceId'] = { 
        errDisplayId: 'au.validation.isRequired',
        fieldDisplayId: 'au.inputSource.sourceId'
      };
    }
    
    if (!groupId) {
      errors['groupId'] = { 
        errDisplayId: 'au.validation.isRequired',
        fieldDisplayId: 'au.inputSource.groupId'
      };
    }
    else if (validUuid(groupId) !== null) {
      errors['groupId'] = { 
        errDisplayId: 'au.validation.validUuid',
        fieldDisplayId: 'au.inputSource.groupId'
      };
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  save = this.save.bind(this);
  save() {
    const { groupId, sourceId } = this.state;
    const canSave = this.validate();

    this.setState({ showErrors: true });

    if (!canSave) return;

    if (groupId !== this.props.groupId || sourceId !== this.props.sourceId) {
      this.props.actions.clearAssets();
      this.props.actions.setGroupId(groupId);
      this.props.actions.setSourceId(sourceId);
    }

    this.props.actions.hideGroupsDialog();
  }

  render() {
    const { actions } = this.props;
    const { groupId, groupName, sourceId, errors, showErrors } = this.state;
    const canSave = !showErrors || !Object.keys(errors).length;

    return (
      <Modal>
        <div className={styles.container}>
          <AutoIntl
            className={styles.header}
            displayId={`au.inputSource.${this.props.groupId ? 'change' : 'select'}Title`}
            tag="h2"
          />
          <AutoIntl className={styles.change} displayId="au.inputSource.groupId" tag="div" />
          <AuInput
            name="groupId"
            value={groupId}
            onChange={this.handleFieldChange}
            placeholderId="au.inputSource.groupId"
            showError={Boolean(showErrors && errors.groupId)}
            error={errors.groupId}
          />
          { groupName && 
            <div>Group Name: { groupName }</div>
          }
          <br />
          <AutoIntl className={styles.change} displayId="au.inputSource.sourceId" tag="div" />
          <AuInput
            name="sourceId"
            value={sourceId}
            onChange={this.handleFieldChange}
            placeholderId="au.inputSource.sourceId"
            showError={Boolean(showErrors && errors.sourceId)}
            error={errors.sourceId}
          />
          <div className={styles.buttons}>
            <AuButton
              type={BUTTON_TYPE_SECONDARY}
              className={styles.apply}
              displayId="au.inputSource.saveChange"
              disabled={!groupId || !sourceId || !canSave}
              onClick={this.save}
            />
            { this.props.groupId && this.props.sourceId && 
              <AuButton
                type={BUTTON_TYPE_PLAIN}
                className={styles.cancel}
                displayId="au.inputSource.cancel"
                onClick={actions.hideGroupsDialog}
              />
            }
          </div>
        </div>
      </Modal>
    );
  }

}
