import Immutable from 'immutable';

import { immutableSelectorCreator } from '@au/core/lib/selectors/general';


const nonObservableKeys = Immutable.Set(['fetchTopics', 'streamTopics', 'activities']);
export function observableSelector(provider) {
  // Excludes network functions
  // routing?
  return immutableSelectorCreator(
    state => state.filter((v, k) => !nonObservableKeys.has(k)),
    subState => provider(subState)
  );
}
