import React from 'react';
import * as T from 'prop-types';
// import { Marker } from 'react-google-maps';
// import { pick } from 'lodash';

// import AssetTripsMapInfoWindow from './AssetTripsMapInfoWindow';

// import hardAccelerationImg from '../images/hard_acceleration.svg';
// import hardBrakingImg from '../images/hard_braking.svg';
// import idlingImg from '../images/idling.svg';

export default class BehaviorMarker extends React.PureComponent {
  render() {
    // const { onMouseOver, onMouseOut, kind, lat, lon, showTooltip, zIndex } = this.props;

    // let icon;
    switch(this.props.kind) {
      case 'IDLE':
        // icon = idlingImg;
        break;
      case 'ACCELERATE':
        // icon = hardAccelerationImg;
        break;
      case 'DECELERATE':
        // icon = hardBrakingImg;
        break;
      default:
        console.error('Kind undefined'); // eslint-disable-line no-console
        break;
    }

    // let infoWindow = false;
    // if (showTooltip) {
    //   const tooltipProps = pick(this.props, ['kind', 'duration', 'event_start', 'timezone', 'lat', 'lon']);
    //   infoWindow = <AssetTripsMapInfoWindow {...tooltipProps} />;
    // }

    // return (
    //   <Marker
    //     onMouseOver={onMouseOver}
    //     onMouseOut={onMouseOut}
    //     position={{ lat, lng: lon }}
    //     icon={{
    //       url: icon,
    //       size: new google.maps.Size(25, 25),
    //       origin: new google.maps.Point(0, 0),
    //       anchor: new google.maps.Point(6, 6),
    //       scaledSize: new google.maps.Size(12, 12)
    //     }}
    //     zIndex={zIndex}
    //     >{infoWindow}</Marker>
    // );

    return false;
  }

  static propTypes = {
    kind: T.oneOf(['ACCELERATE', 'DECELERATE', 'IDLE']),
    onMouseOver: T.func,
    onMouseOut: T.func,
    lat: T.number,
    lon: T.number,
    showTooltip: T.bool
  }

  static defaultProps = {
    onMouseOver: ()=>{},
    onMouseOut: ()=>{},
    showTooltip: false
  }
}
