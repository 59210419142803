export const NOOP = () => {};

export const ARN_RE   = /^aui:(.{2,}|\*):([^/]{2,}|\*)$/;
export const AUI_RE   = /^aui:(.{2,}|\*):(.{2,}|\*)?\//;
export const EMAIL_RE = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;
export const USERNAME_RE = /^[\S]+$/;
export const UUID_RE  = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const VIN_RE   = /^[A-HJ-NPR-Z0-9]{17}$/;

export const HOME_PATH = '/dashboard';

export const SERVICE_NAMES = Object.freeze({
  ACCOUNTS: "accounts-service",
});

export const ACCOUNTS_PATH = ['services', SERVICE_NAMES.ACCOUNTS, 'entities', 'accounts'];

export const DATETIME_FORMAT_HOURS   = 'HH';
export const DATETIME_FORMAT_MINUTES = 'mm';
export const DATETIME_FORMAT_DATE = 'MMM DD YYYY';
export const DATETIME_FORMAT_TIME = `${DATETIME_FORMAT_HOURS}:${DATETIME_FORMAT_MINUTES} a z`;
export const DATETIME_FORMAT_FULL = `${DATETIME_FORMAT_DATE} ${DATETIME_FORMAT_TIME}`;

export const VEHICLE_ID_PROP_NAME = 'vehicle_id';
export const GEOFENCE_ID_PROP_NAME = 'geofenceId';

export const MARKER_LABELS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const environmentQueryParams = Object.freeze({
  partition: 'aP',
  account:   'aA'
});

export default Object.freeze({
  EVENT_ADD_ENTITY_BTN_CLICK: 'addEntityBtnClick',
  EVENT_SAVE_ENTITY_BTN_CLICK: 'saveEntityBtnClick',
});

export const drivingScoreCoefficients = {
  ACCELERATE: 10,
  DECELERATE: 5,
  SPEED: 25
};

export const INDICATOR_LIST = [
    'lowFuel',
    'tirePressureMonitorSystemWarning',
    'fastenSeatbeltWarning',
    'airFilterMinder',
    'antilockBreak',
    'antiTheft',
    'breakWarning',
    'checkFuelFiter',
    'forwardCollisionWarning',
    'hillDescentControFault',
    'laneKeepingAid',
    'lowEngineOilPressure',
    'malfunctionIndicator',
    'parkAidMalfunction',
    'powertrainMalfunction',
    'serviceSteering',
    'adaptiveCruiseControl',
    'allWheelDriveDisabled',
    'blindSpotDetection',
    'chargeSystemFault',
    'checkFuelCap',
    'checkFueFillInlet',
    'fuelDoorOpen',
    'lowWasherFluid',
    'startStopEngineWarning',
    'tractionControlDisabled'
  ];
