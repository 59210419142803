module.exports = Object.freeze({
  assetGeofenceHistory: {
    useFilterEntity: false,
    tablePkField: 'id',
    sort: { columnId: 'enter', direction: 'down' },
    columnDefs: [
      { property: 'id',       labelId: 'au.noop',               searchable: false, width: 0,   display: false },
      { property: 'enter',    labelId: 'au.geofence.enterTime', searchable: true,  width: 150, display: true, flexGrow: 1, formatters: [{ func: 'date' }] },
      { property: 'exit',     labelId: 'au.geofence.exitTime',  searchable: true,  width: 150, display: true, flexGrow: 1, formatters: [{ func: 'date' }] },
      { property: 'duration', labelId: 'au.geofence.duration',  searchable: false, width: 90,  display: true, flexGrow: 1, formatters: [{ func: 'duration' }] },
    ]
  },
  assets: {
    filter: null,
    useFilterEntity: true,
    entity: 'assets',
    tablePkField: 'vehicle_id',
    sort: { columnId: 'vin', direction: 'down' },
    columnDefs: [
      { property: 'vehicle_id',      labelId: 'au.noop',                   searchable: false, width: 0,   display: false },
      { property: 'vin',             labelId: "au.vehicle.vin",            searchable: true,  width: 175, display: true, flexGrow: 1, formatters: [{ func: 'entityLink', args: { type: 'vehicle', idProperty: 'vehicle_id'} }] },
      { property: 'speed',           labelId: "au.vehicle.speed",          searchable: false, width: 150, display: true, flexGrow: 1, unitType: 'rate', unitLabel: 'abbr' },
      { property: 'odometer',        labelId: "au.vehicle.odometer",       searchable: false, width: 150, display: true, flexGrow: 1, unitType: 'dist', unitLabel: 'abbr' },
      { property: 'fuel_level',      labelId: "au.vehicle.fuelLevel",      searchable: false, width: 150, display: true, flexGrow: 1, unitType: 'percentage',  unitLabel: 'full' },
      { property: 'timestamp',       labelId: "au.vehicle.lastUpdate",     searchable: false, width: 185, display: true, flexGrow: 1, formatters: [{ func: 'relativeDate' }]},
      { property: 'indicatorLights', labelId: "au.vehicle.currentStatus",  searchable: false, width: 150, display: true, flexGrow: 1, formatters: [{ func: 'currentStatusIcons' }] }
    ]
  },
  geoFence: {
    useFilterEntity: false,
    entity: 'geoFence',
    tablePkField: 'vehicle_id',
    sort: { columnId: 'vin', direction: 'down' },
    columnDefs: [
      { property: 'vehicle_id', labelId: 'au.noop',              searchable: false, width: 0,   display: false },
      { property: 'vin',       labelId: 'au.vehicle.vin',        searchable: true,  width: 175, display: true, formatter: { type: 'link', args: { type: 'vehicle', idProperty: 'vehicle_id' } } },
      { property: 'timestamp', labelId: 'au.vehicle.lastUpdate', searchable: true,  width: 215, display: true, formatters: [{ func: 'date' }] },
    ]
  },
  geofencePage: {
    sort: { columnId: 'vin', direction: 'up' },
    searchbox: true,
    searchboxPlaceholderId: 'au.geofencepage.filterPlaceholder',
    columnDefs: [
      { property: 'vehicle_id', labelId: 'au.noop',                        width: 0,   searchable: false, display: false },
      { property: 'vin',        labelId: 'au.geofencepage.table.vinLabel', width: 190, searchable: true,  display: true, flexGrow: 1, formatter: { type: 'link', args: { type: 'vehicle', idProperty: 'vehicle_id', linkProps: { selectedTab: 'AssetGeoFences'} } } } ,
      { property: 'timestamp',  labelId: 'au.vehicle.lastUpdate',          width: 180, searchable: false, display: true, flexGrow: 1, formatters: [{ func: 'date' }] },
    ]
  },
  geofences: {
    tablePkField: 'id',
    sort: { columnId: 'name', direction: 'up' },
    searchbox: true,
    searchboxPlaceholderId: 'au.geofences.filterPlaceholder',
    columnDefs: [
      { property: 'id',                      labelId: 'au.noop',                 searchable: false, width: 0,   display: false, sortable: false },
      { property: 'name',                    labelId: 'au.geofence.name',        searchable: true,  width: 250, display: true, flexGrow: 1,  formatter: { type: 'link', args: { type: 'geofences', idProperty: 'id' } } },
      { property: 'numberOfVehiclesPresent', labelId: 'au.geofence.numVehicles', searchable: false, width: 165, display: true, flexGrow: 1 },
    ]
  }
});
