import { fromJS, List, Map as imMap, Set as imSet } from 'immutable';

import { getMapAssets } from '@au/core/lib/utils/maps';
import { BuildTableProps, TableProps, GetTableState, ProcessTableData } from '@au/core/lib/utils/table';
import { sharedGeoFenceSelectors } from '@au/core/lib/selectors/shared';
import { keyIn } from '@au/core/lib/utils/immutableHelpers';

import { ACCOUNTS_PATH } from './constants';
import tableDefs from './tableDefs';
import { filterEntity, filterSuggestions } from './utils/globalFilters';

export default function propProviderGen(topProps, actions, myGoogle=google) {
  let propProvider;
  propProvider = {
    Analytics: () => {
      return {
        timeBack: topProps.getIn(['analytics', 'timeBack'])
      };
    },
    App: () => {
      const popout = topProps.get('popout', imMap()).toJS();
      const partition = topProps.getIn(['settings', 'partition']);
      const accountId = topProps.getIn(['settings', 'partitions', partition, 'accountId']);
      const showGroupsDialog = topProps.get('showGroupsDialog');

      return {
        screenWidth: topProps.get('screenWidth'),
        popout: popout.componentName ? popout : null,
        showGroupsDialog: accountId && showGroupsDialog,
        groupId: topProps.getIn(['settings', 'partitions', partition, 'groupId']),
        sourceId: topProps.getIn(['settings', 'partitions', partition, 'sourceId']),
      };
    },
    Asset: vehicle_id => {
      let asset = topProps.getIn(['entities', 'assets', vehicle_id], imMap());
      return {
        vehicle_id,
        vehicleLoaded: topProps.hasIn(['entities', 'assets', vehicle_id]),
        name: asset.get('name'),
        vin: asset.get('vin'),
        notifications: asset.get('indicatorLights', List()).filter(a => a.get('status') === true).size > 0,
        groupId: topProps.get('groupId'),
        location: asset.get('location')
      };
    },
    AssetMetrics: vehicle_id => {
      return {
        asset: topProps.getIn(['entities', 'assets', vehicle_id])
      };
    },
    AssetData: vehicle_id => {
      return {
        asset: topProps.getIn(['vehicle_id']),
        vehicle_id
      };
    },
    AssetCurrentLocation: vehicle_id => {
      let asset = topProps.getIn(['entities', 'assets', vehicle_id], imMap());

      //This ensures that if the vehicles aren't loaded from the server yet, that we don't error out.
      const jsAsset = asset.toJS();
      if (asset.get('geoFences', imSet()).count()) {
        // Get the first geo fence since UI is not designed for multiple
        // FIXME
        jsAsset.geofence = topProps.getIn(['geofences', asset.get('geoFences').first()]).toJS();
      }
      return {
        vehicle_id: vehicle_id,
        vehicleLoaded: topProps.hasIn(['entities', 'assets', vehicle_id]),
        geofences: topProps.get('geofences'),
        asset: jsAsset
      };
    },
    AssetGeoFences: vehicle_id => {
      const asset = topProps.getIn(['entities', 'assets', vehicle_id]);
      const geoFenceDefs = topProps.get('geofences').map(gf => sharedGeoFenceSelectors.run(gf)).toArray();
      const geoFencesSortOrder = topProps.get('geofencesSortOrder', 'mostRecent');
      const tableDef = tableDefs['assetGeofenceHistory'];

      const geoFences = [];
      const geoFenceTables = {};

      geoFenceDefs.forEach(geoFenceDef => {
        const geofenceId = geoFenceDef.get('id');
        const tableId = 'assetGeoFence_' + geofenceId;
        const tableState = GetTableState(tableId, topProps, tableDef);
        let geofenceDataPath = ['geofenceHistory', geofenceId, 'data'];

        let tableData = imMap();
        if (asset.hasIn(geofenceDataPath)){
          tableData = ProcessTableData(asset.getIn(geofenceDataPath), tableState, tableDef);
        }

        geoFenceTables[geofenceId] = { tableId, tableData };

        geoFences.push(geoFenceDef);
      });

      switch(geoFencesSortOrder) {
        case 'mostFrequent':
          // Sort descending based on number of visits
          geoFences.sort((a, b) => {
            let aTD = geoFenceTables[a.get('id')].tableData.toJS();
            let bTD = geoFenceTables[b.get('id')].tableData.toJS();
            if (aTD && Array.isArray(aTD) && bTD && Array.isArray(bTD)){
              // If one of the geofences has more "visits" than another then we have a "Most Frequent"
              return bTD.length - aTD.length;
            }
            return 0;
          });
          break;
        case 'mostRecent':
        default:
          // Sort descending based on latest entry timestamp
          geoFences.sort((a, b) => {
            let aTD = geoFenceTables[a.get('id')].tableData.toJS();
            let bTD = geoFenceTables[b.get('id')].tableData.toJS();

            if (Array.isArray(aTD) && Array.isArray(bTD)) {
              if (aTD.length && bTD.length) {
                // Compare Max enter timestamps (ISO8061)
                return Math.max(...Object.values(bTD).map(o => new Date(o.enter))) - Math.max(...Object.values(aTD).map(o => new Date(o.enter)));
              }
              return bTD.length - aTD.length;
            }
            return 0;
          });
          break;
      }

      let populatedFences = geoFences.filter(gf =>
        geoFenceTables[gf.get('id')].tableData.size
      );

      return {
        geoFenceDefs: geoFences,
        vehicle_id,
        asset,
        populatedFences,
        geoFenceTables,
        geoFencesSortOrder,
        tableDef
      };
    },
    Assets: function() {
      const tableDef = tableDefs['assets'];
      return {
        ...BuildTableProps('assets', tableDef, topProps, undefined, filterEntity),
        groupId: topProps.get('groupId'),
        showGroupSelector: topProps.getIn(['entities', 'groups'], imMap()).size > 1
      };
    },
    AvgDistTraveledLineChart: () => getLineChart(
      topProps, undefined, 'averageDistanceTraveled', 'dist_traveled_analytics'
    ),
    Charts: function() {
      return {
        actions,
      };
    },
    ComponentExporter: () => ({
      language: topProps.get('locale'),
      systemOfUnits: topProps.get('systemOfUnits'),
    }),
    Connect: () => {},
    Dashboard: () => {
      const partition = topProps.getIn(['settings', 'partition']);

      return {
        screenWidth: topProps.get('screenWidth'),
        hideSidebar: topProps.getIn(['map', 'fullScreen'], false),
        groupId: topProps.getIn(['settings', 'partitions', partition, 'groupId']),
        sourceId: topProps.getIn(['settings', 'partitions', partition, 'sourceId']),
        showGroupSelector: true //topProps.getIn(['entities', 'groups'], imMap()).size > 1
      };
    },
    DashboardMap: () => {
      const assets = getMapAssets(filterEntity(topProps, 'assets'));
      const mapProps = topProps.get('map', imMap()).toJS();
      const initialized = topProps.getIn(['app', 'initialized']);
      const { bounds, visibleBounds, hoveredAsset, fullScreen } = mapProps;
      // Filter out dashboard map assets that are outside the map bounds
      let dashMapAssets = initialized ? assets : List();
      if (visibleBounds && initialized) {
        const mapBounds = new myGoogle.maps.LatLngBounds(
          { lat: visibleBounds.south, lng: visibleBounds.west },
          { lat: visibleBounds.north, lng: visibleBounds.east }
        );
        dashMapAssets = assets.filter(a =>
          mapBounds.contains({
            lat: a.getIn(['location', 'lat']),
            lng: a.getIn(['location', 'lon'])
          })
        );
      }

      return {
        zoom: mapProps.zoom || 4,
        center: {
          lat: parseFloat(mapProps.center.lat || 0),
          lng: parseFloat(mapProps.center.lng || 0)
        },
        // assets: topProps.getIn(['entities', 'assets']),
        // dashMapAssets,
        assets: dashMapAssets,
        hoveredAsset: hoveredAsset ? topProps.getIn(['entities', 'assets', hoveredAsset]) : undefined,
        visibleBounds,
        initialized,
        fullScreen,
        bounds
      };
    },
    DashboardStatPanel: () => {
      return {
        miniCharts: topProps.getIn(['dashboardAnalytics'])
      };
    },
    DashboardStats: date => {
      const allAssetsArr = filterEntity(topProps, 'assets').toIndexedSeq();
      let recentThreshold;
      {
        const recentThresholdDate = date || (new Date());
        recentThresholdDate.setMinutes(recentThresholdDate.getMinutes() - 15);
        recentThreshold = recentThresholdDate.toISOString();
      }

      const activeAssetsArr = allAssetsArr.filter(function activeAssetsArr(a) { return a.get('timestamp') > recentThreshold && a.get('ignition_status').toUpperCase() === 'ON';});
      const active = activeAssetsArr.count();
      const driving = activeAssetsArr.reduce(function driving(a, b) {
        //3.22 kph which gets converted down to 2 mph
        let retVal = a + (b.get('speed') > 0 ? 1 : 0);
        return retVal;
      }, 0);
      const idle = active - driving;
      const needsService = allAssetsArr.reduce(function needsService(a, b) {
        return a + (b.get('needs_service') ? 1 : 0);
      }, 0);
      const ignitionOff = allAssetsArr.size - active;
      /* While there is a possibilty the backend returns ignition_status === "on" but the timestmap is older
       * than 15 minutes ago, which would cause the vehicle's asset page to disagree with the dashboard stats,
       * this _shouldn't_ happen.  As long as the backend's definition of how long the timeout is before setting
       * the vehicle's ignition_status === "off" is the same as our active range or less then this _shouldn't_
       * happen.
       */
      const utilization = allAssetsArr.size ? Math.round((driving + idle)*100/allAssetsArr.size) : 0;
      return {
        driving,
        idle,
        ignitionOff,
        needsService,
        utilization,
        total: allAssetsArr.size
      };
    },
    DistTraveledLineChart: vehicle_id => getLineChart(
      topProps, vehicle_id, 'distanceTraveled', 'dist_traveled_analytics'
    ),
    FuelConsumedLineChart: vehicle_id => getLineChart(
      topProps, vehicle_id, 'fuelConsumed', 'fuel_consumed_analytics'
    ),
    GeoFenceList: () => {
      const tableDef  = tableDefs['geofences'];
      const geofences = topProps.get('geofences').toIndexedSeq().map(fence => {
        const assetsInBounds = topProps.getIn(['entities', 'assets']).filter(asset =>
          asset.get('geoFences', imSet()).has(fence.get('id'))
        );
        return fromJS({
          id: fence.get('id'),
          name: fence.get('name'),
          numberOfVehiclesPresent: assetsInBounds.count()
        });
      });

      return TableProps(geofences, 'geoFenceList', tableDef, topProps);
    },
    GeoFencePage: geofenceId => {
      const geofence = topProps.getIn(['geofences', geofenceId]);
      const assets   = topProps.getIn(['entities', 'assets']).toIndexedSeq();
      const tableDef = tableDefs['geofencePage'];

      if (!geofence) {
        return {
          geofence: null,
          assets: null,
          tableId: null,
          tableDef,
          tableState: null,
          tableData: null
        };
      }

      const containedAssets = assets.filter(a =>
        a.get('geoFences', imSet()).has(geofenceId)
      );
      const assetsForMap = containedAssets.map(asset =>
        asset.filter(keyIn('location', 'vehicle_id'))
      );
      const assetsForTable = containedAssets.map(asset =>
        asset.filter(keyIn('vehicle_id', 'vin', 'timestamp'))
      );

      return {
        geofence: sharedGeoFenceSelectors.run(geofence),
        assets: assetsForMap,
        ...TableProps(assetsForTable, `geoFencePageTable-${geofence.get('id')}`, tableDef, topProps)
      };
    },
    GeoFences: () => {
      const geofences = topProps.get('geofences').map(fence =>
        fence.filter(keyIn('id', 'name', 'center'))
      );

      return {
        center: topProps.getIn(['geofencesMap', 'center']),
        zoom: topProps.getIn(['geofencesMap', 'zoom']),
        geofences,
        actions
      };
    },
    GlobalNavigation: () => {
      const partition = topProps.getIn(['settings', 'partition']);
      const accountId = topProps.getIn(['settings', 'partitions', partition, 'accountId']);
      const accounts  = topProps.getIn(ACCOUNTS_PATH, imMap());
      const accountName  = accounts.getIn([accountId, 'name']);

      return {
        location: topProps.get('location'),
        pageTitle: topProps.get('pageTitle'),
        pageTitleId: topProps.get('pageTitleId'),
        screenWidth: topProps.get('screenWidth'),
        open: topProps.getIn(['nav', 'open']),
        partition,
        accountId,
        accountName,
        accounts,
        actions
      };
    },
    SelectInputSourceDialog: () => {
      const partition = topProps.getIn(['settings', 'partition']);

      return {
        groupId: topProps.getIn(['settings', 'partitions', partition, 'groupId']),
        sourceId: topProps.getIn(['settings', 'partitions', partition, 'sourceId'])
      };
    },
    IntlProvider() {
      return {
        language: topProps.get('locale')
      };
    },
    MapGallery: () => {},
    MiniMap: () => {
      const initialized = topProps.getIn(['app', 'initialized']);
      const assets = getMapAssets(filterEntity(topProps, 'assets'));
      const mapProps = topProps.get('map', imMap()).toJS();

      return {
        bounds: mapProps.visibleBounds,
        initialBounds: mapProps.bounds,
        screenWidth: topProps.get('screenWidth'),
        initialized,
        assets
      };
    },
    SearchBox: () => {
      // Reformat suggestions, TODO: pass these back and change the component to handle Redux state format better
      const dataSets = [];
      const globalFilters = filterSuggestions(topProps.get('globalFilters'));
      for (let [displayId, filterFieldDetails] of globalFilters.get('filterFields').entrySeq()) {
        const target = filterFieldDetails.get('target');
        const suggestions = filterFieldDetails.get('suggestions');
        const entityType = filterFieldDetails.get('entityType');

        const items = [];
        const filterProps = [];
        if (suggestions.get('mapsWith')) {
          for (let [searchTerm, termMetaData] of suggestions.get('items').entrySeq()) {
            items.push({
              searchTerm,
              [target]: termMetaData.get('mapsTo')
            });
          }
          filterProps.push({
            name: target,
            entityType,
            target,
            displayId
          });
        }
        else {
          for (let searchTerm of suggestions.get('items').keySeq()) {
            items.push({
              [target]: searchTerm
            });
          }
          filterProps.push({
            name: target,
            target,
            entityType,
            displayId
          });
        }

        dataSets.push({
          items,
          filterProps
        });
      }

      return {
        input: dataSets
      };
    },
    ScenarioEditor: ownProps => {
      const { scenarioId } = ownProps.match.params;
      const scenario = topProps.getIn(['scenarios', scenarioId], imMap());

      return { scenario, scenarioId };
    },
    ScenarioBuilder: () => {
      const assets = topProps.getIn(['entities', 'assets'], imMap());
      const vins = assets.map(v => {
        return { displayString: v.get('vin'), val: v.get('vin') };
      });
      return {
        options: vins.toArray().sort()
      };
    },
    ScenarioRunner: () => {
      const scenarios = topProps.get('scenarios', imMap());

      return { scenarios };
    },
    Scenarios: () => {
      return {
        screenWidth: topProps.get('screenWidth'),
        hideSidebar: topProps.getIn(['map', 'fullScreen'], false),
        groupId: topProps.get('groupId'),
        showGroupSelector: topProps.getIn(['entities', 'groups'], imMap()).size > 1
      };
    },
    UnitProvider() {
      return {
        system: topProps.get('systemOfUnits')
      };
    }
  };
  return propProvider;
}

function getLineChart(topProps, vehicle_id, chartType, activityName) {
  let dataPath, isLoading = false;
  if (vehicle_id) {
    dataPath = ['assetAnalytics', vehicle_id, chartType];
  } else {
    dataPath = ['analytics', chartType];
    isLoading = (topProps.getIn(['activities', activityName]) || imMap()).get('status') === 'pending';
  }
  return {
    lang: topProps.get('locale'),
    imData: topProps.getIn(dataPath, List()),
    isLoading,
    vehicle_id
  };
}
