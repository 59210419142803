export function routeRangeSerializer(startTime, endTime) {
  // This should be 8601 compliant
  if (!startTime || !endTime) {
    return '';
  }
  return `${startTime.toISOString()}/${endTime.toISOString()}`;
}

export function routeRangeDeserializer(serialized) {
  const startEndStrings = serialized.split('/');
  return startEndStrings.map(s => new Date(s));
}
