import React from 'react';
import * as T from 'prop-types';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import AuStatGrid from './AuStatGrid';

import styles from '../css/components/dashboard_stats.module.scss';

export default class DashboardStats extends React.PureComponent {

  static propTypes = {
    total: T.number.isRequired,
    needsService: T.number.isRequired,
    driving: T.number.isRequired,
    fullScreen: T.bool
  }

  renderAStat(labelId, value) {
    return <div className={this.props.fullScreen ? styles.compact : styles.full }>
      <AutoIntl className={styles.label} displayId={labelId} tag="div" />
      <div className={styles.value}>{value}</div>
    </div>;
  }

  render() {
    const { total, driving, needsService, fullScreen } = this.props;

    return (
      <AuStatGrid className={styles.container} type={fullScreen ? 'table' : 'flex'}>
        {this.renderAStat("au.dashboard.stat.total",   total || "-")}
        {this.renderAStat("au.dashboard.stat.service", needsService)}
        {this.renderAStat("au.dashboard.stat.driving", driving)}
      </AuStatGrid>
    );
  }
}
