import QueryBuilder from './queryBuilder';

export default class MetricsQueryBuilder extends QueryBuilder {
  // TODO: add bounding box
  // TODO: add bounding circle
  // TODO: support precentiles
  // TODO: support degrees of AND and OR nesting
  // TODO: add clear scope and aggregation
  // Should I remove "return" if an aggregation is set?

  last(interval, offset=this._myOffset) {
    // TODO: validate interval via regex?
    this._scopes.last = { last: interval };
    if (offset) {
      this._scopes.last.last += `:${offset}`;
    }
    return this;
  }

  return(...args) {
    if (args.length === 1) {
      const arg = args[0];
      if (Array.isArray(arg)) {
        this._return = arg;
      } else {
        this._return = [args[0]];
      }
    } else {
      this._return = [...args];
    }
    return this;
  }

  timeRange(...args) {
    if (args.length === 1) {
      const arg = args[0];
      if (Array.isArray(arg)) {
        return this._timeRange(arg[0], arg[1]);
      }
      if (typeof arg === 'object') {
        return this._timeRange(arg.start_time, arg.end_time);
      }
    } else {
      return this._timeRange(args[0], args[1]);
    }
  }

  _getRangeDate(startOrEnd) {
    if (this._scopes.timeRange && this._scopes.timeRange[startOrEnd]) {
      return this._scopes.timeRange[startOrEnd];
    }
  }

  get timeRangeStart() {
    return this._getRangeDate('start_time');
  }

  get timeRangeEnd() {
    return this._getRangeDate('end_time');
  }

  _timeRange(start_time, end_time) {
    start_time = this._castDate(start_time);
    end_time = this._castDate(end_time);
    // Future: could test if strings are valid Date strings
    this._scopes.timeRange = { start_time, end_time };
    return this;
  }

  _formQuery() {
    const query = super._formQuery();

    if (this._scopes.last) {
      query.scopes.push(this._scopes.last);
    }
    if (this._scopes.timeRange) {
      query.scopes.push(this._scopes.timeRange);
    }
    if (this._return) {
      query.return = this._return;
    }

    // If return is not specified and there are no aggregations assume _all_
    if (!query.return && !query.aggregations) {
      query.return = [ '_all_' ];
    }

    return query;
  }

  _getRequiredInternals() {
    return Object.assign(super._getRequiredInternals(), {
      _return: this._return
    });
  }
}
