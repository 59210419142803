import { fromJS } from 'immutable';

export default {
  TABLE_FILTER: (state, action) => {
    return state.setIn(['tableState', action.tableId, 'filter'], fromJS(action.filterBy));
  },
  TABLE_SORT_PREFERENCE: (state, action) => {
    let { tableId, columnId, direction } = action;
    return state.setIn(['tableState', tableId, 'sort'], fromJS({direction: direction, columnId: columnId}));
  },
  TOGGLE_TABLE_SORT_PREFERENCE: (state, action) => {
    let { tableId, columnId } = action;
    let newSort = {direction: 'up', columnId};
    let existingSort = state.hasIn(['tableState', tableId, 'sort']) ? state.getIn(['tableState', tableId, 'sort']) : state.getIn(['tableDefs', tableId, 'sort']);
    if (existingSort) {
      existingSort = existingSort.toJS();
      if (existingSort.columnId === columnId) {
        //existing sort is on same column, just toggle the direction
        newSort.direction = (existingSort.direction === 'up') ? 'down' : 'up';
      }
      //else, the newSort has already taken care of things.
    }
    return state.setIn(['tableState', tableId, 'sort'], fromJS(newSort));
  },
};
