import { fromJS } from 'immutable';
import { persistState } from '@au/core/lib/utils/stateDelegators';

function mapToObj(map) {
  const obj = {};
  for (let [id, entity] of map) {
    obj[id] = entity;
  }
  return obj;
}

export default {
  RECEIVED_GROUPS: (state, action) => {
    return state.setIn(['entities', 'groups'], fromJS(mapToObj(action.groups)));
  },
  SET_GROUP_ID: (state, action) => {
    const partition = state.getIn(['settings', 'partition']);
    state = persistState(state)
    .call('setIn', ['settings', 'partitions', partition, 'groupId'], action.groupId)
    .state;
    return state;
  },
  SET_SOURCE_ID: (state, action) => {
    const partition = state.getIn(['settings', 'partition']);
    state = persistState(state)
    .call('setIn', ['settings', 'partitions', partition, 'sourceId'], action.sourceId)
    .state;
    return state;
  },
  SHOW_GROUPS_DIALOG: (state) => {
    return state.set('showGroupsDialog', true);
  },
  HIDE_GROUPS_DIALOG: (state) => {
    return state.set('showGroupsDialog', false);
  },
};
