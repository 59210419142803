import { guessCurrentTimezone } from '../../../utils/timezones';
import shared from '../../../shared';
import { routeRangeSerializer } from '../utils/serializers';
import { fetchTopicRequested, fetchTopicReceived, fetchTopicFailed } from '../../../ActionCreators';

export function setRouteDisplay(vehicle_id, startTime, endTime, display=true) {
  return {
    type: 'SET_ROUTE_DISPLAY',
    vehicle_id,
    startTime,
    endTime,
    display
  };
}

export function setTimeline(vehicle_id, startTime, endTime, timelineStart, timelineEnd) {
  return {
    type: 'SET_TIMELINE',
    vehicle_id,
    startTime,
    endTime,
    timelineStart,
    timelineEnd
  };
}

export function setPanelDisplay(vehicle_id, panelDisplay) {
  return {
    type: 'SET_PANEL_DISPLAY',
    vehicle_id,
    panelDisplay
  };
}

// NOTE: deprecated and left here as an example to use with historical endpoints
// export function fetchDistanceTraveled(vehicle_id, startTime, endTime) {
//   const topic = `${vehicle_id}-${routeRangeSerializer(startTime, endTime)}-dist`;
//   const type = 'RECEIVED_DIST_TRAVELED';
//   const query = mqb.start
//     .timeRange(startTime, endTime)
//     .filter('vehicle_id', vehicle_id)
//     .filter('odometer', '>0')
//     .reduceBy(['max', 'odometer'], ['min', 'odometer']);
//   const fetchFn = () => metricsQuery.query.query(query.build());
//   const paramPromise = Promise.resolve({
//     topic,
//     type,
//     fetchFn,
//     mergeResponse: { vehicle_id, startTime, endTime }
//   });
//
//   return fireOff(paramPromise);
// }

export function fetchTimezoneFromLocation(vehicle_id, location, usersTimezone) {
  // Assumes the location is at the current time
  const topic = `timezone-for-${JSON.stringify(location)}`;
  const type = 'RECEIVED_TIMEZONE_FROM_LOCATION';
  const fetchFn = () => Object.keys(location).length ? guessCurrentTimezone(location) : Promise.resolve({});
  const paramPromise = Promise.resolve({
    topic,
    type,
    fetchFn,
    mergeResponse: { vehicle_id, usersTimezone },
    failForever: true
  });

  return fireOff(paramPromise);
}

// NOTE: deprecated and left here as an example to use with historical endpoints
// export function fetchFuelConsumed(vehicle_id, startTime, endTime, interval) {
//   const topic = `${vehicle_id}-${routeRangeSerializer(startTime, endTime)}-fuel`;
//   const type = 'RECEIVED_FUEL_CONSUMED';
//   const query = mqb.start
//     .timeRange(startTime, endTime)
//     .filter('vehicle_id', vehicle_id)
//     .reduceBy('sum', 'fuel_consumed')
//     .groupBy('vehicle_id')
//     .groupByInterval(interval);
//   const fetchFn = () => metricsQuery.query.query(query.build());
//   const paramPromise = Promise.resolve({
//     topic,
//     type,
//     fetchFn,
//     mergeResponse: { vehicle_id, startTime, endTime },
//     failForever: true
//   });
//
//   return fireOff(paramPromise);
// }

// NOTE: deprecated and left here as an example to use with historical endpoints
// export function fetchBehaviors(vehicle_id, startTime, endTime) {
//   const topic = `${vehicle_id}-${routeRangeSerializer(startTime, endTime)}-behavior`;
//   const type = 'RECEIVED_BEHAVIOR';
//   const kindOfEvents = ['DECELERATE', 'ACCELERATE', 'SPEED'];
//   const query = bqb.start
//     .filter('vehicle_id', vehicle_id)
//     .orFilters(...kindOfEvents.map(e => ['kind', e]))
//     .startsAfter(startTime)
//     .endsBefore(endTime)
//     .build();
//   const fetchFn = () => behaviorQuery.fields.query(query);
//   const paramPromise = Promise.resolve({
//     topic,
//     type,
//     fetchFn,
//     mergeResponse: { vehicle_id, startTime, endTime },
//     failForever: true
//   });
//
//   return fireOff(paramPromise);
// }

// NOTE: deprecated and left here as an example to use with historical endpoints
// export function fetchRoutes(vehicle_id, startTime, endTime) {
//   const topic = `${vehicle_id}-${routeRangeSerializer(startTime, endTime)}-route`;
//   const type = 'RECEIVED_ROUTES';
//   const query = {
//     scopes: [
//       { start_time: startTime, end_time: endTime },
//       { filter: `vehicle_id:${vehicle_id}` }
//     ],
//     fields: [ 'timestamp', 'location' ]
//   };
//   const fetchFn = () => metricsQuery.fields.query(query);
//   const paramPromise = Promise.resolve({
//     topic,
//     type,
//     fetchFn,
//     mergeResponse: { vehicle_id, startTime, endTime },
//     failForever: true
//   });
//
//   return fireOff(paramPromise);
// }

export function fetchRoutes(vehicle_id, startTime, endTime, groupId) {
  const topic = `${vehicle_id}-${routeRangeSerializer(startTime, endTime)}-route`;
  const type = 'RECEIVED_ROUTES';

  const fetchFn = () => {
    return shared.transporter.post(`/v1alpha/history/sources/${window.WORKSPACE}/groups/${groupId}?vehicle=${vehicle_id}`, {
      startTime,
      endTime,
      "metrics": [
        "position"
      ]
    }).then(response => {
      loadVehicleHistorySuccess(vehicle_id, response.data);
      return response;
    }).catch(error => {
      // TODO implement proper error handling
      /* eslint-disable no-console */
      console.warn('Loading group vehicles failed', error);
      /* eslint-enable no-console */
      throw error;
    });
  };
  const paramPromise = Promise.resolve({
    topic,
    type,
    fetchFn,
    mergeResponse: { vehicle_id, startTime, endTime },
    failForever: true
  });

  return fireOff(paramPromise);
}

export function loadVehicleHistorySuccess(vehicle_id, result) {
  return { type: 'RECEIVED_ROUTES', vehicle_id, items: [result] };
}

function fireOff(promise) {
  // Makes wrapping the promise easier and cleaner
  return (dispatch, getState) => promise
    .then(params => ({dispatch, getState, ...params}))
    .then(fetchTopic);
}

function fetchTopic({dispatch, getState, topic, type, fetchFn, mergeResponse={}, allowConcurrent=false, failForever=false}) {
  if (!allowConcurrent && getState().getIn(['fetchTopics', topic, 'isFetching'], false)) {
    // Previous request is still in progress
    return Promise.resolve();
  }

  if (failForever && getState().getIn(['fetchTopics', topic, 'status']) === 'failed') {
    return Promise.resolve();
  }

  dispatch(fetchTopicRequested(topic));
  // Current topic version
  let version = getState().getIn(['fetchTopics', topic, 'version']);

  return fetchFn(getState).then(response => {
    // Do not apply the data if version is different
    if (version === getState().getIn(['fetchTopics', topic, 'version'])) {
      dispatch({
        type,
        response: response.data || response,
        ...mergeResponse
      });
    }
  }).catch(error => {
    // Ignore if we are on a different version
    if (version === getState().getIn(['fetchTopics', topic, 'version'])) {
      dispatch(fetchTopicFailed(topic, version));
    }
    throw error;
  }).then(() => {
    dispatch(fetchTopicReceived(topic, version));
  });
}
