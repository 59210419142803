import { connect } from 'react-redux';

import VehicleLink from '@au/core/lib/components/elements/VehicleLink';
import { defaultMapDispatchToProps, defaultMergeProps, defaultConnectEqualityChecks } from '../utils/ConnectDefaults';


export default connect(
  null,
  defaultMapDispatchToProps, defaultMergeProps, defaultConnectEqualityChecks
)(VehicleLink);
