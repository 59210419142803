import Immutable from 'immutable';

import initReducer from './init';
import generalReducer from './general';
import mapReducer from './map';
import geoFenceReducer from './geoFence';
import analyticsReducer from './analytics';
import suggestionsReducer from './suggestion';
import fleetReducer from './fleet';
import groupReducer from './group';
import assetsReducer from './asset';
import tableReducer from './table';
import topicReducer from './topic';
import popoutReducer from './popout';
import accountReducer from './account';
import settingReducer from './setting';

import AuSpec from '../utils/AuSpec';
import AuSpecs from '../AuSpecs';

const handlers = Object.assign({},
  generalReducer,
  mapReducer,
  geoFenceReducer,
  analyticsReducer,
  suggestionsReducer,
  fleetReducer,
  groupReducer,
  assetsReducer,
  tableReducer,
  topicReducer,
  popoutReducer,
  initReducer,
  accountReducer,
  settingReducer
);

export default function(state=Immutable.Map(), action) {
  var handler = handlers[action.type];
  var nextState;
  if (handler) {
    nextState = handler(state, action);
  } else {
    nextState = state;
  }
  if (nextState == state) {
    return state;
  }
  AuSpec.assert(() => ({
    spec: AuSpecs(AuSpec.builder({strict: true})).storeState,
    data: nextState.toJS()
  }));
  return nextState;
}
