import registerServiceWorker from './registerServiceWorker';

//React
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Map as imMap } from 'immutable';

//React Router
import { history } from './history';

//Redux
import { createStore, applyMiddleware, bindActionCreators } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';
import { Provider } from 'react-redux';

//Reducer
import Reducer from './reducers/index';

import TMC from '@autonomic/browser-sdk';
import { fetchJson } from '@au/core/lib/network/fetch';

//NOTE main.scss should be imported before Root and other components
import './css/main.scss';

import { ACCOUNTS_PATH } from './constants';
import Root from './Root';
import * as actionCreators from './ActionCreators';
import DevFlags from './DevFlags';
import IntlProvider from './containers/IntlProvider';
import { getPartition, removePartition } from './utils/linkHelper';
import shared from './shared';

import AuAnalytics, { buildUserInfo } from '@au/core/lib/utils/AuAnalytics';
import { clearStoredDomain, getUserDomain, writeDomainCookie } from '@au/core/lib/utils/domain';
import { AuDomainBootstrap } from '@au/core/lib/components/elements';
import authenticate from '@au/core/lib/utils/auth';

const middlewares = [thunkMiddleware];

if (process.env.NODE_ENV === 'development') {
  const loggerMiddleware = createLogger({
    logger: {
      log: function() {
         if (window.reduxLogEnabled) {
          /* eslint-disable no-console */
          console.log.apply(this, arguments);
          /* eslint-enable no-console */
         }
      }
    }
  });
  middlewares.push(loggerMiddleware);
}

const store = createStore(Reducer, applyMiddleware(...middlewares));
const actions = bindActionCreators(actionCreators, store.dispatch);

store.dispatch({ type: 'REDUX_INIT' });
// trigger settingsMiddleware on init
store.dispatch({ type: 'SET_SETTING' });

// Set locale and unit system based on locale
const locale = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
actions.setLocale(locale);
if (locale.toUpperCase() === 'EN-US') {
  actions.setSystemOfUnits('uscs');
} else {
  actions.setSystemOfUnits('si');
}

if (module.hot) {
  module.hot.accept('./reducers/index', () => {
    const nextRootReducer = require('./reducers/index').default;
    store.replaceReducer(nextRootReducer);
  });
}

window.copyDebugReportToClipboard = function() {
  //In Google Chrome you can then run:
  //copyDebugReportToClipboard()
  //which works with
  //pbpaste > /some/file/path.json
  //on macOS
  /* eslint-disable no-undef */
  if (typeof(copy) === "function") {
  /* eslint-enable no-undef */
    /* eslint-disable no-undef */
    copy(JSON.stringify(store.getState().toJS()));
    /* eslint-enable no-undef */
    return "copied app state to clipboard";
  } else {
    return JSON.stringify(store.getState().toJS());
  }
};

window.auDebug = window.auDebug || {};
window.auDebug.store = store;

var rootProps = {
  store,
  history
};

const root = createRoot(document.getElementById("root"));

// Do the initial data fetch
async function initialFetch() {
  await fetchAccountSettings();
}

function fetchAccountSettings() {
  const accountsService = new TMC.services.Accounts();
  accountsService.accounts.settings().then(resp => {
    shared.account = resp.data;
  }).catch(error => {
    AuAnalytics.trackException({
      description: error.toString(),
      fatal: false
    });
  });
}

function buildAuthConfig(appConfig, partitionConfig) {
  const clientId = (process.env.NODE_ENV === 'production') ? appConfig.clientId : process.env.REACT_APP_AUTH_CLIENT;
  return {
    clientId,
    baseDomain: partitionConfig.baseDomain,
    idpHint: partitionConfig.idpHint
  };
}

async function initialize(appConfig) {
  const partitionConfig = getUserPartitionConfig(appConfig);
  const config = await buildAuthConfig(appConfig, partitionConfig);
  const accounts = store.getState().getIn(ACCOUNTS_PATH, imMap());
  let accountId = store.getState().getIn(['settings', 'partitions', partitionConfig.id, 'accountId']);

  if ((!accountId || !accounts.has(accountId)) && accounts.size) {
    accountId = accounts.first().get('id');
  }

  return authenticate({ TMC, config, shared, accountId }).then(client => {
    const analyticsConfig = {
      ga: {
        trackingId: appConfig.gaTrackingId,
        options: {debug: false}
      }
    };
    if (appConfig.dd?.token) { // NOTE: dashboard does not use datadog
      analyticsConfig.dd = {
        config: {
          token: appConfig.dd.token,
          appId: appConfig.dd.appId
        },
        userInfo: buildUserInfo(client)
      };
    }
    AuAnalytics.initialize(analyticsConfig);
    initializeApp(appConfig, partitionConfig, client);
  });
}

async function initializeApp(appConfig, partitionConfig, authClient) {
  setApplicationSettings(appConfig, partitionConfig);

  shared.authClient = authClient;

  //Now that we know the user has auth'd, persist the partition
  actions.setPartition(partitionConfig.id);
  removePartition();

  await initialFetch(appConfig);

  root.render(<Root {...rootProps} />);

  /*
  scriptjs(getGMapsURL(), () => {
    root.render(<Root {...rootProps} />);
  });
  */
}

function setApplicationSettings(appConfig, partitionConfig) {
  //Base domain, used for creation of the API URL
  window.API_BASE_URL = 'https://api.' + partitionConfig.baseDomain;
  shared.config.baseDomain = partitionConfig.baseDomain;
  shared.config.gMapsKey = appConfig.gMapsKey;

  //Used for the AuSpec to determine whether it runs or not
  window._deployment_mode_ = appConfig.deploymentMode ? appConfig.deploymentMode : "production";

  //Save the Partitions into shared for display later
  shared.config.partitions = appConfig.partitions;
  shared.config.environment = appConfig.environment;
  shared.config.partition = partitionConfig.id;

  /*
   * Allow environment over-ride of the dev flags
   */
  window._devflags_ = {};
  if (appConfig.devflags && typeof appConfig.devflags === 'object'){
    Object.keys(DevFlags).forEach((flag) => {
      if (flag in appConfig.devflags){
        window._devflags_[flag] = appConfig.devflags[flag];
      }
    });
  }

  // initializeGMapsURL(appConfig.gMapsKey);
  // timezonesFetcher.googleApiKey = appConfig.gMapsKey;
}

if (module.hot) {
  module.hot.accept('./Root', () => {
    // If you use Webpack 2 in ES modules mode, you can
    // use <App /> here rather than require() a <NextApp />.
    const NextApp = require('./Root').default;
    root.render(<NextApp {...rootProps} />);
  });
}

registerServiceWorker();

function getUserPartitionConfig(appConfig) {
  let partitionConfig;
  let partitionKey = getPartition() || store.getState().getIn(['settings', 'partition']);

  if (appConfig.partitions) {
    if (partitionKey && appConfig.partitions[partitionKey]){
      partitionConfig = appConfig.partitions[partitionKey];
    }
    else {
      partitionKey = Object.keys(appConfig.partitions).find(key => appConfig.partitions[key].default);

      if (!partitionKey) {
        partitionKey = Object.keys(appConfig.partitions)[0];
      }

      partitionConfig = appConfig.partitions[partitionKey];
    }
    //augment the partition def with its ID. We'll need that later when we
    //persist it after the auth has been successful.
    partitionConfig.id = partitionKey;
  }
  else {
    //this is a problem. there should be at least one partition
    /* eslint-disable no-console */
    console.error("Missing partition definitions", appConfig);
    /* eslint-enable no-console */
  }
  return partitionConfig;
}

function main(domain) {
  //GET USER DOMAIN FROM LOCAL STORAGE
  domain = domain || getUserDomain();

  writeDomainCookie(domain);

  //FETCH APP CONFIG BASED ON THE DOMAIN
  //IF EITHER MISSING, DISPLAY DOMAIN BOOTSTRAP COMPONENT
  if (!domain){
    root.render(
      <Provider store={store}>
        <IntlProvider>
          <AuDomainBootstrap callback={main} />
        </IntlProvider>
      </Provider>
    );
  }
  else {
    fetchJson(`/config/${domain}/application.json`).then(
      appConfig => initialize(appConfig),
      () => { clearStoredDomain(); main(); }
    );
  }
}

main();
