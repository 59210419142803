import React from 'react';
import IPT from 'react-immutable-proptypes';
import * as T from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { some } from 'lodash';

import { INDICATOR_LIST } from '../constants';

import AlertIcon from '@au/core/lib/components/elements/AlertIcon';

import styles from '../css/components/asset_status.module.scss';



export default class AssetStatus extends React.PureComponent {

  static propTypes = {
    indicatorLights: IPT.list,
    driver: T.shape({
      fullName: T.string,
      phone: T.string,
    })
  };

  static defaultProps = {
    driver: null
  }

  render() {
    const indicatorLights = this.props.indicatorLights ? this.props.indicatorLights.toJS() : [];

    return (
      <div className={styles.container}>
        <div className={styles.current}>
          <div className={styles.section}>
            <div className={styles.header}>
              <FormattedMessage id="au.vehicle.indicatorsTitle" />
            </div>
            <div className={styles.status_icon}>
              {INDICATOR_LIST.map((aStat) => {
                return some(indicatorLights, s => s.indicator === aStat && s.status === true) && <div className={styles.indicator_container} key={aStat}>
                  <AlertIcon kind={aStat}/>
                  <div className={styles.indicator_title}>
                    {aStat}
                  </div>
                  </div>;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

}
