import Immutable from 'immutable';
import _ from 'lodash';

export default {
  PROCESS_FIELD_SUGGESTIONS: state => {
    for (let [displayId, filterFields] of state.getIn(['globalFilters', 'filterFields']).entrySeq()) {
      const entityType = filterFields.get('entityType');
      const fieldPath = filterFields.get('target');

      // Count up each asset.field occurance or look up how many unique vehicles map to the other entity Guid
      let count = 0;
      let searchTerms = {};
      for (let entityData of state.getIn(['entities', entityType]).valueSeq()) {
        const fieldVal = entityData.getIn(fieldPath);

        if (fieldVal === undefined) continue;

        if (entityType === 'assets') {
          if (!searchTerms[fieldVal]) {
            searchTerms[fieldVal] = { count: 0 };
          }
          ++searchTerms[fieldVal].count;
          ++count;
        } else {
          // Right now only "alerts" exist beside "assets"...
          if (!searchTerms[fieldVal]) {
            searchTerms[fieldVal] = { assets: Immutable.Set().asMutable() };
          }
          searchTerms[fieldVal].assets.add(entityData.get('vehicle_id'));
        }
      }

      // Process non-asset entity counts
      if (entityType !== 'assets') {
        for (let [fieldVal, meta] of _.entries(searchTerms)) {
          searchTerms[fieldVal] = { count: meta.assets.count() };
          count += meta.assets.count();
        }
      }

      // Remap if need by
      const mapsWith = filterFields.getIn(['suggestions', 'mapsWith']);
      if (mapsWith) {
        searchTerms = mapSuggestions(state, mapsWith, searchTerms);
      }

      // Save the state
      const suggestionsPath = ['globalFilters', 'filterFields', displayId, 'suggestions'];
      state = state
        .setIn([...suggestionsPath, 'items'], Immutable.fromJS(searchTerms))
        .setIn([...suggestionsPath, 'count'], count);
    }

    return state;
  }
};

function mapSuggestions(state, mapsWith, searchTerms) {
  // Maps raw suggestions, such as IDs, to another string
  let mappedSearchTerms = {};
  if (mapsWith === 'fleetGuidToName') {
    // TODO move fleet map to locales or map to locales
    for (let [fleetId, metaData] of _.entries(searchTerms)) {
      const fleetName = state.getIn(['entities', 'fleets', fleetId, 'name']);
      if (fleetName) {
        mappedSearchTerms[fleetName] = {...metaData, mapsTo: fleetId };
      }
    }
  }
  if (mapsWith === 'pidPluggedStatus') {
    // This is a poor solution that doesn't support localization FIXME
    mappedSearchTerms = {
      'Plugged In': { count: 0, mapsTo: 'plugged' },
      'Unplugged': { count: 0, mapsTo: 'unplugged' }
    };
    for (let [pidStatus, metaData] of _.entries(searchTerms)) {
      mappedSearchTerms[pidStatus === 'plugged' ? 'Plugged In' : 'Unplugged'] = {...metaData, mapsTo: pidStatus};
    }
  }
  if (mapsWith === 'alertsKind') {
    const alertsKindMapping = {
      OIL_LEVEL_ALERT: 'Oil Level',
      CHECK_ENGINE_ALERT: 'Check Engine',
      TIRE_PRESSURE_ALERT: 'Tire Pressure',
      TRANSMISSION_ALERT: 'Transmission',
      HEADLAMP_ALERT: 'Headlamp',
      ABS_ALERT: 'ABS',
      PID_BATTERY_ALERT: 'PID'
    };
    for (let [alertsKind, metaData] of _.entries(searchTerms)) {
      const mappedAlertKind = alertsKindMapping[alertsKind];
      if (mappedAlertKind) {
        mappedSearchTerms[mappedAlertKind] = {...metaData, mapsTo: alertsKind};
      }
    }
  }
  if (mapsWith === 'healthIndicator') {
    const indicatorsMapping = {
      'on': 'On',
      'normal': 'Normal'
    };
    mappedSearchTerms = {
      'On': { count: 0, mapsTo: 'on' },
      'Normal': { count: 0, mapsTo: 'normal' }
    };
    for (let [status, metaData] of _.entries(searchTerms)) {
      mappedSearchTerms[indicatorsMapping[status]] = {...metaData, mapsTo: status};
    }
  }
  return mappedSearchTerms;
}
