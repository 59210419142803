import React from 'react';
import cn from 'classnames';
// import { withGoogleMap, GoogleMap } from 'react-google-maps';

import styles from '../css/components/map.module.scss';

//TODO - DISABLE THE ZOOM ON FINGER DRAG, SOMEHOW
// import auMapStyles from '../utils/AuMapStyle.json';

// const AuFixedMap = withGoogleMap(props => (
//   <GoogleMap
//     ref={props.onMapMounted}
//     zoom={props.zoom}
//     center={props.center}
//     onZoomChanged={props.onZoomChanged ? props.onZoomChanged : () => {}}
//     options={{
//       disableDefaultUI: true,
//       disableDoubleClickZoom: true,
//       draggable: false,
//       keyboardShortcuts: false,
//       gestureHandling: 'none',
//       mapTypeControl: false,
//       scaleControlOptions: false,
//       scrollWheel: false,
//       streetViewControl: false,
//       zoomControl: false,
//       fullscreenControl: false,
//       styles: auMapStyles,
//       draggableCursor: 'default',
//     }}
//   >
//     {props.assetMarker}
//     {props.otherElements}
//     {props.customZoomControl}
//   </GoogleMap>
// ));

const AuFixedMap = () => false;

export default (props => (
  <AuFixedMap {...props}
    containerElement={
      <div className={cn(styles.container, props.className)} />
    }
    mapElement={
      <div className={styles.map} />
    }
  />
));