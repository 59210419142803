import { fromJS } from 'immutable';

export default {
  LOAD_FLEETS_SUCCESS: (state, action) => {
    const fleets = {};
    action.fleets.forEach(fleet => {
      fleets[fleet.id] = fleet;
    });
    return state.setIn(['entities', 'fleets'], fromJS(fleets));
  },
};
