import { utils as coreUtils } from '@au/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ActionCreators from '../ActionCreators';

import { VEHICLE_ID_PROP_NAME } from '../constants';
const ROUTE_TITLE_KEY = '_routeMetaPageTitle';

const { equality: { smartEqual } } = coreUtils;

export const defaultMergeProps = (stateProps, dispatchProps, ownProps) => ({...ownProps, ...stateProps, ...dispatchProps});

export var defaultConnectEqualityChecks = {
  areStatesEqual: smartEqual,
  areOwnPropsEqual: smartEqual,
  areStatePropsEqual: smartEqual,
  areMergedPropsEqual: smartEqual
};

export function defaultMapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(ActionCreators, dispatch) };
}

export function breadcrumbsConnectDefaults(Component, mapStateToProps, labelId, schema) {
  const stateToProps = injectRouteMetaState(mapStateToProps);
  const mergeProps = breadcrumbsMergeProps(labelId, schema);
  return connect(
    stateToProps,
    defaultMapDispatchToProps,
    mergeProps,
    defaultConnectEqualityChecks
  )(Component);
}


function injectRouteMetaState(mapStateToProps) {
  return (state, ownProps) => {
    const componentProps = mapStateToProps(state, ownProps);

    let pageTitle;
    const vehicleId = ownProps[VEHICLE_ID_PROP_NAME];
    if (vehicleId) {
      const path = ['entities', 'assets', vehicleId];
      pageTitle = state.getIn([...path, 'name']) || state.getIn([...path, 'vin']) || state.getIn([...path, 'imei']);
    }
    else if (ownProps.geofenceId) {
      pageTitle = state.getIn(['geofences', ownProps.geofenceId, 'name']);
    }
    componentProps[ROUTE_TITLE_KEY] = pageTitle;
    return componentProps;
  };
}

function breadcrumbsMergeProps(labelId, schema) {
  return (stateProps, dispatchProps, ownProps) => {
    const { actions } = dispatchProps;
    const pageTitle = stateProps[ROUTE_TITLE_KEY];

    // TODO: maybe make one action for this...
    actions.setPageTitle(pageTitle);
    if (labelId) actions.setPageTitleId(labelId);
    if (schema) actions.setBreadcrumbsSchema(schema);

    return defaultMergeProps(stateProps, dispatchProps, ownProps);
  };
}
