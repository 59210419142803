import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';

import { wShape } from '@au/core/lib/AuPropTypes';

export const AssetProps = {
  vehicle_id: T.string,
  fleet: T.shape({
    id: T.string,
    name: T.string
  }),
  make: T.string,
  model: T.string,
  status: T.string,
  pid_plugged_in: T.string,
  // odometer: T.number,
  location: T.shape({
    lat: T.number,
    lon: T.number,
    time: T.string
  })
};
export const AlertProps = {
  vehicle_id: T.string,
  kind: T.string,
  message: T.string,
  timestamp: T.string,
  vin: T.string,
};

export const GeoFenceDefProps = {
  id: T.string,
  title: T.string,
  fleetId: T.string,
  timezone: T.string,
  polygon: T.arrayOf(T.shape({
    lat: T.number,
    lng: T.number
  }))
};

export const GeoFencePagePropTypes = {
  params: T.shape({
    geofenceId: T.string
  }),
  geofence: IPT.mapContains({
    id: T.string,
    name: T.string,
    bounding_polygon: IPT.listOf(IPT.mapContains({
      lat: T.number,
      lng: T.number
    })),
    center: IPT.mapContains({
      lat: T.number,
      lng: T.number
    }),
    timezone: T.string,
  }),
  actions: T.shape({
    geoFencePageMounted: T.func
  }),
  assets: IPT.seq
};

export const GeoFencesMapPropTypes = {
  center: T.shape({
    lat: T.number,
    lng: T.number,
    _initial: T.bool
  }),
  zoom: T.number,
  gBounds: T.object, // google.maps.LatLngBounds
  otherElements: T.arrayOf(T.object),
  actions: T.shape({
    geofencesMapChange: T.func,
  })
};

export const TableDefProps = {
  entity: T.string,
  tableId: T.string,
  tablePkField: T.string,
  sort: wShape({
    columnId: T.string,
    direction: T.string
  }),
  searachbox: T.bool,
  searchboxPlaceholderId: T.string,
  columnDefs: T.arrayOf(wShape({
    property: T.string.isRequired,
    labelId: T.string.isRequired,
    searchable: T.bool,
    sortable: T.bool,
    weight: T.number,
    width: T.number.isRequired,
    display: T.bool,
    unitType: T.string,
    unitLabel: T.string,
    flexGrow: T.number,
    flexShrink: T.number,
    formatters: T.oneOfType([
      T.string,
      T.object,
      T.array,
      T.func
    ])
  }))
};

export const ResponsiveTablePropTypes = {
  tableDef: T.shape(TableDefProps),
  tableData: IPT.listOf(IPT.map),
  fetching: T.bool
};
