import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import styles from '../css/components/popout.module.scss';

export default class Popout extends React.Component {
  static propTypes = {
    className: T.string,
    titleDisplayId: T.string,
    onCloseClick: T.func.isRequired
  }

  render() {
    return (
      <div className={cn(styles.container, this.props.className)}>
        <div className={styles.stickout}>
          <div className={styles.circle}></div>
        </div>
        <div className={styles.button} onClick={this.props.onCloseClick}></div>
        <div className={styles.content}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
