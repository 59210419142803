import React from 'react';
import { INDICATOR_LIST } from '../constants';

import AuComponent from '@au/core/lib/components/elements/AuComponent';
import AlertIcon from '@au/core/lib/components/elements/AlertIcon';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import styles from '../css/components/au_current_status_icons.module.scss';

const indicators = new Set(INDICATOR_LIST);
const LIMIT = 3;

export default class AuCurrentStatusIcons extends AuComponent {
  render() {
    const indicatorLights = Object.values(this.props.indicatorLights).filter(light =>
      indicators.has(light.indicator) && light.status === true
    );
    const elements = indicatorLights.slice(0, LIMIT).map(({ indicator }) => (
      <div className={styles.icon} key={indicator}>
        <AlertIcon kind={indicator} />
      </div>
    ));
    const count = indicatorLights.length - LIMIT;

    return (
      <div className={styles.container}>
        {elements}
        {count > 0 && <div className={styles.overflow}>{count}+ <AutoIntl displayId='au.more'/> </div>}
      </div>
    ); 
  }
}