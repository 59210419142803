import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Map as imMap } from 'immutable';

import * as ActionCreators from '../stores/routes/actionCreators/index';
import LatestAssetTrip from '../components/LatestAssetTrip';

export default connect(
  (state, {vehicle_id, vehicleLocation}) => {
    vehicle_id;
    const trips = state.getIn([vehicle_id, 'trips'], imMap());
    const timezone = state.getIn([vehicle_id, 'timezone']);
    return {
      vehicle_id,
      trips,
      vehicleLocation,
      timezone
    };
  },
  dispatch => ({ actions: bindActionCreators(ActionCreators, dispatch) })
  // TODO: determine if AuComponent will trigger renders since we are including the store
  // or at a minimum cause undue processing
)(LatestAssetTrip);