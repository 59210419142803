import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import keyBy from 'lodash/keyBy';
import { AssetProps } from '../AuPropTypes';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import styles from '../css/components/asset_check_list.module.scss';

export default class AssetCheckList extends React.PureComponent {

  static propTypes = {
    asset: T.shape(AssetProps)
  };

  renderTireStatus(tires, doors) {
    const statusList = [];

    ['FRONT_LEFT', 'FRONT_RIGHT', 'REAR_LEFT', 'REAR_RIGHT'].forEach((position) => {
      let tire = tires && tires[position];
      let tireStatus = tire ? tire.status.toLowerCase() : '';
      statusList.push(
        <div className={cn(styles[position.toLowerCase()])} key={position}>
          <div className={styles.status}>
            <AutoIntl displayId="au.vehicle.tirePressureStatus" className={styles.label} tag="div" />
            <div className={cn({
              [styles.low]: tireStatus === 'low',
              [styles.alert]: tireStatus === 'alert',
              [styles.normal]: tireStatus === 'normal'
            })}>
              {tireStatus}
            </div>
          </div>
          <div className={styles.psa}>
            <AutoIntl displayId="au.vehicle.tirePressure" className={styles.label} tag="div" />
            <div className={styles.value}>
              {tire && tire.psa.toFixed(2)}
            </div>
          </div>
          <div className={styles.door}>
            <AutoIntl displayId="au.vehicle.door" className={styles.label} tag="div" />
            <div className={styles.value}>
              { doors[position] && <AutoIntl displayId={`au.vehicle.alert.${doors[position].locked ? '' : 'un'}locked`} />}
            </div>
          </div>
        </div>
      );
    });
    return statusList;
  }

  render() {
    const { asset } = this.props;
    const { tires, doors } = asset;

    return (
      <div className={styles.container_outer}>
        {this.renderTireStatus(keyBy(tires, 'wheel'), keyBy(doors, 'door'))}
      </div>
    );
  }

}
