import { EMAIL_RE, UUID_RE, VIN_RE } from '../constants';

/**
 * Checks whether provided value is empty.
 * @param {string} text Value to validate
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const required = (text) => {
  return text ? null : { errDisplayId: 'au.validation.isRequired' };
};

/**
 * Checks whether two fields are equal.
 * @param {string} field Field name to be compared with
 * @param {string} errDisplayId Localized error message displayId
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const mustMatch = (field, errDisplayId) => {
  /**
   * @param {string} text Current field value
   * @param {object} state Current state
   */
  return (text, state) => {
    return state[field] === text ? null : { errDisplayId: errDisplayId };
  };
};

/**
 * Checks whether value satisfy minimum length requirement.
 * @param {number} length Minimum length
 * @param {string} errDisplayId Localized error message displayId
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const minLength = (length, errDisplayId = 'au.validation.minLength') => {
  /**
   * @param {string} text Current field value
   */
  return (text) => {
    return text.length >= length ? null : { errDisplayId: errDisplayId, values: { length: length } };
  };
};

/**
 * Checks whether value satisfy maximum length requirement.
 * @param {number} length Maximum length
 * @param {string} errDisplayId Localized error message displayId
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const maxLength = (length, errDisplayId = 'au.validation.maxLength') => {
  /**
   * @param {string} text Current field value
   */
  return (text) => {
    return text.length <= length ? null : { errDisplayId: errDisplayId, values: { length: length } };
  };
};

/**
 * Checks whether provided value is a valid email address.
 * @param {string} email Value to validate
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const validEmail = (email) => {
  return EMAIL_RE.test(email) ? null : { errDisplayId: 'au.validation.notValid' };
};

/**
 * Checks whether provided value is a valid UUID
 * @param  {uuid} uuid Value to validate
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const validUuid = (uuid) => {
  return UUID_RE.test(uuid) ? null : { errDisplayId: 'au.validation.notValid' };
};

/**
 * Checks whether provided value is a valid VIN.
 * @param  {string} vin Value to validate
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const validVin = (vin) => {
  return VIN_RE.test(vin) ? null : { errDisplayId: 'au.validation.notValid' };
};

/**
 * Checks whether provided value matches specified regular expression.
 * @param  {string} re           Pattern of the regular expression
 * @param  {string} errDisplayId Localized error message displayId
 * @return {object or null}      Will return error spec object if value is invalid, otherwise NULL
 */
export const regexp = (re, errDisplayId) => {
  /**
   * @param {string} text Current field value
   */
  return (text) => {
    return RegExp(re).test(text) ? null : { errDisplayId, values: { re } };
  };
};
