import { persistState } from '@au/core/lib/utils/stateDelegators';
import { entitiesToMap } from '@au/core/lib/utils/immutableHelpers';
import { ACCOUNTS_PATH } from '../constants';

export default {
  RECEIVED_ACCOUNTS: (state, action) => {
    const { pkField, data } = action;
    return state.setIn(ACCOUNTS_PATH, entitiesToMap(data, pkField));
  },
  SHOW_ACCOUNT_DIALOG: (state) => {
    return state.set('showAccountDialog', true);
  },
  HIDE_ACCOUNT_DIALOG: (state) => {
    return state.set('showAccountDialog', false);
  },
  SET_ACCOUNT_ID: (state, action) => {
    state = persistState(state)
      .call('setIn', ['settings', 'partitions', state.getIn(['settings', 'partition']), 'accountId'], action.accountId)
      .state;
    return state;
  }

};
