import { fromJS } from 'immutable';
import _ from 'lodash';

import buildUnitConverter from '../utils/unitConversion';
import { emptyVehicle, getValue, convertVehicleUnits, getGeoFenceSet, inferIgnitionStatus } from '@au/core/lib/utils/assets';

const stremedVehiclesIds = new Set();

// function metricEnumValueRetriever(enumValue) {
//   let retVal;
//
//   const enumValueNames = [
//     "gear_position",
//     "impact_status",
//     "occupancy_status",
//     "seatbelt_status",
//     "ignition_status",
//     "off_on_status",
//     "power_take_off_status",
//     "alarm_status",
//     "tire_pressure_status",
//     "door_status",
//     "door_lock_status",
//     "engine_status"
//   ];
//
//   enumValueNames.some(name => {
//     if (enumValue.hasOwnProperty(name)){
//       retVal = enumValue[name].toLowerCase();
//       return retVal;
//     }
//   });
//
//   return retVal;
// }

function metricValueRetriever(metric) {
  // for the 'fancy' format
  if (Object.prototype.hasOwnProperty.call(metric, 'value')) {
    return metric.value;
  }
}

  // retired 'flatten' format
  // if (metric.hasOwnProperty("bool_value")) {
  //   return metric.bool_value;
  // }
  // if (metric.hasOwnProperty("int64_value")) {
  //   return metric.int64_value;
  // }
  // if (metric.hasOwnProperty("double_value")) {
  //   return metric.double_value.toFixed(1);
  // }
  // if (metric.hasOwnProperty("string_value")) {
  //   return metric.string_value;
  // }
  // if (metric.hasOwnProperty("position_value")) {
  //   let locationPoint = false;
  //   if (metric.position_value.location[0].hasOwnProperty('three_d_point')) {
  //     locationPoint = metric.position_value.location[0].three_d_point;
  //   }
  //   else {
  //     locationPoint = metric.position_value.location[0].point;
  //   }
  //
  //   if (locationPoint.hasOwnProperty('latitude') && locationPoint.hasOwnProperty('longitude')) {
  //     return {
  //       lat: locationPoint.latitude,
  //       lon: locationPoint.longitude
  //     };
  //   }
  // }
  // if (metric.hasOwnProperty("speed_value")) {
  //   return metric.speed_value.speed;
  // }
  // if (metric.hasOwnProperty("indicator_value")) {
  //   return metric.indicator_value.indicator_state;
  // }
  // if (metric.hasOwnProperty("enum_value")) {
  //   return metricEnumValueRetriever(metric.enum_value);
  // }
  // }

function assetDataRetriever(vehicleData, tags) {
  tags.forEach(t => {
    vehicleData[t.name.string_name] = t.value.string_value;
  });
}

function seatDataRetriever(vehicleData, metric, fieldName, fieldValue) {
  if (!vehicleData.seats) {
    vehicleData.seats = [];
    for (let seat of metric) {
      vehicleData.seats.push({
        'row': seat.positionRow,
        'seat': seat.positionSeat,
        'role': seat.vehicleOccupantRole,
        [fieldName]: seat.value === fieldValue
      });
    }
  } else {
    for (let currentSeat of metric) {
      for (let prevSeat of vehicleData.seats) {
        if (currentSeat.positionRow === prevSeat.row && currentSeat.positionSeat === prevSeat.seat) {
          prevSeat[fieldName] = currentSeat.value === fieldValue;
          break;
        }
      }
    }
  }
}

function tireRetriever(vehicleData, metric, fieldName) {
  if (!vehicleData.tires) {
    vehicleData.tires = [];
    for (let tire of metric) {
      vehicleData.tires.push({
        'wheel': tire.vehicleWheel,
        [fieldName]: tire.value
      });
    }
  } else {
    for (let currentTire of metric) {
      for (let prevTire of vehicleData.tires) {
        if (currentTire.vehicleWheel === prevTire.wheel) {
          prevTire[fieldName] = currentTire.value;
          break;
        }
      }
    }
  }
}

function doorRetriever(vehicleData, metric, fieldName) {
  if (!vehicleData.doors) {
    vehicleData.doors = [];
    for (let door of metric) {
      vehicleData.doors.push({
        'door': door.vehicleDoor,
        [fieldName]: door.value
      });
    }
  } else {
    for (let currentDoor of metric) {
      for (let prevDoor of vehicleData.doors) {
        if (currentDoor.vehicleWheel === prevDoor.wheel) {
          prevDoor[fieldName] = currentDoor.value;
          break;
        }
      }
    }
  }
}


function indicatorLightRetriever(vehicleData, metric) {
  if (!vehicleData.indicatorLights) {
    vehicleData.indicatorLights = [];
  }
  for (let indicator in metric) {
    vehicleData.indicatorLights.push({
      indicator,
      'status': metric[indicator].value
    });
  }
}

function positionRetriever(vehicleData, location) {
  if (!vehicleData.location) {
    vehicleData.location = {...location};
   }
}

function fancyToUseful(metrics, vehicleData) {
  for (let [metricName, metric] of Object.entries(metrics)) {
    metricName = _.snakeCase(metricName);
    if (metricName == 'position') {
      positionRetriever(vehicleData, metric.value.location);
    }

    if (metricName == 'asset_data') {
      assetDataRetriever(vehicleData, metric.element.tags);
    }
    else if (metricName == 'indicators') {
      indicatorLightRetriever(vehicleData, metric);
    }
    else if (metricName == 'tire_pressure') {
      tireRetriever(vehicleData, metric, 'psa');
    }
    else if (metricName == 'tire_pressure_status') {
      tireRetriever(vehicleData, metric, 'status');
    }
    else if (metricName == 'door_lock_status') {
      doorRetriever(vehicleData, metric, "locked");
    }
    else if (metricName == 'seat_occupancy_status') {
      seatDataRetriever(vehicleData, metric, "occupied", "OCCUPIED");
    }
    else if (metricName == 'seat_belt_status') {
      seatDataRetriever(vehicleData, metric, "buckled", "BUCKLED");
    }
    else {
      let mVal = metricValueRetriever(metric);
      if (typeof mVal !== 'undefined') {
        vehicleData[metricName] = mVal;
      }
    }

    //hackery to make look like gallons
    if (metricName == "fuel_level"){
      vehicleData[metricName] = Number.isNaN(vehicleData[metricName]) ? vehicleData[metricName] : Number(vehicleData[metricName]).toFixed(1);
    }
  }

  return vehicleData;
}

function getFancyBaseProperties(v) {
  const id = v.vehicleId;
  const { vin } = v;
  return {
    id,
    vehicle_id: id,
    vin,
    timestamp: Object.values(v.metrics || [])
      .map(m => m.updateTime)
      .filter(t => t !== undefined)
      .sort()
      .pop()
  };
}

export default {
  CLEAR_ASSETS: (state) => {
    const newState = state.setIn(['app', 'initialized'], false);
    return newState.setIn(['entities', 'assets'], fromJS({}));
  },
  RECEIVED_FANCY_GROUP_VEHICLES: (state, action) => {
    const partition = state.getIn(['settings', 'partition']);
    const groupVehicleTelemetry = action.telemetry;

    if (state.getIn(['settings', 'partitions', partition, 'groupId']) !== action.groupId) {
      // groups doesn't match
      return state;
    }

    let newState = state;
    let initialized = state.getIn(['app', 'initialized'], false);

    const processedVehicles = {};
    const converter = buildUnitConverter(state.get('systemOfUnits'));

    groupVehicleTelemetry.forEach(vehicleTelemetry => {
      const vehicleData = getFancyBaseProperties(vehicleTelemetry);
      const prevVehicleData = state.getIn(['entities', 'assets', vehicleData.id], fromJS({})).toJS();

      try {
        fancyToUseful(vehicleTelemetry.metrics || [], vehicleData);
      }
      catch (e) {
        /* eslint-disable no-console */
        console.error(e, vehicleTelemetry);
        /* eslint-enable no-console */
      }

      let fuel_level = vehicleData.fuel_level;

      //TODO - UPDATE unit conversion to understand fuel_level percentage
      convertVehicleUnits(vehicleData, converter);

      vehicleData.fuel_level = fuel_level;
      processedVehicles[vehicleData.id] = {...emptyVehicle, ...prevVehicleData, ...vehicleData};
    });

    newState = newState.mergeDeepIn(['entities', 'assets'], fromJS(processedVehicles));

    if (!initialized) {
      initialized = true;
      newState = newState.mergeIn(['app'], fromJS({ initialized }));
    }

    return newState;
  },
  RECEIVED_VEHICLE_DATA_SUCCESS: (state, action) => {
    const vehicleTelemetry = action.telemetry;
    let newState = state.mergeIn(['vehicle_id'], vehicleTelemetry);
    return newState;
  },
  RECEIVED_RESOURCES_VEHICLES: (state, action) => {
    const vehicles = action.items;

    const processedVehicles = {};
    for (let vehicleData of vehicles) {
      const { id } = vehicleData;

      vehicleData.timestamp = vehicleData.updateTime;

      vehicleData.vehicle_id = id; // TODO: use "id" in the rest of the app

      // Unit conversion isn't necessary since the resources service doesn't return those units
      const prevVehicleData = state.getIn(['entities', 'assets', id], fromJS({})).toJS();

      //The VSS feed may create a timestamp that is newer than the Resource Service date.
      if (vehicleData.timestamp < prevVehicleData.timestamp){
        delete vehicleData.timestamp;
      }

      processedVehicles[id] = {...emptyVehicle, ...prevVehicleData, ...vehicleData};
    }
    return state.mergeDeepIn(['entities', 'assets'], fromJS(processedVehicles));
  },
  RECEIVED_PARTIAL_VEHICLE_CHANGES: (state, action) => {
    /**
     * This function is expected to handle a subset of the vehicle fields
     * generally used by VSS.
     */

    const converter = buildUnitConverter(state.get('systemOfUnits'));
    let initialized = state.getIn(['app', 'initialized'], false);

    let newState = state;
    for (let message of action.messages) {
      // Filter any returned fields that weren't requested
      // FIXME: not sure if we will always need this
      message.fields = _.pick(message.fields, action.requestedFields);

      // Convert fields with timestamp to just their value
      for (let [field, val] of _.entries(message.fields)) {
        // FIXME: location should be stored as a nested object
        if (field === 'location' && val !== null && typeof val ==='object') {
          message.fields[field] = {
            lat: getValue(val.lat),
            lon: getValue(val.lon)
          };
        }
        else if (field === 'indicators') {
          // Do nothing since we store indicators as is
        }
        else {
          message.fields[field] = getValue(val);
        }
      }

      let incoming = convertVehicleUnits(message.fields, converter);

      // TODO: update convertVehicleUnits to work better with partial fields (sometimes gives back nulls)
      if (incoming.speed === null) {
        delete incoming.speed;
      }
      if (incoming.fuel_level === null) {
        delete incoming.fuel_level;
      }

      const currentAsset = newState.getIn(['entities', 'assets', message.vehicle_id]);
      const current = _.pick(currentAsset.toJS(), Object.keys(incoming));

      // Skip updating the state if nothing has changed
      if (fromJS(incoming).equals(fromJS(current))) {
        // TODO FIXME - most/all of these are GPS location changes that are idential
        // I suspect some floating point comparison being done in VSS before
        // rounding the GPS coordinates.
        continue;
      }

      // Add current geofence
      if (incoming.location) {
        incoming.geoFences = getGeoFenceSet(incoming.location, state.get('geofences'));
      }

      incoming.timestamp = message.timestamp;
      const merged = _.merge(currentAsset.toJS(), incoming);
      merged.ignition_status = inferIgnitionStatus(merged);
      newState = newState.setIn(['entities', 'assets', message.vehicle_id], fromJS(merged));

      if (!initialized) {
        if (!stremedVehiclesIds.has(message.vehicle_id)) {
          stremedVehiclesIds.add(message.vehicle_id);
        }
        else {
          initialized = true;
          newState = newState.mergeIn(['app'], fromJS({ initialized }));
          // do cleanup
          stremedVehiclesIds.clear();
        }
      }
    }

    return newState;
  },
};
