// General purpose query builder for family of API endpoints

export default class QueryBuilder {
  constructor() {
    this._scopes = {
      generalFilters: []
    };
  }

  requestId(requestId) {
    this._requestId = requestId;
    return this;
  }

  filter(...args) {
    // Take multiple arrays of [$field, $condition]
    this._parseArgumentsArray(args, 'filter', this._scopes.generalFilters);
    return this;
  }

  orFilters(...args) {
    // Take multiple arrays of [$field, $condition]
    const orThese = args.map(x => this._parseFieldCondition(x[0], x[1]));
    this._scopes.generalFilters.push({ filter: orThese.join(' OR ') });
    return this;
  }

  _parseArgumentsArray(args, key, destArray) {
    // For functions taking two arguments, array of two or multiple arrays of two
    if (args.length === 2 && !Array.isArray(args[0]) && !Array.isArray(args[1])) {
      destArray.push({ [key]: this._parseFieldCondition(args[0], args[1]) });
    } else {
        for (let arg of args) {
          if (Array.isArray(arg)) {
           destArray.push({ [key]: this._parseFieldCondition(arg[0], arg[1]) });
          } // else invalid
        }
    }
    return destArray;
  }

  _castDate(date) {
    if (date instanceof Date) {
      return date.toISOString();
    }
    else if (typeof date === 'string') {
      // TODO: support custom timezone offset
      return date;
    }
    return null;
  }

  _parseFieldCondition(field, condition) {
    // Parses these separated by a colon and added quotes if necessary
    if (condition.includes(' ')) {
      condition = `"${condition}"`;
    }
    return `${field}:${condition}`;
  }

  reduceBy(...args) {
    // Only makes sense to apply as the inner aggregation so this will override
    this._aggregations = this._reduceBy(args);
    return this;
  }

  _reduceBy(args) {
    // TODO: support precentiles
    const reductions = [];
    return this._parseArgumentsArray(args, 'reduce_by', reductions);
  }

  andReduceBy(...args) {
    // Applies reduceBy to current aggregation, usually used in conjunction with
    // a group by.
    for (let reduction of this._reduceBy(args)) {
      this._aggregations.push(reduction);
    }
    return this;
  }

  groupBy(fieldOrIntervalOrArray, interval, offset=this._myOffset) {
    let fieldOrInterval;
    if (Array.isArray(fieldOrIntervalOrArray)) {
      fieldOrInterval = fieldOrIntervalOrArray[0];
      if (fieldOrIntervalOrArray.length >= 2) {
        interval = fieldOrIntervalOrArray[1];
      }
      if (fieldOrIntervalOrArray.length === 3) {
        offset = fieldOrIntervalOrArray[2];
      }
    } else {
      fieldOrInterval = fieldOrIntervalOrArray;
    }

    let groupBy;
    if (fieldOrInterval === 'interval') {
      let groupByInterval = `${fieldOrInterval}:${interval}`;
      if (offset) {
        groupByInterval += `:${offset}`;
      }
      groupBy = { group_by: groupByInterval };
    } else {
      groupBy = { group_by: fieldOrInterval };
    }

    if (this._aggregations) {
      groupBy.aggregations = this._aggregations;
    }
    this._aggregations = [ groupBy ];

    return this;
  }

  groupByInterval(interval, offset=this._myOffset) {
    return this.groupBy('interval', interval, offset);
  }

  build() {
    return JSON.parse(this.toString());
  }

  get query() {
    return this.build();
  }

  // TODO: implement `_formQuery()` which will build the object
  _formQuery() {
    const scopes = [...this._scopes.generalFilters];
    const query = { scopes };

    if (this._aggregations) {
      query.aggregations = this._aggregations;
    }

    if (this._requestId) {
      query.request_id = this._requestId;
    }

    return query;
  }

  toString() {
    const query = this._formQuery();
    // Check if we can generate a valid query
    if (!query.scopes.length) {
      throw new Error('A scope is required');
    }
    return JSON.stringify(query);
  }

  _getRequiredInternals() {
    // Used for making clones
    // TODO: make this a static list of internal properties instead
    return {
      _myOffset: this._myOffset,
      _scopes: this._scopes,
      _aggregations: this._aggregations,
      _requestId: this._requestId
    };
  }

  clone() {
    const stateCopy = JSON.parse(JSON.stringify(this._getRequiredInternals()));
    return Object.assign(new this.constructor(), stateCopy);
  }

  useMyOffset(minutesOffset) {
    // Will use the local TZ offset for the offset to non-explicit offsets
    this._myOffset = this.constructor.myOffset(minutesOffset);
    return this;
  }

  static myOffset(minutesOffset=(new Date()).getTimezoneOffset()) {
    /**
     * Gets the timezone offset in the format for queries. Ex: -08:00 for PST.
     */
     const sign = minutesOffset > 0 ? '-' : '+'; // getTimezoneOffset returns 480 for PST
     const hours = String(Math.abs(Math.floor(minutesOffset / 60)));
     const minutes = String(Math.abs(minutesOffset % 60));
     const hoursPadded = hours.length === 1 ? `0${hours}` : hours;
     const minutesPadded = minutes.length === 1 ? `0${minutes}` : minutes;
     return `${sign}${hoursPadded}:${minutesPadded}`;
  }

  static get start() {
    return new this();
  }
}
