import React, { PureComponent } from 'react';
import IPT from 'react-immutable-proptypes';
import AssetMetricTable from './AssetMetricTable';
import AssetStatus from './AssetStatus';
import AssetOccupancy from './AssetOccupancy';

import styles from '../css/components/asset_metrics.module.scss';

export default class AssetMetrics extends PureComponent {

  static propTypes = {
    asset: IPT.map
  }

  render() {
    const { asset, actions } = this.props;

    if (!asset) {
      // There are cases where the vehicle_id might not be set when the user
      // refreshes the browser directly from the vehicle details url.
      return false;
    }

    return (
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.info}>
            <AssetMetricTable asset={asset} actions={actions} />
          </div>
          <div className={styles.status}>
            <AssetStatus indicatorLights={asset.get('indicatorLights')} />
          </div>
          <div className={styles.status}>
            <AssetOccupancy asset={asset} />
          </div>
        </div>
      </div>
    );
  }
}