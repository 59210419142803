import React from 'react';
import * as T from 'prop-types';

import styles from '../css/components/metric_table.module.scss';

export default class MetricTable extends React.PureComponent {

  static propTypes = {
    cells: T.array
  }

  render() {
    let { cells } = this.props;

    return (
      <div className={styles.container}>
        {cells && cells.map((row, i) =>
          <div key={'row'+i} className={styles.row}>
            {row.map((cell, j) =>
              j == 0 && <div key={'label'+i} className={styles.label}>{cell} :</div> ||
              <div key={'value'+i} className={styles.value}>{cell}</div>
            )}
          </div>
        )}
      </div>
    );
  }
}
