
import { drivingScoreCoefficients } from '../constants';

export function calculateScore(eventCounters, dist) {
  let score = 0;
  const distance = parseInt(dist, 10);

  if (distance > 0) {
    score = Object.entries(drivingScoreCoefficients).reduce((acc, event) => {
      const [ name, multiplier ] = event;
      return acc + (parseInt(eventCounters[name], 10) || 0) * multiplier;
    }, 0) / distance;
  }

  return Math.ceil(score);
}
