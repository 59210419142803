import React from 'react';
import * as T from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';

import GroupSelector from './GroupSelector';
import DashboardMap from '../containers/DashboardMap';
import DashboardStats from '../containers/DashboardStats';

import styles from '../css/components/dashboard.module.scss';
export default class Dashboard extends React.PureComponent {

  static propTypes = {
    groupId: T.string,
    sourceId: T.string,
    showGroupSelector: T.bool,
    actions: T.shape({
      showGroupsDialog: T.func.isRequired
    }).isRequired
  }

  handleMapResized() {
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    const { showGroupSelector, groupId, sourceId, actions } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.panels}>
          <div className={styles.stats}>
            <DashboardStats fullScreen={true} />
          </div>
        </div>
        { showGroupSelector && groupId &&
          <GroupSelector
            className={styles.group}
            groupId={groupId}
            sourceId={sourceId}
            onClick={actions.showGroupsDialog}
          />
        }
        <div className={styles.map}>
          <DashboardMap key={`group_${groupId}`} />
          <ReactResizeDetector handleWidth handleHeight onResize={this.handleMapResized} />
        </div>
      </div>
    );
  }
}
