import merge from 'lodash/merge';

import coreLocales from '@au/core/lib/locales';

export const defaultEnglish = {
  'au': "Hello, {name}",
  'au.copied': 'Copied',
  'au.copy': 'Copy',
  'au.noop': " ",
  'au.accounts.title.selectAccount': 'Select Account',
  'au.accounts.title.changeAccount': 'Change Account',
  'au.accounts.saveChange': 'Save Change',
  'au.accounts.cancel': 'Cancel',
  'au.accounts.changeAccountTo': 'Change account to',
  'au.accounts.currentAccount': 'Current Account',
  'au.inputSource.groupId': "Group Id",
  'au.inputSource.groupName': "Group",
  'au.inputSource.sourceId': "Source Id",
  'au.inputSource.change': 'Change',
  'au.inputSource.selectTitle': 'Set Data Input Source',
  'au.inputSource.changeTitle': 'Change Data Input Source',
  'au.inputSource.changePlaceholder': 'Type',
  'au.inputSource.saveChange': 'Save',
  'au.inputSource.cancel': 'Cancel',
  'au.more': 'more',
  'au.data.copyJson': 'Copy JSON',
  'au.data.endpoint': 'Endpoint URL',
  'au.event.details': 'Details',
  'au.event.seatBeltStatusWhileMoving': 'seatBeltStatusWhileMoving',
  'au.event.type': 'Type',
  'au.event.selectEventType': 'Select event type',
  'au.event.harshBraking': 'harshBraking',
  'au.event.harshAcceleration': 'harshAcceleration',
  'au.event.impact': 'impact',
  'au.percentage': "{number}%",
  'au.loadingText': "Updating...",
  'au.poweredBy': "© 2019 Autonomic, LLC. All rights reserved.",
  'au.fuelConsumed': "Fuel Consumed",
  'au.distanceTraveled': "Distance Traveled",
  'au.averageEconomy': "Average Economy",
  'au.section.title.dashboard': "Dashboard",
  'au.section.title.map': "Map",
  'au.section.title.analytics': "Analytics",
  'au.section.title.vehicles':  "Vehicles",
  'au.section.title.geofences': "Geofences",
  'au.section.title.drivers':   "Drivers",
  'au.section.title.component_gallery': "Components",
  'au.section.title.logout': "Log Out",
  'au.section.title.scenarios': "Scenarios",
  'au.unit.si.dist.full': "kilometers",
  'au.unit.si.dist.abbr': "km",
  'au.unit.si.vol.full': "liters",
  'au.unit.si.vol.abbr': "L",
  'au.unit.si.rate.full': "kilometers per hour",
  'au.unit.si.rate.abbr': "km/h",
  'au.unit.si.econ.full': "kilometers per liter",
  'au.unit.si.econ.abbr': "km/L",
  'au.unit.si.minute.full': "minute",
  'au.unit.si.minute.abbr': "min.",
  'au.unit.si.hour.full': "hour",
  'au.unit.si.hour.abbr': "hr.",
  'au.unit.si.percentage.full': "%",
  'au.unit.si.percentage.abbr': "%",
  'au.unit.uscs.dist.full': "miles",
  'au.unit.uscs.dist.abbr': "mi.",
  'au.unit.uscs.vol.full': "gallons",
  'au.unit.uscs.vol.abbr': "gal",
  'au.unit.uscs.rate.full': "miles per hour",
  'au.unit.uscs.rate.abbr': "mph",
  'au.unit.uscs.econ.full': "miles per gallon",
  'au.unit.uscs.econ.abbr': "mpg",
  'au.unit.uscs.minute.full': "minute",
  'au.unit.uscs.minute.abbr': "min.",
  'au.unit.uscs.hour.full': "hour",
  'au.unit.uscs.hour.abbr': "hr.",
  'au.unit.uscs.percentage.full': "%",
  'au.unit.uscs.percentage.abbr': "%",
  'au.vehicle.title': "Vehicle",
  'au.vehicle.name': "Name",
  'au.vehicle.vin': "VIN",
  'au.vehicle.make': "Make",
  'au.vehicle.model': "Model",
  'au.vehicle.year': "Year",
  'au.vehicle.speed': "Speed",
  'au.vehicle.engineCoolantTemp': "Coolant Temp",
  'au.vehicle.engineOilTemp': "Engine Oil Temp",
  'au.vehicle.engineSpeed': "RPMs",
  'au.vehicle.fuel': "Fuel",
  'au.vehicle.fuelLevel': "Fuel",
  'au.vehicle.odometer': "Odometer",
  'au.vehicle.tags': "Tags",
  'au.vehicle.transGearPos': "Gear",
  'au.vehicle.transOilTemp': "Trans Oil Temp",
  'au.vehicle.fleet': "Fleet",
  'au.vehicle.ignitionStatus': "Ignition",
  'au.vehicle.imei': "IMEI",
  'au.vehicle.lastUpdate': "Last Update",
  'au.vehicle.gps': "GPS",
  'au.vehicle.unknownVal': "Unknown",
  'au.vehicle.tiresAndDoors': "Tires & Doors",
  'au.vehicle.needsService': "Needs Service",
  'au.vehicle.occupancy': "Occupancy",
  'au.vehicle.needService': "Need Service",
  'au.vehicle.pidPluggedIn': "PID Plugged In",
  'au.vehicle.currentStatus': 'Current Status',
  'au.vehicle.currentDriver': 'Current Driver',
  'au.vehicle.currentLocation': "Current Location",
  'au.vehicle.geofence': 'Geofence',
  'au.vehicle.driver': 'Driver',
  'au.vehicle.passenger': 'Passenger',
  'au.vehicle.contactDriver': 'Contact',
  'au.vehicle.alert.system': 'System',
  'au.vehicle.alert.locked': 'Locked',
  'au.vehicle.alert.unlocked': 'Unlocked',
  'au.vehicle.alert.temp': 'Temp',
  'au.vehicle.alert.unknown': 'Unknown',
  'au.vehicle.gear.drive': 'Drive',
  'au.vehicle.gear.park': 'Park',
  'au.vehicle.gear.neutral': 'Neutral',
  'au.vehicle.gear.reverse': 'Reverse',
  'au.vehicle.gear.undefined': 'Unknown',
  'au.vehicle.note': 'Note',
  'au.vehicle.trips': 'Trips',
  'au.vehicle.vehicleInfo': 'Vehicle Info',
  'au.vehicle.detailsTitle': 'Vehicle Details',
  'au.vehicle.role': 'Role',
  'au.vehicle.row': 'Row',
  'au.vehicle.seat': 'Seat',
  'au.vehicle.vacancy': 'Occupied',
  'au.vehicle.seatbelt': 'Buckled',
  'au.vehicle.tirePressure': 'PSA',
  'au.vehicle.tirePressureStatus': 'Status',
  'au.vehicle.door': 'Door',
  'au.vehicle.indicatorsTitle': 'Indicators',
  'au.vehicle.statsTitle': "Vehicle Metrics",
  'au.vehicle.section.title.metrics': "Metrics",
  'au.vehicle.section.title.data': "Data",
  'au.vehicle.section.title.map': "Current Location",
  'au.vehicle.section.title.analytics': "Analytics",
  'au.vehicle.section.title.geofences': "Geofences",
  'au.vehicle.section.title.trips': "Latest Trip",
  'au.vehicles.filterPlaceholder': "Filter Vehicles",
  'au.table.dataEmptyMessage': "Looks like there's nothing to display at the moment.",
  'au.table.filterEmptyMessage': "Looks like there's nothing matching your search filter.",
  'au.table.download': "Download as CSV",
  'au.analytics.fuelChart.title': "Fleet Fuel Consumed",
  'au.analytics.distChart.title': "Fleet Distance Traveled",
  'au.analytics.econChart.title': "Fleet Average Economy",
  'au.analytics.distAvgChart.title': "Dist. Traveled per Vehicle",
  'au.geofence.name': 'Name',
  'au.geofence.vehiclesCount': '{count, number} {count, plural, one {Vehicle} other {Vehicles}}',
  'au.geofence.numVehicles': '# of Current Vehicles',
  'au.geofencepage.table.vinLabel': 'Vehicle Name',
  'au.geofencepage.backlink': "Back to list",
  'au.geofencepage.filterPlaceholder': "Search by VIN",
  'au.geofence.deleteWarning': "Are you sure you want to delete this Geofence?",
  'au.geofence.editor.draw' : 'Draw',
  'au.geofence.editor.drag' : 'Drag',
  'au.geofence.editor.reset': 'Clear Drawing',
  'au.geofence.editor.map'  : 'Map',
  'au.geofence.editor.satellite': 'Satellite',
  'au.geofence.editor.autocomplete.placeholder': 'Search for an address',
  'au.geofence.editor.name.placeholder': 'Type a geofence name',
  'au.geofence.editor.fleet': 'Fleet Name',
  'au.geofence.editor.edit': 'Edit Geofence',
  'au.geofence.editor.new': 'Create New Geofence',
  'au.geofence.editor.createBtn': 'Create Geofence',
  'au.geofence.editor.instructions': "Click a drawing tool to draw a shape on the map",
  'au.geofence.saveError': 'An error occurred trying to save the Geofence.',
  'au.geofence.saveNoPolygonError': 'A Geofence region is needed to save',
  'au.geofences.filterPlaceholder': 'Search Geofence',
  'au.geofences.addNew': 'Add a Geofence',
  'au.geofences.breadcrumbs.createGeofence': "Create Geofence",
  'au.geofences.title': "Geofences",
  'au.geofences.noHistory': "Looks like this vehicle has no Geofence history",
  'au.geofences.mostFrequent': 'Frequently Visited',
  'au.geofences.mostRecent': 'Most Recent',
  'au.geofences.list': "List",
  'au.geofences.map': "Map",
  'au.asset.analytics.fuelChart.title': "Fuel Consumed",
  'au.asset.analytics.distChart.title': "Distance Traveled",
  'au.asset.analytics.econChart.title': "Average Economy",
  'au.geofence.direction': "Event Type",
  'au.geofence.eventTime': "Time",
  'au.geofence.enterTime': "Enter Time",
  'au.geofence.exitTime': "Exit Time",
  'au.geofence.entries': "{count, number} {count, plural, one {time} other {times}} / last 7 days",
  'au.geofence.duration': "Duration",
  'au.label.menu': "Menu",
  'au.label.close': "Close",
  'au.breadcrumbs.add': 'Add',
  'au.breadcrumbs.back': 'Back',
  'au.breadcrumbs.save': 'Save',
  'au.breadcrumbs.cancel': 'Cancel',
  'au.time.oneWeek': "1 Week",
  'au.time.twoWeeks': "2 Weeks",
  'au.time.oneMonth': "1 Month",
  'au.time.twoMonths': "2 Months",
  'au.yes': "Yes",
  'au.no': "No",
  'au.on': "On",
  'au.off': "Off",
  'au.multiselect.placeholder': "Drop down or Type",
  'au.filters.numVehicles': "{count, plural, =0 {No Vehicles} one {1 Vehicle} other {# Vehicles}}",
  'au.filters.tooMany': "{count, plural, =0 {0 Filters} one {1 Filter} other {# Filters}}",
  'au.filters.muzzledWarning': "No vehicles match your filter criteria",
  'au.filters.resetWarning': "Are you sure you want to remove all filters?",
  'au.filters.confirmResetButton': "Yes, Remove All",
  'au.filters.addFilters': "Add Filters",
  'au.filters.currentFilters': "Current Filters",
  'au.filters.fleetID': "Fleet ID",
  'au.filters.unknown': "Unknown",
  'au.filters.vinFilters': "VIN Filters",
  'au.filters.imeiFilters': "IMEI Filters",
  'au.filters.fleetFilters': "Fleet Filters",
  'au.filters.fleetIdFilters': "Fleet ID Filters",
  'au.filters.pidStatusFilters': "PID Status Filters",
  'au.filters.modelFilters': "Model Filters",
  'au.filters.makeFilters': "Make Filters",
  'au.filters.yearFilters': "Year Filters",
  'au.filters.oilPressureLampFilters': "Oil Pressure Lamp Filters",
  'au.filters.brakeSystemLampFilters': "Brake System Lamp Filters",
  'au.filters.ignitionLampFilters': "Ignition Lamp Filters",
  'au.filters.dieselExhaustFluidLampFilters': "Diesel Exhaust Fluid Lamp Filters",
  'au.filters.waterInFuelLampFilters': "Water In Fuel Lamp Filters",
  'au.filters.collisionWarningLampFilters': "Collision Warning Lamp Filters",
  'au.filters.automaticSpeedLimiterLampFilters': "Automatic Speed Limiter Lamp Filters",
  'au.filters.lowWasherFluidLampFilters': "Low Washer Fluid Lamp Filters",
  'au.filters.tractionControlLampFilters': "Traction Control Lamp Filters",
  'au.filters.engineWarningLampFilters': "Engine Warning Lamp Filters",
  'au.filters.airbagLampFilters': "Airbag Lamp Filters",
  'au.filters.absLampFilters': "ABS Lamp Filters",
  'au.filters.engineCoolantTempLampFilters': "Engine Coolant Temperature Lamp Filters",
  'au.filters.laneDepartureLampFilters': "Lane Departure Lamp Filters",
  'au.filters.tirePressureFrontLeftFilters': "Front Left Tire Pressure Lamp Filters",
  'au.filters.tirePressureFrontRightFilters': "Front Right Tire Pressure Lamp Filters",
  'au.filters.tirePressureRearLeftFilters': "Rear Left Tire Pressure Lamp Filters",
  'au.filters.tirePressureRearRightFilters': "Rear Right Tire Pressure Lamp Filters",
  'au.filters.unknownFilters': "Unknown Filters",
  'au.filters.reset': "Remove All filters",
  'au.filters.cancel': "Cancel",
  'au.dashboard.stat.total': 'Total #',
  'au.dashboard.stat.service': 'Need Service',
  'au.dashboard.stat.ignitionOff': 'Ignition Off',
  'au.dashboard.stat.idling': 'Idling',
  'au.dashboard.stat.driving': 'Driving',
  'au.dashboard.stat.utilization': 'Utilization',
  'au.dashboard.miniCharts.today': 'Today',
  'au.searchbox.placeholder': "Filter",
  'au.searchbox.moreMatching': "{number} More {category} matching",
  'au.trips.title': "Trips",
  'au.trips.vin': "VIN",
  'au.trips.vehicleName': "Name",
  'au.trips.fleetName': "Fleet",
  'au.trips.timezone': "Timezone",
  'au.trips.averageScore': "Average Score",
  'au.trips.lastNdays': "Last {number, plural, =0 {} one {day} other {# days}}",
  'au.trips.checkboxInstruction': "(Uncheck checkboxes to hide the trips)",
  'au.trips.tab.list': "List",
  'au.trips.tab.map': "Map",
  'au.trips.table.expansion.header.time': "Time",
  'au.trips.table.expansion.header.hardAcceleration': "Aggressive Acceleration",
  'au.trips.table.expansion.header.hardBraking': "Hard Braking",
  'au.trips.table.expansion.header.excessiveSpeeding': "Excessive Speeding",
  'au.trips.table.expansion.header.fuelConsumed': "Fuel Consumed",
  'au.trips.table.header.date': "Date",
  'au.trips.table.header.shift': "Shift",
  'au.trips.table.header.distance': "Distance",
  'au.trips.table.header.fuelConsumed': "Fuel Consumed",
  'au.trips.table.header.excessiveSpeeding': "Excessive Speeding",
  'au.trips.table.header.hardAccel': "Hard Acceleration",
  'au.trips.table.header.hardBraking': "Hard Braking",
  'au.trips.table.header.idling': "Idling Time",
  'au.trips.table.header.map': "Map",
  'au.trips.table.playButton': "Play Animation",
  'au.trips.table.stopButton': "Stop Animation",
  'au.trips.table.duration': "{hours, plural, =0 {} one {#hr} other {#hrs}} {minutes, plural, =0 {} one {#min} other {#mins}} {seconds, plural, =0 {} one {#sec} other {#secs}}",
  'au.trips.table.noDuration': "none",
  'au.trips.map.legend.drivingScore': "Driving Score",
  'au.trips.map.legend.excessiveSpeeding': "Excessive Speeding",
  'au.trips.map.legend.hardAcceleration': "Aggressive Acceleration",
  'au.trips.map.legend.hardBraking': "Hard Braking",
  'au.trips.tooltip.header.event': "Event:",
  'au.trips.tooltip.header.location': "Location:",
  'au.trips.tooltip.header.time': "Time:",
  'au.trips.tooltip.header.duration': "Duration:",
  'au.trips.tooltip.eventType.ACCELERATE': "Aggressive Acceleration",
  'au.trips.tooltip.eventType.DECELERATE': "Hard braking",
  'au.trips.tooltip.eventType.IDLE': "Idling",
  'au.trips.overview.latestTrip': "Latest Trip",
  'au.trips.overview.currentLocation': "Current Location",
  'au.trips.overview.noData': "No Trip Data",
  'au.trips.overview.viewPreviousTrips': "View Previous Trips",
  'au.reverseGeocode.isFetching': "Fetching...",
  'au.reverseGeocode.undetermined': "Undetermined",
  'au.admin.cancel': 'Cancel',
  'au.admin.confirmDelete': 'Yes, delete',
  'au.admin.saveChange': 'Save Changes',
  'au.validation.isRequired': '{field} is required',
  'au.validation.passwordMissmatch': "Passswords don't match",
  'au.validation.minLength': '{field} must be at least {length} characters long',
  'au.validation.maxLength': '{field} must be at most {length} characters long',
  'au.validation.validEmail': '{field} is not valid',
  'au.validation.validUuid': '{field} is not a valid UUID',
  'au.validation.notFound': '{field} doesn\'t exist',
  };

const locales = {
  en: defaultEnglish,
  'en-GB': Object.assign({}, defaultEnglish, {
    'au.analytics.distChart.title': "Fleet Distance Travelled",
    'au.asset.analytics.distChart.title': "Distance Travelled",
  })
};

export default merge(coreLocales, locales);
