import { connect } from 'react-redux';
import { observableSelector } from '../utils/selectors';
import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';

import PropProvider from '../PropProvider';
import DashboardMap from '../components/DashboardMap';

const dashboardMapSelector = observableSelector(state => PropProvider(state).DashboardMap());

export default connect(
  dashboardMapSelector,
  defaultMapDispatchToProps
)(DashboardMap);
