import React, { Component } from 'react';

// TODO: move this to au-core!!!
function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

/**
 * For components directly associated with react-router Routes this will inject
 * match.params as top level props.  This is useful so that components can
 * receive the same prop names via the prop provider, Routes or normal
 * React.createElement() without having duplicate code searching for the same
 * prop with different paths.  Ex. /:vehicleId/ path will pass vehicleId as a
 * prop instead of match.params.vehicldId.
 */
export default function injectParams(WrappedComponent) {
  class InjectParams extends Component {
    static displayName = `InjectParams(${getDisplayName(WrappedComponent)})`;

    static WrappedComponent = WrappedComponent;

    render() {
      const { match, children } = this.props;
      const params = match ? match.params : {};
      return (
        <WrappedComponent {...params} {...this.props}>
          {children}
        </WrappedComponent>
      );
    }
  }

  return InjectParams;
}
