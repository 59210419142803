// import { Marker } from 'react-google-maps';
// import React from 'react';
import * as T from 'prop-types';

// import AuInfoBox from './AuInfoBox';
// import { AssetProps } from '../../AuPropTypes';
import AuComponent from '@au/core/lib/components/elements/AuComponent';

// import vehicleImg from '../../images/vehicle.svg';

export default class AuVehicleMarker extends AuComponent {
  static propTypes = {
    asset: T.object,
    hoveredAsset: T.object,
    // asset: T.shape(AssetProps),
    // hoveredAsset: T.shape(AssetProps),
    isClickable: T.bool,
    isHoverable: T.bool
  };

  render() {
    // let { asset, hoveredAsset, isClickable, isHoverable, clickHandler, overHandler, outHandler, actions } = this.props;
    // Bail if asset doesn't have location info (maybe this can be removed...)
    // if (!(asset.location && asset.location.lat && asset.location.lon)) {
    //   return false;
    // }



    // const clickHndlr = isClickable ? clickHandler ? () => clickHandler(asset.vehicle_id) : () => {
    //   // center map on selected vehicle
    //   actions.mapMarkerClick({lat:asset.location.lat, lng:asset.location.lon});
    //   // open info window
    //   actions.mapMarkerHover(asset.vehicle_id);
    // } : () => {};
    // const mOverHndlr = isHoverable ? overHandler ? () => overHandler(asset.vehicle_id) : () => actions.mapMarkerHover(asset.vehicle_id) : () => {};
    // const mOutHndlr  = isHoverable ? outHandler ? () => outHandler(asset.vehicle_id) : () => actions.mapMarkerEndHover(2000) : () => {};

    // let latLng = new google.maps.LatLng(asset.location.lat, asset.location.lon);

    // let displayTooltip = false;
    // if (isHoverable && hoveredAsset && hoveredAsset.vehicle_id === asset.vehicle_id) {
    //   const tooltipOptions = {
    //     actions: actions,
    //     vehicle_id: hoveredAsset.vehicle_id,
    //     vin: hoveredAsset.vin,
    //     speed: hoveredAsset.speed,
    //     fuel_level: hoveredAsset.fuel_level,
    //     odometer: hoveredAsset.odometer,
    //     timestamp: hoveredAsset.timestamp
    //   };
    //   displayTooltip = <AuInfoBox {...tooltipOptions} />;
    // }

    // return (
    //   <Marker
    //     position={latLng}
    //     onClick={clickHndlr}
    //     onMouseOver={mOverHndlr}
    //     onMouseOut={mOutHndlr}
    //     icon={{
    //       url: vehicleImg,
    //       size: new google.maps.Size(23, 27),
    //       origin: new google.maps.Point(0, 0),
    //       anchor: new google.maps.Point(11.5, 27),
    //       scaledSize: new google.maps.Size(23, 27)
    //     }}
    //   >
    //     {displayTooltip}
    //   </Marker>
    // );

    // return new google.maps.Marker({
    //   position: latLng
    // });

    return false;
  }
}