import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { FormattedDate, FormattedMessage } from 'react-intl';
import _ from 'lodash';

import VehicleLink from '../containers/VehicleLink';
import AuFormatRelative from '../components/AuFormatRelative';
import AuCurrentStatusIcons from '../components/AuCurrentStatusIcons';

require("moment-duration-format");

export const auTableDateOptions = {
  //FIXME - LOCALIZATION ISSUE
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  momentFormat: 'M/D/Y h:mmA'
};

export const formatterFactory = {
  date({ value, props={} }) {
    if (!value) {
      return false;
    }
    const dateOptions = Object.assign({}, auTableDateOptions, props);

    if (dateOptions.timezone) {
      // This is needed because FF and IE do not appear to support IANA timezone formats
      return moment.tz(value, dateOptions.timezone).format(dateOptions.momentFormat);
    }

    return <FormattedDate {...dateOptions} value={value} />;
  },

  relativeDate({ value, props={} }) {
    const dateOptions = Object.assign({}, auTableDateOptions, props);

    return <AuFormatRelative dateOptions={dateOptions} value={value} />;
  },

  currentStatusIcons({ value }) {
    const indicators = value ? value.toJS() : {};

    return <AuCurrentStatusIcons indicatorLights={indicators} />;
  },

  duration({ value }) {
    if (!value) {
      return false;
    }

    return moment.duration(value, 'ms').format("d[d] h[h] m[m]");
  },

  formattedBool({ value, props={} }) {
    return <FormattedMessage id={value ? props['true'] : props['false']} />;
  },

  formattedEnum({ value, props={} }) {
    return <FormattedMessage id={props[value.toLowerCase()]} />;
  },

  checkmarkBool({ value }) {
    return value ? <i className="icon-check-mark" aria-hidden="true"/> : '';
  },

  className({ value }) {
    return <div className={value} />;
  },

  entityLink({ value, rowData, linkProps, type, idProperty }) {
    const id = rowData.getIn(['vehicle_id']);

    if (id) {
      // TODO we should consider splitting this into separate top level formatters.
      if (type === 'vehicle') {
        if (linkProps) {
          return <VehicleLink vehicleId={id} {...linkProps}>{value}</VehicleLink>;
        }
        return <VehicleLink vehicleId={id}>{value}</VehicleLink>;
      }
      else if (type === 'geofences') {
        return <Link to={`/geofences/detail/${id}`}>{value}</Link>;
      }
    }
    else {
      /* eslint-disable no-console */
      console.warn(`Could not read "${idProperty}" from rowData object: `, rowData);
      /* eslint-enable no-console */
    }

    return value;
  },

  formattedAuthor({ value }) {
    return _.map(value, 'name').join(", ");
  },

  tags({ value }) {
    return Array.isArray(value) ? value.join(", ") : value;
  },

};
