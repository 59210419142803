import { fromJS } from 'immutable';

export default {
  MINI_MAP_ZOOM: (state, action) => {
    /*
     * Sets the MiniMap zoom level. Not currently used. Retained for possible
     * future usage and UX.
     */
    return state.updateIn(['map', 'mmapZoom'], () => fromJS(action.zoom));
  },
  MAP_VISIBLE_BOUNDS_CHANGE: (state, action) => {
    return state.updateIn(['map', 'visibleBounds'], () => fromJS(action.bounds));
  },
  MAP_BOUNDS_CHANGE: (state, action) => {
    /*
     * Sets the bounds of the DashboardMap.
     */
    return state.updateIn(['map', 'bounds'], () => fromJS(action.bounds));
  },
  MAP_CHANGE: (state, action) => {
    return state
      .setIn(['map', 'center'], fromJS(action.center))
      .setIn(['map', 'zoom'], fromJS(action.zoom));
  },
  GEOFENCES_MAP_CHANGE: (state, action) => {
    return state
      .setIn(['geofencesMap', 'center'], fromJS(action.center))
      .setIn(['geofencesMap', 'zoom'], fromJS(action.zoom));
  },
  MAP_CENTER_CHANGE: (state, action) => {
    return state.updateIn(['map', 'center'], () => fromJS(action.center));
  },
  MAP_MARKER_CLICK: (state, action) => {
    var newState = state.updateIn(['map', 'center'], () => fromJS(action.center));
    if (newState.getIn(['map', 'zoom']) < fromJS(16)){
      newState = newState.updateIn(['map', 'zoom'], () => 16);
    }
    return newState;
  },
  MAP_MARKER_HOVER: (state, action) => {
    let newState = state.updateIn(['map', 'hoveredAsset'], () => fromJS(action.vehicle_id));
    return newState.updateIn(['map', 'hoveredInTime'], () => fromJS(action.hoveredInTime));
  },
  MAP_MARKER_END_HOVER: (state, action) => {
    // If we have since entered a hovered state after we first called this state then do not update.
    // We want to keep hovering.
    if (state.getIn(['map', 'hoveredInTime']) > action.hoveredOutTime) {
      return state;
    } else {
      return state.updateIn(['map', 'hoveredAsset'], () => null);
    }
  },
  MAP_TOGGLE_VIEW_CLICK: (state) => {
    return state.updateIn(['map', 'fullScreen'], () => !state.getIn(['map', 'fullScreen']));
  },
};
