import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import AuButton, { BUTTON_TYPE_TERTIARY, BUTTON_SIZE_SMALL } from '@au/core/lib/components/elements/AuButton';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { NOOP } from '../constants';

import styles from '../css/components/group_selector.module.scss';

export default class GroupSelector extends React.PureComponent {

  static propTypes = {
    className: T.string,
    groupId: T.string,
    sourceId: T.string,
    groupName: T.string,
    onClick: T.func
  }

  static defaultProps = {
    onClick: NOOP
  }

  render() {
    const { className, groupName, groupId, sourceId, onClick } = this.props;

    return (
      <div className={cn(styles.container, className)}>
        <div className={styles.list}>
          <div className={styles.row}>
            <AutoIntl displayId="au.inputSource.groupName" className={styles.label} />
            <span className={styles.value}>{groupName || groupId || ''}</span>
          </div>
          <div className={styles.row}>
            <span className={styles.label}>
              Source
            </span>
            <span className={styles.value}>{ sourceId }</span>
          </div>
        </div>
        <AuButton
          type={BUTTON_TYPE_TERTIARY}
          size={BUTTON_SIZE_SMALL}
          displayId="au.inputSource.change"
          className={styles.button}
          onClick={onClick}
        />
      </div>
    );
  }
}
