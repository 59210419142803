import React, { Component } from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import { AssetProps } from '../AuPropTypes';
import AssetMap from './AssetMap';
import CurrentLocationStats from './CurrentLocationStats';
import ScenarioRunner from '../containers/ScenarioRunner';

import styles from '../css/components/asset_current_location.module.scss';

// This component contains components from multiple stores.  We need to have this
// "hub/hyper connected" component that cannot have a pure component counterpart
// connect the components with different stores.

export default class AssetCurrentLocation extends Component {

  static propTypes = {
    vehicleId: T.string,
    vehicleLoaded: T.bool,
    geofences: IPT.map,
    asset: T.shape(AssetProps),
  }

  state = { waypoints: [] }

  setWaypoints = this.setWaypoints.bind(this);
  setWaypoints(waypoints) {
    this.setState({ waypoints });
  }

  render() {

    let { vehicleLoaded, vehicleId, asset, geofences } = this.props;
    const { speed, fuel_level, odometer, engine_speed, location, vin } = asset;
    const lat = location.lat;
    const lon = location.lon;
    const { waypoints } = this.state;

    if (!vehicleLoaded) {
      // There are cases where the vehicle_id might not be set when the user
      // refreshes the browser directly from the vehicle details url.
      return false;
    }

    return (
      <div className={styles.container}>
        <div className={styles.bottom}>
          <div className={styles.map}>
            <AssetMap
              asset={asset}
              vehicleId={asset.vehicle_id}
              location={asset.location}
              geofences={geofences}
              waypoints={waypoints}
            />
            <CurrentLocationStats asset={{ fuel_level, speed, odometer, engine_speed, lat, lon }} />
            <ScenarioRunner
              onSelect={this.setWaypoints}
              className={styles.scenario_runner}
              vin={vin}
              vehicleId={vehicleId}
            />
          </div>
        </div>
      </div>
    );
  }

}
