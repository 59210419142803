import { connect } from 'react-redux';

import ScenarioRunner from '../components/ScenarioRunner';
import PropProvider from '../PropProvider.js';
import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';

export default connect(
  state => PropProvider(state).ScenarioRunner(),
  defaultMapDispatchToProps
)(ScenarioRunner);
