import Immutable from 'immutable';

const fieldPathSerializer = fp => fp.join('.');
export const serializeFilter = f => `${fieldPathSerializer(f.fieldPath)}-${f.condition}-${f.entityType}`;

export const bogusFieldPath = ['bogus']; // fake field use to store and look up invalid filters

export class FilterManager {
  // This allows us to keep the filter order consistent but we can check for
  // uniqueness and do O(1) lookups and removal
  constructor(filters) {
    this._filters = filters;
    this._buildLookup();
  }

  _buildLookup() {
    this._filterLookup = {};
    for (let i=0; i<this._filters.length; ++i) {
      const f = this._filters[i];
      this._filterLookup[serializeFilter(f)] = i;
    }
  }

  has(filter) {
    return serializeFilter(filter) in this._filterLookup;
  }

  remove(filter) {
    const serialized = serializeFilter(filter);
    const idx = this._filterLookup[serialized];
    if (Number.isInteger(idx)) {
      this._filters.splice(idx, 1);
      delete this._filterLookup[serialized];
      this._buildLookup();
    }
  }

  groupOred() {
    // Returns array of ORed filters arrays
    const byField = {};
    for (let filter of this._filters) {
      const serializedFieldPath = fieldPathSerializer(filter.fieldPath);
      if (!byField[serializedFieldPath]) {
        byField[serializedFieldPath] = [];
      }
      byField[serializedFieldPath].push(filter);
    }
    const oredSets = [];
    for (let field of Object.keys(byField)) {
      oredSets.push(byField[field]);
    }
    return oredSets;
  }
}

export function addGlobalFiltersByAssetGuids(assetGuids, mqbInstance) {
  // Since we have the GUIDs that we want already we can simply filter based
  // on those GUIDs alone
  const orThese = assetGuids.map(x => ['vehicle_id', x]);
  if (orThese.length) {
    mqbInstance.orFilters(...orThese);
  }
}

function filterEntityPredicate(state, entityType) {
  const filteredGuids = state.getIn(['filteredEntities', entityType]);
  return (v, k) => filteredGuids.has(k);
}

export function filterEntity(state, entityType) {
  // Returns Immutable object with the filtered entities
  const entities = state.getIn(['entities', entityType]);
  if (state.getIn(['globalFilters', 'applied'], Immutable.Map()).size) {
    // Only apply filter if we have global filters applied
    return entities.filter(filterEntityPredicate(state, entityType));
  }
  return entities;
}

export function filterSuggestions(globalFilters) {
  // Build quick lookup of what we have already applied
  const filterLookup = {};
  for (let filter of globalFilters.get('applied').valueSeq()) {
    const set = (filterLookup[filter.get('fieldPath')] || Immutable.Set())
      .add(filter.get('condition'));
    filterLookup[filter.get('fieldPath')] = set;
  }


  //Remove from the items in each matching category
  const mutableGlobalFilters = globalFilters.asMutable();
  for (let [displayId, filterFieldDetails] of globalFilters.get('filterFields').entrySeq()) {
    const suggestionData = filterFieldDetails.get('suggestions');

    const alreadyApplied = filterLookup[filterFieldDetails.get('target')] || Immutable.Set();
    const mutableItems = suggestionData.get('items').asMutable();
    for (let [suggestion, suggestionMeta] of mutableItems.entrySeq()) {
      if (suggestionMeta.has('mapsTo')) {
        if (alreadyApplied.has(suggestionMeta.get('mapsTo'))) {
          mutableItems.delete(suggestion);
        }
      } else if (alreadyApplied.has(suggestion)) {
        mutableItems.delete(suggestion);
      }
    }

    mutableGlobalFilters.setIn(['filterFields', displayId, 'suggestions', 'items'], mutableItems);
  }

  return mutableGlobalFilters.asImmutable();
}
