import TMC from '@autonomic/browser-sdk';
import { history as browserHistory } from './history';

import { VEHICLE_ID_PROP_NAME } from './constants';
import { getTabs as getAssetTabs } from './utils/asset';
import { getQueryTzOffset, adjustOffset } from './utils/dateFunctions';
import { guessCurrentTimezone } from './utils/timezones';
import shared from './shared';
import { default as mqb } from './utils/metricsQueryBuilder';
import AutoBuffer from './utils/autoBuffer';

// TODO: cleanup references and uses
let metricsQuery, vehicleStateStreaming;

export function screenWidthChanged(width) {
  return {
    type: "SCREEN_WIDTH_CHANGED",
    width: width
  };
}

export function dashboardVehicleFocusUndesired() {
  let location = browserHistory.location;
  let newLoc = {...location, query: {}};
  delete newLoc.query.vehicleId;
  browserHistory.push(newLoc);
  return {
    type: "NOOP"
  };
}

export function setPageTitleId(id) {
  return {
    type: 'SET_PAGE_TITLE_ID',
    id
  };
}

export function setPageTitle(title) {
  return {
    type: 'SET_PAGE_TITLE',
    title
  };
}

export function setBreadcrumbsSchema(schema) {
  return {
    type: 'SET_BREADCRUMBS_SCHEMA',
    schema
  };
}

export function tableFilterChange(e, tableId) {
  return {
    type: 'TABLE_FILTER',
    tableId: tableId,
    filterBy: (!e.target.value) ? null : e.target.value.toLowerCase()
  };
}

export function sortTable(tableId, columnId, direction) {
  return {
    type: 'TABLE_SORT_PREFERENCE',
    tableId: tableId,
    columnId: columnId,
    direction: direction
  };
}

export function toggleTableSort(tableId, columnId) {
  return {
    type: 'TOGGLE_TABLE_SORT_PREFERENCE',
    tableId,
    columnId
  };
}

export function setSystemOfUnits(systemOfUnits) {
  return {
    type: 'SET_SYSTEM_OF_UNITS',
    systemOfUnits
  };
}

export function setLocale(locale) {
  return {
    type: 'SET_LOCALE',
    locale
  };
}

export function fetchTopicRequested(topic) {
  return { type: 'REQUESTED_TOPIC', topic };
}

export function fetchTopicReceived(topic, version) {
  return { type: 'RECEIVED_TOPIC', topic, version };
}

export function fetchTopicFailed(topic, version) {
  return { type: 'FAILED_TOPIC', topic, version };
}

function streamTopicReceived(messages, fields) {
  return {
    type: 'RECEIVED_PARTIAL_VEHICLE_CHANGES',
    messages,
    requestedFields: fields
  };
}

function fetchTopic(topicName, fetchFn, callbackFn) {
  return (dispatch, getState) => {
    if (getState().getIn(['fetchTopics', topicName, 'isFetching'])) {
      // Previous request is still in progress
      return Promise.resolve();
    }

    dispatch(fetchTopicRequested(topicName));
    // Current topic version
    let version = getState().getIn(['fetchTopics', topicName, 'version']);

    return fetchFn().then(response => {
      // Do not apply the data if version is different
      if (version === getState().getIn(['fetchTopics', topicName, 'version'])) {
        dispatch(fetchTopicReceived(topicName, version));
        callbackFn(response, dispatch, getState);
      }
    }).catch(error => {
      // Ignore if we are on a different version
      if (version === getState().getIn(['fetchTopics', topicName, 'version'])) {
        dispatch(fetchTopicFailed(topicName, version));
      }
      throw error;
    });
  };
}

export function fetchAssets() {
  const type = 'RECEIVED_RESOURCES_VEHICLES';
  const topicName = 'assets';
  const resources = new TMC.services.Resources({ apiVersion: 2 });

  return fetchTopic(
    topicName,
    () => resources.vehicles.list({pageSize: 150}),
    (response, dispatch) => {
      dispatch({ type, topicName, items: response.items });
      processGlobalFilterGuids(dispatch); // TEMP FIXME TODO -> work around to ensure local filters are always applied
    }
  );
}


let vehicleChangesStream;
export function streamVehicleChanges() {
  if (vehicleChangesStream) return { type: 'NOOP' };

  const fields = ['fuel_level', 'speed', 'engine_speed', 'location', 'odometer', 'ignition_status', 'indicators'];
  const query = {
    request_id: 'vehicleChanges',
    fields,
    per_asset_limit_interval_millis: 2000,
    scopes: []
  };
  return dispatch => {
    const buffer = new AutoBuffer((messages) => dispatch(streamTopicReceived(messages, fields)));
    const ws = vehicleStateStreaming.assetState.streamQuery(query, message => buffer.push(message));

    vehicleChangesStream = {
      buffer,
      ws
    };
  };
}

export function stopStreamVehicleChanges() {
  if (vehicleChangesStream) {
    vehicleChangesStream.ws.close();
    vehicleChangesStream.buffer.stop();
    vehicleChangesStream.buffer.emptyBuffer();
    vehicleChangesStream = undefined;
  }
}

export function miniMapZoom(zoom){
  return {
    type: "MINI_MAP_ZOOM",
    zoom: zoom
  };
}

export function minimapMobileClusterClicked() {
  let location = browserHistory.location;
  let newLoc = {...location, state: { pageTitleId: 'au.section.title.map', forced: true, mapFocused: true } };
  browserHistory.push(newLoc);
  return {
    type: "NOOP"
  };
}

/**
 * Used to apply new bounds for dashboard map.
 */
export function mapBoundsChange(bounds) {
  return {
    type: "MAP_BOUNDS_CHANGE",
    bounds
  };
}

/**
 * Used to trigger a change in the minimap viewed area bounding box
 * representation.
 */
export function mapVisibleBoundsChange(bounds) {
  return {
    type: "MAP_VISIBLE_BOUNDS_CHANGE",
    bounds
  };
}

export function mapChange(center, zoom) {
  return {
    type: "MAP_CHANGE",
    center: center,
    zoom: zoom
  };
}

export function setGeofencesSortOrder(order) {
  return {
    type: "SET_GEOFENCES_SORT_ORDER",
    order
  };
}

export function geofencesMapChange(center, zoom) {
  return {
    type: "GEOFENCES_MAP_CHANGE",
    center: center,
    zoom: zoom
  };
}

export function mapCenterChange(center) {
  return {
    type: "MAP_CENTER_CHANGE",
    center: center,
  };
}

export function setGroupId(groupId) {
  return {
    type: "SET_GROUP_ID",
    groupId
  };
}

export function setSourceId(sourceId) {
  return {
    type: "SET_SOURCE_ID",
    sourceId
  };
}

export function mapMarkerClick(latLng){
  return {
    type: "MAP_MARKER_CLICK",
    center: latLng
  };
}

export function mapMarkerHover(vehicle_id) {
  return {
    type: "MAP_MARKER_HOVER",
    hoveredInTime: Date.now(),
    vehicle_id
  };
}

export function mapMarkerEndHover(msDelay) {
  const hoveredOutTime = Date.now();
  return function(dispatch) {
    setTimeout(function() {
      dispatch({
        type: "MAP_MARKER_END_HOVER",
        hoveredOutTime
      });
    }, msDelay);
  };
}

export function mapToggleViewClick() {
  return {
    type: "MAP_TOGGLE_VIEW_CLICK"
  };
}

export function modifyAnalyticsTimeBack(timeBack) {
  return {
    type: "MODIFY_ANALYTICS_TIME_BACK",
    timeBack
  };
}

function wrapActionsAsActivity(activityName, actionFns) {
  // The action functions are expected to return promise objects
  if (!Array.isArray(actionFns)) actionFns = [actionFns];
  const firstAction = actionFns.shift();
  let promiseChain;
  return (dispatch, getState) => {
    dispatch({ name: activityName, type: 'ACTIVITY_START' });
    const doneActivityFn = () => dispatch({ name: activityName, type: 'ACTIVITY_END' });
    promiseChain = firstAction(dispatch, getState);
    for (let action of actionFns) {
      promiseChain.then(() => action(dispatch, getState));
    }
    promiseChain.then(doneActivityFn, doneActivityFn);
  };
}

function processGlobalFilterGuids(dispatch) {
  return dispatch({
    type: 'PROCESS_GLOBAL_FILTER_GUIDS'
  });
}

export function clearAssets() {
  return (dispatch) => {
    dispatch({ type: 'CLEAR_ASSETS'});
  };
}

export function processSuggestions() {
  return { type: 'PROCESS_FIELD_SUGGESTIONS' };
}

export function fetchFuelConsumedDashboardAnalytics(options={ userRequested: false }) {
  const modQuery = db => db
    .reduceBy('sum', 'fuel_consumed');

  const fetchFn = fetchAnalytics('RECEIVED_FUEL_CONSUMED_DASHBOARD_ANALYTICS', modQuery, { ...options, includeToday: true, timeBack: 7 });
  if (options.userRequested) {
    return wrapActionsAsActivity('fuel_consumed_dashboard_analytics', fetchFn);
  } else {
    return fetchFn;
  }
}

export function fetchDistTraveledDashboardAnalytics(options={ userRequested: false }) {
  const modQuery = db => db
    .filter('odometer', '>0')
    .reduceBy(['max', 'odometer'], ['min', 'odometer']);

  const fetchFn = fetchAnalytics('RECEIVED_DIST_TRAVELED_DASHBOARD_ANALYTICS', modQuery, { ...options, includeToday: true, timeBack: 7 });
  if (options.userRequested) {
    return wrapActionsAsActivity('dist_traveled_dashboard_analytics', fetchFn);
  } else {
    return fetchFn;
  }
}

function fetchAnalytics(type, modBuilderFn, params) {
  // This is the shared function for both the dashboard analytics (mini charts)
  // and main analytics charts, including vehicle specific ones.  The main
  // difference is the dashboard analytics generally includes the current day.
  const { vehicle_id='', interval='1d', includeToday=false, timeBack } = params;

  const topicName = `${type}_${interval}${vehicle_id}`.toLowerCase();

  return (dispatch, getState) => {
    // TODO: better align design patterns between both groups of charts (store params in each one's Redux map)
    const daysBack = timeBack || parseInt(getState().getIn(['analytics', 'timeBack']));
    const now = new Date();
    let end;
    if (includeToday) {
      // Include today is usually used by dashboard analytics
      end = now;
    } else {
      end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59);
    }

    // JS will cast the start date to the TZ at _that_ time.  To be consistent
    // we need to adjust the start to use the same TZ's definition of the start
    // of the day. This keeps the start and end time in the same timezone.
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - daysBack);
    const startAdjusted = adjustOffset(end.getTimezoneOffset(), start);

    const queryBuilder = mqb.start.useMyOffset().timeRange(startAdjusted, end);

    if (vehicle_id) {
      queryBuilder.filter('vehicle_id', vehicle_id);
    } else {
      // This must be a general analytics query
      return new Promise(() => dispatch({
        type,
        topicName,
        response: {
          results: {
            [`interval:${interval}`]: {}
          }
        },
        vehicle_id,
        interval,
        start,
        end
      }));
    }

    // Query specific changes to the queryBuilder
    modBuilderFn(queryBuilder)
      .groupBy('vehicle_id')
      .groupByInterval(interval);

    const imGlobalFilters = getState().getIn(['globalFilters', 'applied']);

    return fetchTopic(
      topicName,
      () => metricsQuery.query.query(queryBuilder.query),
      (response, dispatch, getState) => {
        const filtersAreSame = getState().getIn(['globalFilters', 'applied']).equals(imGlobalFilters);
        dispatch({
          type: filtersAreSame ? type : 'NOOP',
          response: response.data,
          vehicle_id,
          interval,
          start,
          end
        });
      }
    )(dispatch, getState);
  };
}

export function fetchFuelConsumedAnalytics(options={ userRequested: false }) {
  const modQuery = qb => qb
    .reduceBy('sum', 'fuel_consumed');

  const fetchFn = fetchAnalytics('RECEIVED_FUEL_CONSUMED_ANALYTICS', modQuery, options);
  if (options.userRequested) {
    return wrapActionsAsActivity('fuel_consumed_analytics', fetchFn);
  } else {
    return fetchFn;
  }
}

export function fetchDistTraveledAnalytics(options={ userRequested: false }) {
  const modQuery = qb => qb
    .filter('odometer', '>0')
    .reduceBy(['max', 'odometer'], ['min', 'odometer']);

  const fetchFn = fetchAnalytics('RECEIVED_DIST_TRAVELED_ANALYTICS', modQuery, options);
  if (options.userRequested) {
    return wrapActionsAsActivity('dist_traveled_analytics', fetchFn);
  } else {
    return fetchFn;
  }
}

export function fetchFuelEconomyAnalytics(options={ userRequested: false }) {
  const modQuery = db => db
    .filter('odometer', '>0')
    .filter('fuel_consumed', '>0')
    .reduceBy(['max', 'odometer'], ['min', 'odometer'], ['sum', 'fuel_consumed']);

  const fetchFn = fetchAnalytics('RECEIVED_FUEL_ECONOMY_ANALYTICS', modQuery, options);
  if (options.userRequested) {
    return wrapActionsAsActivity('fuel_economy_analytics', fetchFn);
  } else {
    return fetchFn;
  }
}

function buildGeoFenceQuery(geoFenceDef, vehicle_id, start, end) {
  const query = {
    scopes: [
      {
        start_time: start.toISOString(),
        end_time: end.toISOString()
      }
    ],
    aggregations: [
      {
        group_by: `interval:15m:${getQueryTzOffset()}`,
      }
    ]
  };

  const { bounding_polygon, bounding_box, bounding_circle } = geoFenceDef;

  if (bounding_polygon) {
    query.scopes.push({
      bounding_polygon: bounding_polygon.map(b=>[b.lat, b.lng])
    });
  }
  else if (bounding_box){
    // TODO: fill out once we support bounding_box instead of casting it to a polygon
  }
  else if (bounding_circle) {
    query.scopes.push({
      distance: [bounding_circle.lat, bounding_circle.lng, bounding_circle.radius]
    });
  }
  else {
    throw('Need at least one of "bounding_polygon", "bounding_box" or "bounding_circle" to create query');
  }

  if (vehicle_id) {
    query.scopes.push({ filter: `vehicle_id:${vehicle_id}` });
    query.aggregations[0].aggregations = [
      { reduce_by: 'max:timestamp' },
      { reduce_by: 'min:timestamp' }
    ];
  }
  else {
    query.aggregations[0].aggregations = [{
      group_by: 'vehicle_id',
      aggregations: [
        { reduce_by: 'max:timestamp' },
        { reduce_by: 'min:timestamp' }
      ]}];
  }
  return query;
}

export function fetchGeoFenceTimeseriesData(geoFenceDef, vehicleId='', daysBack=7) {
  // (for now) ignore today since it is very likely a partial day and incomparable
  const now = new Date();
  const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - daysBack);
  const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours());
  const topicName = 'geo_fence_data-' + geoFenceDef.id + (vehicleId ? '-' + vehicleId : '');
  const innerQuery = buildGeoFenceQuery(geoFenceDef, vehicleId, start, end);

  const outerDef = { bounding_circle: { ...geoFenceDef.center, radius: 10000 } };
  const outerQuery = buildGeoFenceQuery(outerDef, vehicleId, start, end);

  return fetchTopic(
    topicName + '-inner',
    () => metricsQuery.query.query(innerQuery),
    (innerResponse, dispatch, getState) => {
      //if no inner data, then don't bother with the next query.
      const innerResponseData = innerResponse.data;
      if (innerResponseData.results
        && innerResponseData.results['interval:15m']
        && Object.keys(innerResponseData.results['interval:15m']).length){
          return fetchTopic(
            topicName + '-outer',
            () => metricsQuery.query.query(outerQuery),
            (outerResponse, dispatch) => dispatch({
              type: 'RECEIVED_GEO_FENCE_DATA',
              innerBoundsResponse: innerResponse.data,
              outerBoundsResponse: outerResponse.data,
              vehicleId,
              geoFenceId: geoFenceDef.id
            })
          )(dispatch, getState);
      }

      //no inner data, skip checking for the outer data
      const outerResponseData = { results: {} };
      outerResponseData.results['interval:15m'] = {};
      return dispatch({
        type: 'RECEIVED_GEO_FENCE_DATA',
        innerBoundsResponse: innerResponseData,
        outerBoundsResponse: outerResponseData,
        vehicleId,
        geoFenceId: geoFenceDef.id
      });
    }
  );
}

export function openPopout(popoutProps) {
  return {
    type: 'OPEN_POPOUT',
    popoutProps
  };
}

export function closePopout() {
  return {
    type: 'CLOSE_POPOUT'
  };
}

export function showGroupsDialog() {
  return {
    type: 'SHOW_GROUPS_DIALOG'
  };
}

export function hideGroupsDialog() {
  return {
    type: 'HIDE_GROUPS_DIALOG'
  };
}

function showContent(dispatch, getState, path, popoutProps) {
  if (['desktop', 'tabletLandscape'].includes(getState().get('screenWidth'))) {
    dispatch(openPopout(popoutProps));
  }
  else {
    if (path) {
      browserHistory.push(path);
    }
    dispatch({ type: "NOOP" });
  }
}

export function openVehicleDetails(vehicleId, selectedTab='AssetMetrics') {
  const url = getAssetTabs(vehicleId).get(selectedTab).linkDestination;
  return (dispatch, getState) => showContent(dispatch, getState, url, {
    props: {
      className: 'app__vehicle-popout'
    },
    componentName: 'ConnectedAsset',
    componentProps: {
      className: 'app__vehicle-popout__vehicle-summary',
      selectedTab,
      [VEHICLE_ID_PROP_NAME]: vehicleId,
    }
  });
}

export function loadGroupsSuccess(groups) {
  return { type: 'RECEIVED_GROUPS', groups };
}

export function loadGroups(...args) {
  const { groups } = new TMC.services.Inventory({ apiVersion: '1-beta' });
  return (dispatch) => {
    return groups.list(...args).then(resp => resp.getItemsMap(Infinity)).then(groups => {
      dispatch(loadGroupsSuccess(groups));
    }).catch(error => {
      // TODO implement proper error handling
      /* eslint-disable no-console */
      console.warn('Loading groups failed', error);
      /* eslint-enable no-console */
      throw error;
    });
  };
}

export function loadAccounts(...args) {
  const accounts = new TMC.services.Accounts({ apiVersion: 1 });
  return (dispatch) => {
    return accounts.accounts.list(...args).then(result => {
      dispatch({ type: 'RECEIVED_ACCOUNTS', data: result.items, pkField: accounts.accounts.idProp });
      return result;
    }).catch(error => {
      // TODO implement proper error handling
      /* eslint-disable no-console */
      console.warn('Loading accounts failed', error);
      /* eslint-enable no-console */
      throw error;
    });
  };
}

let isFetchingTelemetry = false;
export function loadVehicleData(vehicleId) {
  if (isFetchingTelemetry) {
    return { type: 'NOOP' };
  }
  return (dispatch, getState) => {
    isFetchingTelemetry = true;
    const partition = getState().getIn(['settings', 'partition']);
    const { sourceId } = getState().getIn(['settings', 'partitions', partition]).toJS() ?? {};
    return shared.transporter.get(`/v1/telemetry/sources/${sourceId}/vehicles/${vehicleId}`).then(result => {
      dispatch(loadVehicleDataSuccess(vehicleId, result.data));
    }).catch(error => {
      // TODO implement proper error handling
      /* eslint-disable no-console */
      console.warn('Loading group vehicles failed', error);
      /* eslint-enable no-console */
      throw error;
    }).finally(() => isFetchingTelemetry = false);
  };
}

export function loadVehicleDataSuccess(vehicleId, telemetry) {
  return { type: 'RECEIVED_VEHICLE_DATA_SUCCESS', vehicleId, telemetry };
}

export function loadGroupVehicles() {
  if (isFetchingTelemetry) {
    return { type: 'NOOP' };
  }
  return (dispatch, getState) => {
    isFetchingTelemetry = true;
    const partition = getState().getIn(['settings', 'partition']);
    const { sourceId, groupId } = getState().getIn(['settings', 'partitions', partition]).toJS() ?? {};
    return shared.transporter.get(`/v1/telemetry/sources/${sourceId}/groups/${groupId}`).then(result => {
      dispatch(loadGroupVehiclesSuccess(groupId, result.data));
    }).catch(error => {
      // TODO implement proper error handling
      /* eslint-disable no-console */
      console.warn('Loading group vehicles failed', error);
      /* eslint-enable no-console */
      throw error;
    }).finally(() => isFetchingTelemetry = false);
  };
}

export function loadGroupVehiclesSuccess(groupId, telemetry) {
  return { type: 'RECEIVED_FANCY_GROUP_VEHICLES', groupId, telemetry };
}

function loadFleetsSuccess(fleets) {
  return { type: 'LOAD_FLEETS_SUCCESS', fleets };
}

export function loadFleets(...args) {
  const resources = new TMC.services.Resources({ apiVersion: 2 });
  return (dispatch) => {
    return resources.fleets.list(...args).then(response => {
      if (!Array.isArray(response.items)) {
        dispatch(loadFleetsSuccess(null));
        return;
      }
      dispatch(loadFleetsSuccess(response.items));
    }).catch(error => {
      // TODO implement proper error handling
      /* eslint-disable no-console */
      console.warn('Loading fleets failed', error);
      /* eslint-enable no-console */
      throw error;
    });
  };
}

function loadVehicleSuccess(vehicle) {
  return { type: 'RECEIVED_RESOURCES_VEHICLES',  items: [vehicle] };
}

export function loadVehicle(...args) {
  const resources = new TMC.services.Resources({ apiVersion: 2 });
  return (dispatch) => {
    return resources.vehicles.get(...args).then(result => {
      dispatch(loadVehicleSuccess(result));
    }).catch(error => {
      // TODO implement proper error handling
      /* eslint-disable no-console */
      console.warn('Loading vehicle failed', error);
      /* eslint-enable no-console */
      throw error;
    });
  };
}

export function loadGeofencesSuccess(geofences) {
  return { type: 'LOAD_GEOFENCES_SUCCESS', geofences };
}

function fetchGeofenceTzSuccess(geofenceId, tz) {
  return { type: 'SET_GEOFENCE_TZ', geofenceId,  timezone: tz};
}

// export function loadGeofences(...args) {
//   return (dispatch) => {
//     return geofence.fence.list(...args).then(response => {
//       if (!Array.isArray(response.items)){
//         dispatch(loadGeofencesSuccess(null));
//         return;
//       }
//       dispatch(loadGeofencesSuccess(response.items.reduce(
//         (fencesToAdd, geofence) => {
//           augmentGeofence(geofence);
//           fetchGeofenceTz(geofence.id, geofence.center)(dispatch);
//           fencesToAdd[geofence.id] = geofence;
//           return fencesToAdd;
//         }, {}
//       )));
//     }).catch(error => {
//       // TODO dispatch LOAD_GEOFENCES_FAILED
//       throw(error);
//     });
//   };
// }

export function fetchGeofenceTz(geofenceId, center) {
  return (dispatch) => {
    return guessCurrentTimezone(center).then(tz => {
      dispatch(fetchGeofenceTzSuccess(geofenceId, tz));
    }).catch(error => {
      console.warn(error); // eslint-disable-line no-console
      //throw(error); // TODO: figure out how to handle no timezone returned
    });
  };
}

/*
 * Entity Operation shepards the typical Create, Update, Delete requests.
 * Each need to have the API invoked with a payload and then the successful
 * outcome needs to be dispatched to the store.
 *
 * api - is expected to be restClient class/object that conforms to a standard
 * CRUD interface (create, update, delete methods take in an entity object, the
 * get method takes an entity ID)
 *
 * operation - is a string that is one of ['create', 'update', 'delete']. In theory
 * 'get' should work as well.
 *
 * entity - is an opaque object and is passed to the api method. It is expected
 * that the caller is passing in an appropriate object for the rest client method
 * being invoked.
 *
 * successAction - is the function within ActionCreators that will dispatch the
 * successful completion of the operation into the store. The returned payload
 * from the call is passed to the successAction for processing and dispatch.
 */
export function entityOperation(api, operation, entity, successAction) {
  return (dispatch) => {
    return api[operation](entity).then(
      response => {
        dispatch(successAction(response.data));
        return response;
      });
  };
}


export function setPartition(partitionKey) {
  return { type: 'SET_SETTING', name: 'partition', 'value':partitionKey };
}

export function setAccountId(accountId) {
  return { type: 'SET_ACCOUNT_ID', accountId: accountId };
}
