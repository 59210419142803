import { pick } from 'lodash';

// working example
/*
{
  waypoints: [
    {
      label: 'AuPA right lane',
      lat: 37.442501,
      lon: -122.159287,
      events: [
        { type: 'harsh_acceleration' },
        { type: 'seat_belt_status_while_moving', details: { seat: 'row1_seat1' }}
      ]
    },
    {
      label: 'AuPA left lane',
      lat: 37.442421,
      lon: -122.159201,
      events: [
        { type: 'harsh_braking' },
        { type: 'impact' }
      ]
    },
    {
      label: 'new office',
      lat: 37.4087,
      lon: -122.1458,
      events: [
        { type: 'seat_belt_status_while_moving', details: { seat: 'row1_seat2' }},
        { type: 'impact' }
      ]
    }
  ]
}
*/


/**
 * Converts dashboard scenario builder output to mouse's format. Input looks
 * like:
 * waypoints: [
 *  { label, lon, lat, &events }
 * ]
 * *events: [
 *  { type, details }
 * ]
 * @param  {string} vin
 * @param  {object} scenario
 * @return {object}          mouse formatted scenario format
 */
export function toMouseScenario(vin, scenario) {
  const waypoints = scenario.waypoints
    .map(w => pick(w, ['lat', 'lon', 'label']));

  const events = [];
  for (let waypoint of scenario.waypoints) {
    for (let event of (waypoint.events || [])) {
      events.push({
        ...(event.details || {}),
        type: event.type,
        trigger: [{
          type: 'gps',
          limit: 1,
          radius: 15,
          lat: waypoint.lat,
          lon: waypoint.lon
        }]
      });
    }
  }

  return {
    vehicles: {
      [vin]: {
        trip: {
          label: 'UI generated trip',
          waypoints,
          events
        }
      }
    }
  };
}
