import {history} from '../history';
import {environmentQueryParams} from '../constants';

export function getPartition() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(environmentQueryParams.partition);
}

export function setPartition(partitionKey) {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(environmentQueryParams.partition, partitionKey);
  const search = urlParams.toString();
  history.replace({ search });
}

export function removePartition() {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete(environmentQueryParams.partition);
  const search = urlParams.toString();
  history.replace({ search });
}

export function generateUrl(domain, path = "", queryParams = []) {
  const url = new URL(path, domain);
  url.search = new URLSearchParams(queryParams).toString();
  return url.href;
}