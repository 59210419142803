import { fromJS } from 'immutable';

export default {
  OPEN_POPOUT: (state, action) => {
    return state.set('popout', fromJS(action.popoutProps));
  },
  CLOSE_POPOUT: (state) => {
    return state.set('popout', fromJS({}));
  },
};
