import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';

import AuComponent from '@au/core/lib/components/elements/AuComponent';
import LoadingIndicator from '@au/core/lib/components/elements/LoadingIndicator';

import AuMap from './AuMap';
import { AuInfoBox } from './AuInfoBox';
import MapBundledControl from './MapBundledControl';
// import { genRoundAssetsLocationSelector } from '../utils/mapSelectors';
// import AuMarkerClusterer from './AuMarkerClusterer';
// import GeoFenceOutline from './GeoFenceOutline';

import styles from '../css/components/dashboard_map.module.scss';
// import { MarkerClusterer } from '@googlemaps/markerclusterer';

// import clusterIconStyles from '../utils/clusterIconStyles.json';

// const roundAssetsLocationSelector = genRoundAssetsLocationSelector();

/*
class ClustererWrapper extends React.Component {

  shouldComponentUpdate(nextProps) {
    const { roundAssetLocations, bounds, isDragging } = nextProps;

    if (!this.props.bounds || isDragging) {
      return false;
    }

    // Adjust location resolution to match display so we do not render too often
    let assetsEqual = false;
    if (this.props.assets && bounds) {
      // Compare rounded locations
      const roundedAssets = roundAssetLocations(this.props.assets, bounds);
      const roundedNextAssets = roundAssetLocations(nextProps.assets, bounds);
      assetsEqual = roundedAssets.equals(roundedNextAssets);
    }

    if (assetsEqual) {
      // Check if anything else has changed
      const otherProps = {...this.props};
      const otherNextProps = {...nextProps};

      delete otherProps.bounds;
      delete otherProps.assets;
      delete otherNextProps.assets;
      delete otherNextProps.bounds;

      return !smartEqual(otherProps, otherNextProps);
    }

    return true;
  }

  render() {
    const { map, assets, hoveredAsset, actions } = this.props;
    // const places = generateAssetMarkers(assets, hoveredAsset, actions);
    // const places = assets.map(asset =>
    //   new google.maps.Marker({
    //     position: {
    //       lat: asset.getIn(['location', 'lat']),
    //       lng: asset.getIn(['location', 'lon'])
    //     },
    //     map,
    //   })
    //   // ({ 
    //   //   latLng: new google.maps.LatLng(
    //   //     asset.getIn(['location', 'lat']), 
    //   //     asset.getIn(['location', 'lon'])
    //   //   )
    //   // })
    // ).toJS();    
    const locations = [
      { lat: -29.122382, lng: 26.217521 }, //Bloemfontein
      { lat: -29.133639, lng: 26.209048 }, //Show Ground
      { lat: -29.138617, lng: 26.188814 } //Fichardtpark Driving Range
    ];
    const places = locations.map(position => new google.maps.Marker({
      position 
    }))

    if (places && places.length) {
      const mc = new MarkerClusterer({ markers: places, map })
      // return <AuMarkerClusterer maxZoom={11} gridSize={30} mapRef={map}>{places}</AuMarkerClusterer>;
    }

    return false;
  }

}
*/

const markerProps = {
  icon: {
    url: 'images/vehicle.svg'
  }
};

export default class DashboardMap extends AuComponent {

  static propTypes = {
    assets: T.oneOfType([IPT.list, IPT.map]),
  }

  infoWindowRef = React.createRef(null);
  infoboxContentRef = React.createRef(null);

  state = {
    hoveredAsset: null
  }

  // shouldComponentUpdate(nextProps) {
  //   if (this._dragging || !nextProps.initialized) {
  //     // If we dragging, then don't update
  //     return false;
  //   }

  //   // Adjust location resolution to match display so we do not render too often
  //   let assetsEqual = false;
  //   if (this.props.assets && this.props.visibleBounds && nextProps.visibleBounds && this.container) {
  //     // Compare rounded locations
  //     const roundedAssets = this.roundAssetLocations(this.props.assets, this.props.visibleBounds);
  //     const roundedNextAssets = this.roundAssetLocations(nextProps.assets, nextProps.visibleBounds);
  //     assetsEqual = roundedAssets.equals(roundedNextAssets);
  //   }

  //   if (assetsEqual) {
  //     // Check if anything else has changed
  //     const otherProps = {...this.props};
  //     const otherNextProps = {...nextProps};
  //     delete otherProps.assets;
  //     delete otherNextProps.assets;
  //     return !smartEqual(otherProps, otherNextProps);
  //   }

  //   return true;
  // }

  // componentDidMount() {
  //   window.addEventListener('resize', this.updateVisibleBounds);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.updateVisibleBounds);
  // }

  // componentDidUpdate(prevProps) {
  //   if (this._map && this.props.bounds && !smartEqual(this.props.bounds, prevProps.bounds)) {
  //     this.setMapBounds(this.props.bounds);
  //   } else {
  //     this.updateVisibleBounds();
  //   }
  // }

  handleMapMounted = this.handleMapMounted.bind(this);
  handleMapMounted(map) {
    const { assets } = this.props;

    this._map = map;
    
    // set initial map bounds
    if (assets.size) {
      let bounds = this.getMapBounds(assets);
      this.setMapBounds(bounds);
    }
  }

  getMapBounds(assets) {
    let bounds = new google.maps.LatLngBounds();
    for (let asset of assets.values()) {
      bounds.extend(new google.maps.LatLng(
        asset.getIn(['location', 'lat']), 
        asset.getIn(['location', 'lon'])
      ));
    }
    return bounds;
  }

  setMapBounds(bounds) {
    const { actions } = this.props;

    if (!this._map) return;
    
    this._map.fitBounds(bounds);
    actions.mapBoundsChange(bounds);

    // update visible bounds
    // const newBounds = this._map.getBounds();
    // if (newBounds) {
    //   actions.mapVisibleBoundsChange(newBounds.toJSON());
    // }
  }

  handleMarkerClick = this.handleMarkerClick.bind(this);
  handleMarkerClick(asset) {
    const { actions } = this.props;
    actions.mapMarkerClick({
      lat: asset.getIn(['location', 'lat']),
      lng: asset.getIn(['location', 'lon'])
    });
    actions.mapMarkerHover(asset.get('vehicle_id'));
  }

  handleMarkerOver = this.handleMarkerOver.bind(this);
  handleMarkerOver(asset, marker) {
    if (this.state.hoveredAsset?.get('vehicle_id') === asset.get('vehicle_id')) {
      return;
    }

    const infowindow = new google.maps.InfoWindow({
      // content: `<div>Hello, ${asset.get('vehicle_id')}</div>`
      content: this.infoboxContentRef.current
    });

    // this.setState({ hoveredAsset: this.props.assets.get(asset.get('vehicle_id'))})
    this.props.actions.mapMarkerHover(asset.get('vehicle_id'));

    if (this.infoWindowRef.current) {
      this.setState({ hoveredAsset: null });
      this.infoWindowRef.current.close();
      this.infoWindowRef.current = infowindow;
    }

    infowindow.open({
      anchor: marker,
      shouldFocus: true
    })

    // marker.addListener('mouseout', () => {
    //   infowindow.close();
    // })
  }

  handleMarkerOut = this.handleMarkerOut.bind(this);
  handleMarkerOut() {
    // this.props.actions.mapMarkerEndHover(2000);
  }

  handleChanged = this.handleChanged.bind(this);
  handleChanged() {
    this._dragging = false;
    const nextCenter = this._map.getCenter().toJSON();
    const nextZoom = this._map.getZoom();
    this.props.actions.mapChange(nextCenter, nextZoom);
    this.props.actions.mapVisibleBoundsChange(this._map.getBounds().toJSON());
  }

  handleZoomChanged = this.handleZoomChanged.bind(this);
  handleZoomChanged(nextZoom) {
    const nextCenter = this._map.getCenter().toJSON();
    this.props.actions.mapChange(nextCenter, nextZoom);
  }

  // handleToggleScreenClick = this.handleToggleScreenClick.bind(this);
  // handleToggleScreenClick() {
  //   this.props.actions.mapToggleViewClick();
  // }

  // handleOnDrag = this.handleOnDrag.bind(this);
  // handleOnDrag() {
  //   this.updateVisibleBounds();
  // }

  // handleDragStart = this.handleDragStart.bind(this);
  // handleDragStart() {
  //   this._dragging = true;
  // }

  // roundAssetLocations = this.roundAssetLocations.bind(this);
  // roundAssetLocations(assets, bounds) {
  //   return roundAssetsLocationSelector(fromJS({
  //     assets,
  //     bounds,
  //     pixelHeight: this.container.offsetHeight,
  //     pixelWidth: this.container.offsetWidth
  //   }));
  // }

  // updateVisibleBounds = this.updateVisibleBounds.bind(this);
  // updateVisibleBounds() {
  //   if (this._map) {
  //     let bounds = this._map.getBounds();
  //     // update visible bounds when changed
  //     if (bounds && !bounds.equals(this.props.visibleBounds)) {
  //       this.props.actions.mapVisibleBoundsChange(bounds.toJSON());
  //     }
  //   }
  // }

  render() {
    const { center, zoom, assets, actions, initialized, hoveredAsset } = this.props;
    // const { hoveredAsset } = this.state;

    if (!initialized) {
      return (
        <div className={styles.loader}>
          <LoadingIndicator display={true} />
        </div>
      );
    }

    // let clusterer;
    // if (this._map) {
    //   clusterer = <ClustererWrapper
    //     assets={assets}
    //     hoveredAsset={hoveredAsset}
    //     actions={actions}
    //     isDragging={this._dragging}
    //     bounds={this.props.visibleBounds}
    //     roundAssetLocations={this.roundAssetLocations}
    //     map={this._map}
    //   />;
    // }

    return (
      <>
        <AuMap
          center={center}
          zoom={zoom}
          assets={assets}
          markerProps={markerProps}
          onMarkerClick={this.handleMarkerClick}
          onMarkerOver={this.handleMarkerOver}
          onMarkerOut={this.handleMarkerOut}
          onMapMounted={this.handleMapMounted}
          // onDragStart={this.handleDragStart}
          // onDragEnd={this.handleChanged}
          // onZoomChanged={this.handleChanged}
          // containerRefFn={container => this.container = container}
          customZoomControl={
            <MapBundledControl
              map={this.props}
              showToggle={true}
              showOnTablet={true}
              handleZoomChanged={this.handleZoomChanged}
              handleToggleScreenClick={this.handleToggleScreenClick}
            />
          }
        />
        <div style={{display: 'none'}}>
          <div ref={this.infoboxContentRef} id="infoboxcontent">
            { hoveredAsset && 
              <AuInfoBox vehicle={hoveredAsset} actions={actions} />
            }
          </div>
        </div>
      </>
    );
  }
}
