import { fromJS } from 'immutable';
import _ from 'lodash';

import flags from '../DevFlags';
import buildUnitConverter from '../utils/unitConversion.js';
import { rekeyNoOffset, buildBuckets } from '../utils/dateFunctions';

export default {
  RECEIVED_FUEL_CONSUMED_ANALYTICS: (state, action) => {
    return receivedFuelConsumedAnalytics(state, action, 'analytics');
  },
  RECEIVED_FUEL_CONSUMED_DASHBOARD_ANALYTICS: (state, action) => {
    return receivedFuelConsumedAnalytics(state, action, 'dashboardAnalytics');
  },
  RECEIVED_FUEL_ECONOMY_ANALYTICS: (state, action) => {
    const systemOfUnits = state.get('systemOfUnits');
    const converter = buildUnitConverter(systemOfUnits);
    const results = action.response.results[`interval:${action.interval}`];

    // Fill in missing odometer
    if (flags.addMissingOdometer) {
      const missing = splitMissingOdometer(results);
      if (flags.andPrintMissingOdometer) {
        console.log(`Missing odometer: ${missing} (${systemOfUnits})`); // eslint-disable-line no-console
      }
    }

    // We have to group by vehicle_id to get correct MPG data so we need to
    // sum up all vehicles
    const reducer = (entry_id, entry, store) => {
      store.distTraveled += entry['max:odometer'] - entry['min:odometer'];
      store.fuelConsumed += entry['sum:fuel_consumed'];
    };
    const aggFuelEconomy = aggVehicleGroupBy(results, reducer, { distTraveled: 0, fuelConsumed: 0});
    _.values(aggFuelEconomy).map(x => x.econ = x.fuelConsumed ? x.distTraveled/x.fuelConsumed : 0);

    // It is possible to have the fleet data populate each vehicle, but I
    // believe it is better to follow a paradigm instead. Individual
    // vehicle calls only happen when a user is on a vehicle analytics page.

    const fuelEconomy = analyticsChartFormatter(aggFuelEconomy, action.start, action.end, action.interval, 'econ', converter.econ);

    if (action.vehicle_id) {
      return state.setIn(['assetAnalytics', action.vehicle_id, 'fuelEconomy'], fromJS(fuelEconomy));
    } else {
      return state.setIn(['analytics', 'fuelEconomy'], fromJS(fuelEconomy));
    }
  },
  RECEIVED_DIST_TRAVELED_ANALYTICS: (state, action) => {
    return receivedDistTraveledAnalytics(state, action, 'analytics');
  },
  RECEIVED_DIST_TRAVELED_DASHBOARD_ANALYTICS: (state, action) => {
    return receivedDistTraveledAnalytics(state, action, 'dashboardAnalytics');
  },
  MODIFY_ANALYTICS_TIME_BACK: (state, action) => {
    return state.setIn(['analytics', 'timeBack'], action.timeBack);
  },
};

function receivedFuelConsumedAnalytics(state, action, storePath) {
  const converter = buildUnitConverter(state.get('systemOfUnits'));
  const results = action.response.results[`interval:${action.interval}`] || [];

  // Sum up all the group by vehicles
  const reducer = (entry_id, entry, store) => {
    store.fuelConsumed += entry['sum:fuel_consumed'];
  };
  const aggFuelConsumed = aggVehicleGroupBy(results, reducer, { fuelConsumed: 0 });

  const fuelConsumed = analyticsChartFormatter(aggFuelConsumed, action.start, action.end, action.interval, 'fuelConsumed', converter.vol);

  if (action.vehicle_id) {
    return state.setIn(['assetAnalytics', action.vehicle_id, 'fuelConsumed'], fromJS(fuelConsumed));
  } else {
    return state.setIn([storePath, 'fuelConsumed'], fromJS(fuelConsumed));
  }
}

export function splitMissingOdometer(results) {
  // Takes (time, vehicle) grouped data and evenly splits the missing odometer
  // values between the two buckets
  // TODO: remove this function once data is good or this moves to the backend
  const prev = {};
  let totalMissing = 0;
  for (let iso8601 of Object.keys(results).sort()) {
    const vehicleData = results[iso8601]['group_by:vehicle_id'];
    for (let vehicle_id of Object.keys(vehicleData)){
      const data = vehicleData[vehicle_id];
      if (!prev[vehicle_id]) {
        prev[vehicle_id] = data; // Initialize on the first entry
        continue;
      }
      const prevData = prev[vehicle_id];

      // Get the difference between time buckets
      const missing = data['min:odometer'] - prevData['max:odometer'];
      const split = Math.floor(missing/2);
      prevData['max:odometer'] += split;
      data['min:odometer'] -= missing - split; // Might get the extra tick
      prev[vehicle_id] = data;

      totalMissing += missing;
    }
  }
  return totalMissing;
}

export function aggVehicleGroupBy(results, reducer, initial) {
  const dayAgg = {};
  for (let iso8601 of Object.keys(results)) {
    const vehicleData = results[iso8601]['group_by:vehicle_id'];
    let dayInitial = Object.assign({}, initial);
    for (let vehicle_id of Object.keys(vehicleData)) {
      reducer(vehicle_id, vehicleData[vehicle_id], dayInitial);
    }
    dayAgg[iso8601] = dayInitial;
  }
  return dayAgg;
}

export function analyticsChartFormatter(perPeriodResults, start, end, interval, key, convert=x=>x, avgFill=false) {
  // Dates are generally return with local offset, need to rekey the perPeriodResults
  // for lookup
  perPeriodResults = rekeyNoOffset(perPeriodResults);

  // Generate date keys since perPeriodResults can have holes
  const iso8601keys = buildBuckets(interval, start, end);

  // Determine what to fill potential holes with
  let assumed = 0;
  if (avgFill) {
    const vals = _.values(perPeriodResults);
    assumed = vals.length ? vals.reduce((prev, curr) => prev + curr[key], 0) / vals.length : 0;
  }

  const samples = [];
  for (let iso8601 of iso8601keys) {
    const data = perPeriodResults[iso8601];
    const dataPoint = data ? data[key] : assumed;
    samples.push({
      periodStart: iso8601,
      val: convert(dataPoint)
    });
  }
  return samples;
}

function receivedDistTraveledAnalytics(state, action, storePath) {
  const systemOfUnits = state.get('systemOfUnits');
  const converter = buildUnitConverter(systemOfUnits);
  const results = action.response.results[`interval:${action.interval}`] || [];

  const reducer = (entry_id, entry, store) => {
    let distance = entry['max:odometer'] - entry['min:odometer'];

    if (distance > 1) {
      // Only qualifying vehicles should be counted
      ++store.qualAssetsCount;
      store.qualDistTraveled += distance;
      store.avgDistTraveled = store.qualDistTraveled / store.qualAssetsCount;
    }

    store.distTraveled += distance;
  };
  if (flags.addMissingOdometer) {
    const missing = splitMissingOdometer(results);
    if (flags.andPrintMissingOdometer) {
      console.log(`Missing odometer: ${missing} (${systemOfUnits})`); // eslint-disable-line no-console
    }
  }
  const aggDistTraveled = aggVehicleGroupBy(results, reducer, { distTraveled: 0, avgDistTraveled: 0, qualDistTraveled: 0, qualAssetsCount: 0 });

  const distTraveled = analyticsChartFormatter(aggDistTraveled, action.start, action.end, action.interval, 'distTraveled', converter.dist);

  const avgDistTraveled = analyticsChartFormatter(aggDistTraveled, action.start, action.end, action.interval, 'avgDistTraveled', converter.dist);

  if (action.vehicle_id) {
    state = state.setIn(['assetAnalytics', action.vehicle_id, 'distanceTraveled'], fromJS(distTraveled));
  } else {
    state = state
      .setIn([storePath, 'distanceTraveled'], fromJS(distTraveled))
      .setIn([storePath, 'averageDistanceTraveled'], fromJS(avgDistTraveled));
  }

  return state;
}
