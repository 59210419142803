import React from 'react';
import * as T from 'prop-types';

import { NOOP } from '../constants';

import styles from '../css/components/screen_width_detector.module.scss';

export const screenWidths = ['desktop', 'tabletLandscape', 'tabletPortrait', 'mobile'];

export default class ScreenWidthDetector extends React.PureComponent {

  static propTypes = {
    screenWidth: T.oneOf(screenWidths),
    onChange: T.func
  }

  static defaultProps = {
    onChange: NOOP
  }

  componentDidMount() {
    this.updateScreenWidth();
    window.addEventListener('resize', this.updateScreenWidth);
  }

  componentDidUpdate() {
    this.updateScreenWidth();
  }

  componentWillUnmount() {
    this.updateScreenWidth();
    window.removeEventListener('resize', this.updateScreenWidth);
  }

  updateScreenWidth = this.updateScreenWidth.bind(this);
  updateScreenWidth() {
    for (var i in screenWidths) {
      if (this.visible(screenWidths[i])) {
        if (this.props.screenWidth !== screenWidths[i]) {
          this.props.onChange(screenWidths[i]);
        }
        return;
      }
    }
  }

  visible(refName) {
    if (!this.refs[refName]) { return; }
    return window.getComputedStyle(this.refs[refName]).display !== 'none';
  }

  render() {
    return (
      <div>
        <div ref="mobile" className={styles.mobile}></div>
        <div ref="tabletPortrait" className={styles.tablet}></div>
        <div ref="tabletLandscape" className={styles.tablet_ls}></div>
        <div ref="desktop" className={styles.desktop}></div>
      </div>
    );
  }
}
