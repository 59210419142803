import React from 'react';
import * as T from 'prop-types';
// import { DirectionsRenderer } from 'react-google-maps';
import isEqual from 'lodash/isEqual';

import { wShape } from '@au/core/lib/AuPropTypes';
import MapBundledControl from './MapBundledControl';
import { AssetProps } from '../AuPropTypes';
import AuFixedMap from './AuFixedMap';
import AuVehicleMarker from './AuVehicleMarker';

const ZOOM_DEFAULT = 16;

export default class AssetMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = { zoom: ZOOM_DEFAULT };

    this.handleMapMounted = this.handleMapMounted.bind(this);
    this.handleZoomChanged = this.handleZoomChanged.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { waypoints } = this.props;

    if (!isEqual(prevProps.waypoints, waypoints)) {
      this.buildDirections();
    }
  }

  handleMapMounted(map) {
    this._map = map;
    this.directionsService = new google.maps.DirectionsService();
  }

  handleZoomChanged(nextZoom) {
    this.setState({
      zoom: nextZoom
    });
  }

  // * Deprecated - left as example to bring back with new API *
  // getGeoFenceOutlines() {
  //   const geofences = this.props.geofences;
  //   const geoFences = [];

  //   for (let [key, geofence] of geofences.entries()) {
  //     const { gCir, gPoly, gRect} = sharedGeoFenceSelectors.run(geofence).toJS();
  //     geoFences.push(
  //       <GeoFenceOutline key={key} gCir={gCir} gPoly={gPoly} gRect={gRect} />
  //     );
  //   }

  //   return geoFences;
  // }

  buildDirections() {
    const { waypoints } = this.props;

    if (waypoints.length < 2) {
      return;
    }

    const start = waypoints[0];
    const end   = waypoints[waypoints.length - 1];

    const routepProps = {
      travelMode: google.maps.TravelMode.DRIVING,
      origin: { lat: start.lat, lng: start.lon },
      destination: { lat: end.lat, lng: end.lon },
      waypoints: waypoints.slice(1, waypoints.length - 1).map(w =>
        ({
          location: { lat: w.lat, lng: w.lon },
          stopover: true
        })
      )
    };

    this.directionsService.route(routepProps, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        this.setState({
          directions: result
        });
      } else {
        /* eslint-disable no-console */
        console.error(`error fetching directions ${result}`);
        /* eslint-enable no-console */
      }
    });
  }

  getDirections() {
    const { waypoints } = this.props;
    const { directions } = this.state;

    if (waypoints.length < 2 || !directions) {
      return false;
    }

    // return (
    //   <DirectionsRenderer
    //     directions={directions}
    //     options={{
    //       // suppressMarkers: true
    //     }}
    //   />
    // );

    return false;
  }

  render() {
    let { vehicle_id, location, zoomControl=true } = this.props;

    if (!location) {
      return false;
    }

    const assetMarker = <AuVehicleMarker asset={{vehicle_id, location}} isClickable={false} isHoverable={false} />;
    // const geoFences = this.getGeoFenceOutlines();
    const customZoomControl = (zoomControl) ? <MapBundledControl map={this.state} handleZoomChanged={this.handleZoomChanged} /> : '';
    const lat = location.lat;
    const lon = location.lon;

    return (
      <div style={{ flex: 1, display: 'flex' }}>
        <AuFixedMap
          center={{lat: lat, lng: lon}}
          zoom={this.state.zoom}
          assetMarker={assetMarker}
          otherElements={this.getDirections()}
          onMapMounted={this.handleMapMounted}
          onZoomChanged={() => this.handleZoomChanged(this._map.getZoom())}
          customZoomControl={customZoomControl}
          // geofences={this.getGeoFenceOutlines}
        />
      </div>
    );
  }

  static propTypes = {
    asset: T.shape(AssetProps),
    vehicle_id: T.string,
    location: wShape({
      lon: T.number,
      lat: T.number,
      lng: T.number
    }),
    // geofences: T.object.isRequired
  };
}
