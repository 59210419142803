import _ from 'lodash';

export function getQueryTzOffset(minutesOffset=(new Date()).getTimezoneOffset()) {
  /**
   * Gets the timezone offset in the format for queries. Ex: -08:00 for PST.
   */
   const sign = minutesOffset > 0 ? '-' : '+'; // getTimezoneOffset returns 480 for PST
   const hours = Math.abs(Math.floor(minutesOffset / 60));
   const minutes = Math.abs(minutesOffset % 60);
   return `${sign}${_.padStart(hours, 2, '0')}:${_.padStart(minutes, 2, '0')}`;
}

export function rekeyNoOffset(dateKeyed) {
  /**
   * Frequently we need to look up date keyed results that come back with a local
   * offset. This function will rekey those with a UTC offset ISO string.
   */
  var rekeyedResults = {};
  for (let iso8601 of Object.keys(dateKeyed)) {
    const data = dateKeyed[iso8601];
    const noOffsetIso8601 = (new Date(iso8601)).toISOString();
    rekeyedResults[noOffsetIso8601] = data;
  }
  return rekeyedResults;
}

export function adjustOffset(targetOffset, newDate) {
  // FIXME: this adjust applies the offset, but the current TZ stays the same.
  // This means you cannot adjustOffset more than once on a date
  const minuteDiff = targetOffset - newDate.getTimezoneOffset();
  return new Date(newDate.getTime() + minuteDiff*60*1000);
}

export function buildBuckets(interval, firstDate, lastDate=(new Date())) {
  /**
   * Returns a chronologically ordered array of ISO-8601 strings
   * Note: Calculations might be off for leap years depending on your expectations for units greater than days
   * Note: A week is considered 7 days segments building from the firstDate
   * TODO: ensure the week definiton here matches what the backend does
   */
  const timeUnit = interval.slice(-1); // s, m, h, w, d, M, y
  const period = parseInt(interval.slice(0, -1));
  if (interval.length < 2 || !_.includes(['s', 'm', 'h', 'w', 'd', 'M', 'y'], timeUnit) || isNaN(period) || typeof period != 'number' || period <= 0) {
    if (process.env.NODE_ENV === 'development') console.warn(`interval "${timeUnit}" is invalid`); // eslint-disable-line no-console
    return false;
  }
  if (typeof firstDate === 'string') firstDate = new Date(firstDate);
  if (typeof lastDate === 'string') lastDate = new Date(lastDate);
  if (typeof firstDate !== 'object' || typeof lastDate !== 'object') {
    if (process.env.NODE_ENV === 'development') console.warn('invalid date'); // eslint-disable-line no-console
    return false;
  }

  // TODO: add some explanation
  const targetOffset = lastDate.getTimezoneOffset();

  let getNextDate;
  switch (timeUnit) {
    case 's':
      getNextDate = curDate => new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate(), curDate.getHours(), curDate.getMinutes(), curDate.getSeconds() + period);
      break;
    case 'm':
      getNextDate = curDate => new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate(), curDate.getHours(), curDate.getMinutes() + period);
      break;
    case 'h':
      getNextDate = curDate => new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate(), curDate.getHours() + period);
      break;
    case 'd':
      getNextDate = curDate => new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate() + period);
      break;
    case 'w':
      getNextDate = curDate => new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate() + 7*period);
      break;
    case 'M':
      getNextDate = curDate => new Date(curDate.getFullYear(), curDate.getMonth() + period);
      break;
    case 'y':
      getNextDate = curDate => new Date(curDate.getFullYear() + period, 0);
      break;
  }

  const iso8601keys = [adjustOffset(targetOffset, firstDate).toISOString()];
  let curDateTime = firstDate.getTime();
  let curDate = firstDate;
  const lastDateTime = lastDate.getTime();
  while (curDateTime < lastDateTime) {
    curDate = getNextDate(curDate);
    iso8601keys.push(adjustOffset(targetOffset, curDate).toISOString());
    curDateTime = curDate.getTime();
  }

  iso8601keys.pop(); // The last key would have stepped out of bounds

  return iso8601keys;
}

export const shortYearWithTime = {
  year: '2-digit', month: 'numeric', day: 'numeric',
  hour: 'numeric', minute: 'numeric',
  hour12: true
};
