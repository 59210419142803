import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";
import buildUnitConverter from '../utils/unitConversion';

class UnitProvider extends React.Component {

  constructor(props) {
    super(props);
    this._converter = buildUnitConverter(this.props.system);
  }

  getChildContext() {
    if (this.props.system === 'si') {
      return {
        units: {
          dist: {
            full: this.props.intl.formatMessage({id: "au.unit.si.dist.full"}),
            abbr: this.props.intl.formatMessage({id: "au.unit.si.dist.abbr"}),
            convert: this._converter.dist
          },
          vol: {
            full: this.props.intl.formatMessage({id: "au.unit.si.vol.full"}),
            abbr: this.props.intl.formatMessage({id: "au.unit.si.vol.abbr"}),
            convert: this._converter.vol
          },
          percentage: {
            full: this.props.intl.formatMessage({id: "au.unit.si.percentage.full"}),
            abbr: this.props.intl.formatMessage({id: "au.unit.si.percentage.abbr"}),
            convert: this._converter.percentage
          },
          rate: {
            full: this.props.intl.formatMessage({id: "au.unit.si.rate.full"}),
            abbr: this.props.intl.formatMessage({id: "au.unit.si.rate.abbr"}),
            convert: this._converter.rate
          },
          econ: {
            full: this.props.intl.formatMessage({id: "au.unit.si.econ.full"}),
            abbr: this.props.intl.formatMessage({id: "au.unit.si.econ.abbr"}),
            convert: this._converter.econ
          },
          minute: {
            full: this.props.intl.formatMessage({id: "au.unit.si.minute.full"}),
            abbr: this.props.intl.formatMessage({id: "au.unit.si.minute.abbr"}),
            convert: this._converter.minute
          },
          hour: {
            full: this.props.intl.formatMessage({id: "au.unit.si.hour.full"}),
            abbr: this.props.intl.formatMessage({id: "au.unit.si.hour.abbr"}),
            convert: this._converter.hour
          }
        }
      };
    } else if (this.props.system === 'uscs') {
      return {
        units: {
          dist: {
            full: this.props.intl.formatMessage({id: "au.unit.uscs.dist.full"}),
            abbr: this.props.intl.formatMessage({id: "au.unit.uscs.dist.abbr"}),
            convert: this._converter.dist
          },
          vol: {
            full: this.props.intl.formatMessage({id: "au.unit.uscs.vol.full"}),
            abbr: this.props.intl.formatMessage({id: "au.unit.uscs.vol.abbr"}),
            convert: this._converter.vol
          },
          percentage: {
            full: this.props.intl.formatMessage({id: "au.unit.uscs.percentage.full"}),
            abbr: this.props.intl.formatMessage({id: "au.unit.uscs.percentage.abbr"}),
            convert: this._converter.percentage
          },
          rate: {
            full: this.props.intl.formatMessage({id: "au.unit.uscs.rate.full"}),
            abbr: this.props.intl.formatMessage({id: "au.unit.uscs.rate.abbr"}),
            convert: this._converter.rate
          },
          econ: {
            full: this.props.intl.formatMessage({id: "au.unit.uscs.econ.full"}),
            abbr: this.props.intl.formatMessage({id: "au.unit.uscs.econ.abbr"}),
            convert: this._converter.econ
          },
          minute: {
            full: this.props.intl.formatMessage({id: "au.unit.uscs.minute.full"}),
            abbr: this.props.intl.formatMessage({id: "au.unit.uscs.minute.abbr"}),
            convert: this._converter.minute
          },
          hour: {
            full: this.props.intl.formatMessage({id: "au.unit.uscs.hour.full"}),
            abbr: this.props.intl.formatMessage({id: "au.unit.uscs.hour.abbr"}),
            convert: this._converter.hour
          }
        }
      };
    } else {
      throw `system property must be "si" or "uscs", not "${this.props.system}"`;
    }
  }

  static childContextTypes = {
    units: PropTypes.object
  }

  render() {
    return React.Children.only(this.props.children);
  }

}

export default injectIntl(UnitProvider);
