import { fromJS } from 'immutable';

/**
 * This file contains general purpose reducers.
 * At some point we might want split it into: Nav, System, etc. reducers.
 */

export default {
  SET_LOCALE: (state, action) => {
    return state.set('locale', action.locale);
  },
  SET_SYSTEM_OF_UNITS: (state, action) => {
    // Currently conversion is done as data comes in so this should be set
    // once on initial application load.
    return state.set('systemOfUnits', action.systemOfUnits);
  },
  NORMAL_NAV: (state) => {
    return state.updateIn(['nav', 'responsive'], () => false);
  },
  SCREEN_WIDTH_CHANGED: (state, action) => {
    return state.set('screenWidth', action.width);
  },
  SET_PAGE_TITLE_ID: (state, action) => {
    return state.set('pageTitleId', action.id);
  },
  SET_PAGE_TITLE: (state, action) => {
    return state.set('pageTitle', action.title);
  },
  SET_BREADCRUMBS_SCHEMA: (state, action) => {
    return state.set('breadcrumbsSchema', action.schema);
  },
  ACTIVITY_START: (state, action) => {
    return state.setIn(['activities', action.name], fromJS({
      status: 'pending'
    }));
  },
  ACTIVITY_END: (state, action) => {
    return state.setIn(['activities', action.name], fromJS({
      status: 'successful'
    }));
  },
  "NOOP": (state => state),
};
