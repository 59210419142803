import { createSelector } from 'reselect';
import { Map as imMap, fromJS } from 'immutable';
import { immutableSelectorCreator, genKeyedSelector } from '@au/core/lib/selectors/general';
import { aggVehicleGroupBy, analyticsChartFormatter } from '../../../reducers/analytics';
import { calculateTimeRanges } from '../../../components/utils/assetTrips';
import buildUnitConverter from '../../../utils/unitConversion.js';
import { calculateScore } from '../../../utils/drivingScore';

export const tripsSelector = (state, vehicle_id) => state.getIn([vehicle_id, 'trips'], imMap());

const distanceSelector = immutableSelectorCreator(
  [ trip => trip.get('rawDistData') ],
  rawDistData => {
    let distance = 0;

    if (rawDistData) {
      distance = rawDistData.get('max:odometer') - rawDistData.get('min:odometer');
    }

    return distance;
  }
);

// Finds number of behavior occurances and durations for kinds specified in
// the `initial` object.
const aggBehaviorTypesSelector = immutableSelectorCreator(
  [ trip => trip.get('rawBehaviorData') ],
  rawBehaviorData => {
    if (!rawBehaviorData) {
      return {};
    }

    const initial = {
      SPEED:      { count: 0, duration: 0 },
      ACCELERATE: { count: 0, duration: 0 },
      DECELERATE: { count: 0, duration: 0 }
    };
    const summedTypes = rawBehaviorData.reduce(
      (acc, entry) => {
        const kind = entry.get('kind');
        const duration = entry.get('duration');
        if (kind in acc) {
          acc[kind].count += 1;
          acc[kind].duration += duration;
        }
        return acc;
      },
      initial
    );
    return summedTypes;
  }
);

export const drivingScoreSelector = immutableSelectorCreator(
  [
    aggBehaviorTypesSelector,
    distanceSelector
  ],
  (aggedBehaviorTypes, distance) => {
    const eventCounters = {};
    Object.entries(aggedBehaviorTypes).forEach(([kind, { count }]) =>
      eventCounters[kind] = count || 0
    );
    return calculateScore(eventCounters, distance);
  }
);

function genAvgDrivingScoreSelector() {
  return immutableSelectorCreator(
    [ params => params.trips ],
    trips => {
      let total = 0;
      let avg   = 0;

      if (trips.size) {
        trips.forEach(trip => total += drivingScoreSelector(trip));
        avg = total / trips.size;
      }

      return avg;
    }
  );
}

function genTripsDataSelector() {
  return immutableSelectorCreator(
    [
      params => params.trips,
      params => params.timezone,
      params => params.systemOfUnits
    ],
    (trips, timezone, systemOfUnits) => {
      const converter = buildUnitConverter(systemOfUnits);
      const rows = [];

      if (timezone) {
        for (let range of calculateTimeRanges({ timezone })) {
          const trip = trips.get(range.toString(), imMap());
          const behaviorsProcessedData = behaviorSelectors.get(range)(trip);
          const { SPEED: aggSpeed, ACCELERATE: aggAccel, DECELERATE: aggBrake } = behaviorsProcessedData.aggedBehaviorTypes;
          const { drivingScore } = behaviorsProcessedData;
          const traveled = trip.get('rawDistData');
          const distance = (traveled || {}).size ? converter.dist(traveled.get('max:odometer') - traveled.get('min:odometer')) : 0;
          rows.push({
            date: range.startTime,
            distance: (Math.abs(distance) === Infinity ? 0 : distance).toFixed(1),
            speed: aggSpeed ? aggSpeed.count : 0,
            accel: aggAccel ? aggAccel.count : 0,
            brake: aggBrake ? aggBrake.count : 0,
            display: trip.get('display', false),
            range,
            drivingScore
          });
        }
      }

      return fromJS(rows);
    }
  );
}

function genBehaviorSelector() {

  // Processes behavior data for component consumption
  const processRawBehaviorData = immutableSelectorCreator(
    [ trip => trip.get('rawBehaviorData') ],
    rawBehaviorData => {
      if (!rawBehaviorData) {
        return;
      }
      const behaviors = rawBehaviorData.toJS();
      for (let behavior of behaviors) {
        behavior.event_start = new Date(behavior.event_start).getTime();
        behavior.event_end = new Date(behavior.event_end).getTime();
      }

      return behaviors;
    }
  );

  return createSelector(
    [ processRawBehaviorData, aggBehaviorTypesSelector ],
    (processedBehaviors, aggedBehaviorTypes) => ({
      processedBehaviors,
      aggedBehaviorTypes
    })
  );
}

function genFuelConsumedSelector() {
  const sumFuelConsumedSelector = immutableSelectorCreator(
    [ params => params.trip.get('rawFuelData') ],
    rawFuelData => {
      if (!rawFuelData) {
        return;
      }

      let sum = 0;
      rawFuelData.map(buckets =>
        buckets.map(groupByVehicle =>
          groupByVehicle.get('group_by:vehicle_id').map(
            vehicleData => sum += vehicleData.get('sum:fuel_consumed', 0)
          )
        )
      );

      return sum;
    }
  );

  const buildChartDataPointsSelector = immutableSelectorCreator(
    [
      params => params.trip.get('rawFuelData'),
      params => params.range.startTime.toISOString(),
      params => params.range.endTime.toISOString(),
      params => params.interval
    ],
    (rawFuelData, startTime, endTime, interval) => {
      if (!rawFuelData) {
        return;
      }

      const reducer = (entry_id, entry, store) => {
        store.fuelConsumed += entry['sum:fuel_consumed'];
      };
      const aggFuelConsumed = aggVehicleGroupBy(rawFuelData.get(`interval:${interval}`, imMap()).toJS(), reducer, { fuelConsumed: 0 });

      return analyticsChartFormatter(aggFuelConsumed, startTime, endTime, interval, 'fuelConsumed');
    }
  );

  return createSelector(
    [ sumFuelConsumedSelector, buildChartDataPointsSelector ],
    (sumFuelConsumed, chartDataPoints) => ({
      sumFuelConsumed,
      chartDataPoints
    })
  );
}

export const behaviorSelectors = genKeyedSelector(genBehaviorSelector, key => key.toString());

export const sharedFuelConsumedSelectors = genKeyedSelector(genFuelConsumedSelector, key => key.toString());

export const tripsDataSelector = genKeyedSelector(genTripsDataSelector);

export const avgDrivingScoreSelector = genKeyedSelector(genAvgDrivingScoreSelector);
