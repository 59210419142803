import React from 'react';
import * as T from 'prop-types';
import moment from 'moment-timezone';

import { DATETIME_FORMAT_FULL } from '../constants';

const DEFAULT_TIMEZONE = moment.tz.guess();

export default function AuFormatRelative({ date, timezone, momentFormat }) {
  const momentDate = moment.tz(date, timezone);

  return (
    <span title={momentDate.format(momentFormat)}>
      { momentDate.fromNow() }
    </span>
  );
}

AuFormatRelative.propTypes = {
  date: T.instanceOf(Date).isRequired,
  timezone: T.string,
  momentFormat: T.string
}

AuFormatRelative.defaultProps = {
  timezone: DEFAULT_TIMEZONE,
  momentFormat: DATETIME_FORMAT_FULL
}