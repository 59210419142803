import { fromJS } from 'immutable';

export default {
  RECEIVED_GEO_FENCE_DATA: (state, action) => {
    const innerBoundsData = action.innerBoundsResponse.results['interval:15m'];
    const outerBoundsData = action.outerBoundsResponse.results['interval:15m'];


    const tableData = [];

    var isBoundsEmpty = function(bounds){
      return (!('max:timestamp' in bounds) || (bounds['max:timestamp'] === '-Infinity')
              || !('min:timestamp' in bounds) || (bounds['min:timestamp'] === 'Infinity'));
    };

    if (innerBoundsData && outerBoundsData){

      /*
       * if inner === outer -> then inside
       * if inner empty && outer not empty -> then outside
       * if inner not empty && outer empty -> error condition
       * if inner empty && outer empty -> then off or outside
       * if inner.max < outer.max -> then exiting, is outside
       * If inner.max > outer.max -> then entering, is inside
       * If inner empty && outer not empty && was inside -> exiting is outside
       * If inner ==== outer && was outside -> entering is inside
       */
      const IN = 'in';
      const OUT = 'out';
      const UNKNOWN = null;
      let inOutStatus = UNKNOWN;
      Object.keys(outerBoundsData).sort().forEach((iso8601) => {
        let innerTimes = innerBoundsData[iso8601];
        let outerTimes = outerBoundsData[iso8601];
        let innerEmpty = !(iso8601 in innerBoundsData) || isBoundsEmpty(innerTimes);
        let outerEmpty = isBoundsEmpty(outerTimes);
        let prevStatus = inOutStatus;

        if (innerEmpty && outerEmpty){
          // car is off or far outside of bounds
          // leave last known state
          return;
        }
        else if (!innerEmpty && outerEmpty){
          //ERROR CONDITION!!!!
          /* eslint-disable no-console */
          console.log("ERROR CONDITION", iso8601, innerTimes, outerTimes);
          /* eslint-enable no-console */
          inOutStatus = UNKNOWN;
          return;
        }
        else if (innerEmpty && !outerEmpty){
          if (prevStatus === IN){
            //Was inside and is now outside
            tableData.push({direction: 'Exited', timestamp: outerTimes['min:timestamp']});
          }
          //presume is just outside
          inOutStatus = OUT;
          return;
        }
        else if ((innerTimes['max:timestamp'] === outerTimes['max:timestamp'])
            && (innerTimes['min:timestamp'] === outerTimes['min:timestamp'])){
          if (prevStatus === OUT){
            tableData.push({direction: 'Entered', timestamp: innerTimes['min:timestamp']});
          }
          //Is in Parking lot.
          inOutStatus = IN;
          return;
        }

        if ((prevStatus === OUT) && (innerTimes['min:timestamp'] > outerTimes['min:timestamp'])){
          //came from outside to inside
          inOutStatus = IN;
          tableData.push({direction: 'Entered', timestamp: innerTimes['min:timestamp']});
        }
        else if ((prevStatus !== OUT) && (innerTimes['min:timestamp'] < outerTimes['min:timestamp'])){
          //Went from inside to outside
          inOutStatus = OUT;
          tableData.push({direction: 'Exited', timestamp: innerTimes['min:timestamp']});

          if (innerTimes['max:timestamp'] > outerTimes['max:timestamp']){
            //came from outside to inside, but would only occur if the vehicle
            //went out, and then back in within the same time bucket.
            inOutStatus = IN;
            tableData.push({direction: 'Entered', timestamp: outerTimes['max:timestamp']});
          }
        }

        if ((prevStatus !== OUT) && (innerTimes['max:timestamp'] < outerTimes['max:timestamp'])){
          //Went from inside to outside
          inOutStatus = OUT;
          tableData.push({direction: 'Exited', timestamp: innerTimes['max:timestamp']});
        }
      });
    }
    else {
      // console.log("don't have inner and outer bounds");
    }

    var enterExits = [];
    var prior;
    tableData.forEach((datum) => {
      if (prior){
        prior.duration = new Date(datum.timestamp) - new Date(prior.timestamp);
        if (datum.direction === 'Exited'){
          enterExits.push({enter: prior.timestamp, exit: datum.timestamp, duration: prior.duration});
        }
      }
      else {
        //this is the first one
        if (datum.direction === 'Exited'){
          enterExits.push({enter: '', exit: datum.timestamp, duration: 0});
        }
      }
      prior = datum;
    });
    //using lingering reference - populate the most recent duration relative to now
    if (prior){
      prior.duration = Date.now() - new Date(prior.timestamp);
      if (prior.direction === 'Entered'){
        enterExits.push({enter: prior.timestamp, exit: '', duration: prior.duration});
      }
    }

    return state.setIn(['entities', 'assets', action.vehicleId, 'geofenceHistory', action.geoFenceId, 'data'], fromJS(enterExits));
  },
  CREATE_GEOFENCE_SUCCESS: (state, action) => {
    return state.setIn(['geofences', action.geofence.id], fromJS(action.geofence));
  },
  LOAD_GEOFENCES_SUCCESS: (state, action) => {
    if (!action.geofences){
      return state;
    }
    return state.set('geofences', fromJS(action.geofences));
  },
  UPDATE_GEOFENCE_SUCCESS: (state, action) => {
    return state.setIn(['geofences', action.geofence.id], fromJS(action.geofence));
  },
  DELETE_GEOFENCE_SUCCESS: (state, action) => {
    return state.deleteIn(['geofences', action.geofence.id]);
  },
  SET_GEOFENCE_TZ: (state, action) => {
    return state.setIn(['geofences', action.geofenceId, 'timezone'], action.timezone);
  },
  SET_GEOFENCES_SORT_ORDER: (state, action) => {
    return state.set('geofencesSortOrder', action.order);
  }

};
