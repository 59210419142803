export default function buildConverter(system) {
  // I wish we could store functions in immutable
  const minute = s => s/60;
  const hour = s => s/3600;
  if (system === 'si') {
    return {
      dist: x => x,
      vol:  v => v,
      rate: r => r,
      econ: e => e,
      minute,
      hour,
      percentage: p => p
    };
  } else if (system === 'uscs') {
    return {
      dist: x => 0.621371*x,
      vol:  v => 0.264172*v,
      rate: r => 0.621371*r,
      econ: e => 2.352145*e,
      minute,
      hour,
      percentage: p => p
    };
  } else {
    throw `system must be "si" or "uscs", not "${system}"`;
  }
}
