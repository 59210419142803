import React from 'react';
import * as T from 'prop-types';
import { Map as imMap } from 'immutable';

import IPT from 'react-immutable-proptypes';

import { routeRangeDeserializer } from '../stores/routes/utils/serializers';
import { getLatestTrip, colorTrips, SharedTripsNetworkWrapper } from './utils/assetTrips';
import { AssetTripsMap } from './AssetTripsMap';

// TODO: write debug function that prints a warning if the distance reported
// differs by around 10% or more from the distanced plotted on the map.

    // checks that dates are sorted TODO: resume checking for data discrepancies
    // let times = latestTrip.toJS().rawTripData;
    // function isSorted(times) {
    //   for (let i=0; i < times.length-1; i++){
    //     const current = new Date(times[i].timestamp);
    //     const next = new Date(times[i + 1].timestamp);
    //     if (current > next) {
    //       console.log('not sorted');
    //     }
    //     console.log('sorted');
    //   }
    // }

    // isSorted(timezones);


// This component contains components from multiple stores.  We need to have this
// "hub/hyper connected" component that cannot have a pure component counterpart
// connect the components with different stores.

export default class NetworkWrapper extends SharedTripsNetworkWrapper {

  static propTypes = {
    vehicle_id: T.string,
    trips: IPT.map,
    timezone: T.string,
  }

  // Separates the fetch requests from the route map to avoid re-renders
  render() {
    const { vehicle_id, trips, timezone, actions, groupId } = this.props;
    if (!timezone) {
      return false;
    }

    // Fetch any missing routes
    trips.forEach((trip, key) => {
      const [startTime, endTime] = routeRangeDeserializer(key);

      if (!trip.has('rawTripData')) {
        actions.fetchRoutes(vehicle_id, startTime, endTime, groupId);
      }

      // if (!trip.has('rawDistData')) {
      //   actions.fetchDistanceTraveled(vehicle_id, startTime, endTime);
      // }
    });

    const details = getLatestTrip(timezone, trips);
    if(!details) {
      return false;
    }
    const [latestTrip, key] = details;

    if (!latestTrip) {
      return false;
    }

    const panelTrips = imMap({
      [key]: latestTrip
    });

    return <AssetTripsMap {...this.props} trips={colorTrips(timezone, panelTrips)} />;
  }
}