import React, { Component } from 'react';
import copy from '@au/core/lib/utils/copyTextToClipboard';
import AuInput from '@au/core/lib/components/elements/AuInput';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import IPT from 'react-immutable-proptypes';

import AuButton, { BUTTON_TYPE_PLAIN } from '@au/core/lib/components/elements/AuButton';

import styles from '../css/components/asset_data.module.scss';

export default class AssetData extends Component {

  static propTypes = {
    asset: IPT.map
  }

  state = {}

  copyJsonData(stringData) {
    copy(stringData);
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: undefined }), 5000);
  }

  render() {
    if (!this.props.vehicle_id) {
      // There are cases where the vehicle_id might not be set when the user
      // refreshes the browser directly from the vehicle details url.
      return false;
    }
    const { vehicle_id, asset, actions } = this.props;

    actions.loadVehicleData(vehicle_id);

    const stringData = JSON.stringify(asset, null, 2);
    const isCopied = this.state.copied;
    const endpoint = `${window.API_BASE_URL}/telemetry/${window.WORKSPACE}/vehicles/${vehicle_id}`;

    return (
      <div className={styles.container}>
        <div className={styles.inner_container}>
          <div className={styles.endpoint_container}>
            <AutoIntl
              displayId="au.data.endpoint"
              className={styles.title}
              tag="h3"
            />
            <div className={styles.endpoint}>
              {endpoint}
            </div>
          </div>
          {isCopied &&
            <AutoIntl
              displayId="au.copied"
              tag="div"
              className={styles.custom_button__copied}
            />
          }
          {!isCopied &&
            <AuButton
              type={BUTTON_TYPE_PLAIN}
              displayId="au.data.copyJson"
              onClick={this.copyJsonData.bind(this, stringData)}
              className={styles.custom_button}
            />
          }
        </div>
        <div className={styles.textarea}>
          <AuInput
            type="textarea"
            name="json"
            value={stringData}
            className={styles.custom_input}
            disabled={true}
          />
        </div>
      </div>
    );
  }
}
