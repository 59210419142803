import { fromJS } from 'immutable';

export default {
  REQUESTED_TOPIC: (state, action) => {
    const version = state.getIn(['fetchTopics', action.topic, 'version'], 0) + 1;
    const topicMeta = {
      isFetching: true,
      startedFetchAt: Date.now(),
      status: 'pending',
      version
    };
    return state.setIn(['fetchTopics', action.topic], fromJS(topicMeta));
  },
  FAILED_TOPIC: (state, action) => {
    console.log('FAILED_TOPIC', action.topic); // eslint-disable-line no-console
    const topicMeta = {
      isFetching: false,
      finishedFetchAt: Date.now(),
      status: 'failed'
    };
    return state.mergeIn(['fetchTopics', action.topic], fromJS(topicMeta));
  },
  RECEIVED_TOPIC: (state, action) => {
    const topicMeta = {
      isFetching: false,
      finishedFetchAt: Date.now(),
      status: 'successful'
    };
    return state.mergeIn(['fetchTopics', action.topic], fromJS(topicMeta));
  }
};
