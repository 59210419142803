import { breadcrumbsConnectDefaults } from '../utils/ConnectDefaults';
import { immutableSelectorCreator } from '@au/core/lib/selectors/general';
import Assets from '../components/Assets';
import PropProvider from '../PropProvider';

// Stupidly checks if the entire state has changed.  This is here to prevent
// Expensive re-renders and PropProvider calls when scrolling happens.
const assetsSelector = immutableSelectorCreator(
  [ state => state ],
  state => PropProvider(state).Assets()
);

export default breadcrumbsConnectDefaults(
  Assets,
  assetsSelector,
  'au.section.title.vehicles',
  'details'
);
