import { breadcrumbsConnectDefaults } from '../utils/ConnectDefaults';
import { observableSelector } from '../utils/selectors';
import Dashboard from '../components/Dashboard';
import PropProvider from '../PropProvider';

const dashboardSelector = observableSelector(state => PropProvider(state).Dashboard());

export default breadcrumbsConnectDefaults(
  Dashboard,
  dashboardSelector,
  'au.noop',
  'index'
);
