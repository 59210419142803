import React from 'react';
import * as T from 'prop-types';
// import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import { FormattedDate } from 'react-intl';
import cn from 'classnames';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import VehicleLink from '@au/core/lib/components/elements/VehicleLink';

import styles from '../css/components/au_info_box.module.scss';
// import xWithoutBlueCircle from '../../images/x_blue_wo_circle.svg';

export const auTableDateOptions = {
  //FIXME - LOCALIZATION ISSUE
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  momentFormat: 'M/D/Y h:mmA'
};

// const INFO_BOX_OPTIONS = {
//   disableAutoPan: false,
//   maxWidth: 0,
//   pixelOffset: undefined, // populated on rendering
//   zIndex: 2000,
//   boxStyle: {
//     marginLeft: "-2px",  // compensate padding used for the shadow
//     padding: "0px 2px 2px 2px",
//     width: "300px",
//   },
//   // closeBoxURL: xWithoutBlueCircle,
//   infoBoxClearance: undefined, // populated on rendering
//   isHidden: false,
//   pane: "floatPane",
//   enableEventPropagation: true,
// };

class Li extends React.Component {
  render() {
    const { label, labelId, value, unitType, unitLabel } = this.props;
    let units;

    if (unitType && unitLabel && value && this.props.units) {
      units = this.props.units[unitType][unitLabel];
    }

    return (
      <li className={styles.line}>
        <AutoIntl className={styles.label} displayId={labelId} displayString={label} />
        <span className={styles.delimiter}> : </span>
        <span className={styles.value}>{value} {units}</span>
      </li>
    );
  }
}

export class AuInfoBox extends React.Component {

  static propTypes = {
    vehicle: T.object,
    timestamp: T.string,
    odometer: T.number,
    fuel_level: T.oneOfType([T.number, T.string]),
    speed: T.number,
    vin: T.string,
    vehicle_id: T.string,
    units: T.object,
    actions: T.object
  }

  // onMouseOver = this.onMouseOver.bind(this);
  // onMouseOver(e) {
  //   const events = ["mousedown", "mouseup", "mousewheel", "dblclick", "touchstart", "touchend", "touchmove"];

  //   /* prevent above events propagation to the Map */
  //   for (var i = 0; i < events.length; i++) {
  //     google.maps.event.addDomListener(e.target, events[i], ev => {
  //       ev.cancelBubble = true;
  //       if (ev.stopPropagation) {
  //         ev.stopPropagation();
  //       }
  //     });
  //   }

  //   this.props.actions.mapMarkerHover(this.props.vehicle_id);
  // }

  // onMouseOut = this.onMouseOut.bind(this);
  // onMouseOut() {
  //   this.props.actions.mapMarkerEndHover(2000);
  // }

  render() {
    const { actions, vehicle, units } = this.props;
    const { vin, vehicle_id, speed, fuel_level, odometer, timestamp } = vehicle.toJS();
    const vinLink = <VehicleLink vehicleId={vehicle_id} actions={actions}>{vin}</VehicleLink>;

    return (
      <div className={styles.container} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
        <div className={styles.content}>
          <ul className={cn(styles.list, 'o-list-bare')}>
            <Li labelId="au.vehicle.vin"        value={vinLink} />
            <Li labelId="au.vehicle.speed"      value={speed}      units={units} unitType="rate" unitLabel="abbr" />
            <Li labelId="au.vehicle.fuelLevel"  value={fuel_level} units={units} unitType="percentage" unitLabel="full" />
            <Li labelId="au.vehicle.odometer"   value={odometer}   units={units} unitType="dist" unitLabel="abbr" />
            <Li labelId="au.vehicle.lastUpdate" value={<FormattedDate {...auTableDateOptions} value={timestamp} />} />
          </ul>
        </div>
        <div className={styles.buttons}>
          <VehicleLink className={styles.info} vehicleId={vehicle_id} actions={actions}>
            <AutoIntl displayId="au.vehicle.vehicleInfo" />
          </VehicleLink>
          {/* TODO implement notes (TBD) */}
          {/* <AutoIntl className={styles.note} displayId="au.vehicle.note" tag="a" /> */}
        </div>
      </div>
    );
  }
}

export default class ImprovedInfoBox extends React.Component {

  // NOTE this is outdated Context API and may brake any time
  static contextTypes = { units: T.object }

  onCloseClick = this.onCloseClick.bind(this);
  onCloseClick() {
    this.props.actions.mapMarkerEndHover(0);
  }

  render() {
    // const options = {...INFO_BOX_OPTIONS,
    //   pixelOffset: new google.maps.Size(-148, 0),
    //   infoBoxClearance: new google.maps.Size(1, 1)
    // };

    // return (
    //   <InfoBox options={options} onCloseClick={this.onCloseClick}>
    //     <AuInfoBox {...this.props} units={this.context.units} />
    //   </InfoBox>
    // );

    return false;
  }
}