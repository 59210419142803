import { connect } from 'react-redux';
import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';

import PropProvider from '../PropProvider';
import GlobalNavigation from '../components/GlobalNavigation';

export default connect(
  state => PropProvider(state).GlobalNavigation(),
  defaultMapDispatchToProps
)(GlobalNavigation);
