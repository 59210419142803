import moment from 'moment-timezone';

import TimezoneFetcher from '@au/core/lib/network/timezone';

export const timezonesFetcher = new TimezoneFetcher();
const browserTimezone = moment.tz.guess();

export function guessCurrentTimezone(location) {
  return timezonesFetcher.fetchAtNow(location, true) // true: attempt to use local lib first
    .catch(msg => {
      console.warn('Unable to get timezone: %s', msg); // eslint-disable-line no-console
      return browserTimezone;
    });
}
