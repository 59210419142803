import React from 'react';
import { injectIntl } from 'react-intl';

import MetricTable from './MetricTable';
import AuFormatRelative from './AuFormatRelative';
import { auTableDateOptions } from '../utils/formatters';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import styles from '../css/components/asset_metric_table.module.scss';

class AssetMetricTable extends React.PureComponent {

  componentDidMount() {
    const { actions } = this.props;
    const asset = this.props.asset.toJS();
    if (!("name" in asset)) {
      actions.loadVehicle(asset.id);
    }
  }
  
  render() {
    let { intl } = this.props;
    let asset = this.props.asset.toJS();

    let lastUpdate = <AuFormatRelative dateOptions={auTableDateOptions} value={asset.timestamp} />;
    let unknownVal = intl.formatMessage({id: 'au.vehicle.unknownVal'});
    return (
      <div className={styles.container}>
        <div className={styles.stats}>
          <AutoIntl displayId="au.vehicle.section.title.metrics" className={styles.header} tag="div" />
          <MetricTable
            cells={[
              [ intl.formatMessage({id: "au.vehicle.ignitionStatus"}),    asset.ignition_status && intl.formatMessage({ id: `au.${asset.ignition_status.toUpperCase() === 'ON' ? 'on': 'off'  }`}) ],
              [ intl.formatMessage({id: "au.vehicle.transGearPos"}),      asset.transmission_gear_position && intl.formatMessage({ id: `au.vehicle.gear.${asset.transmission_gear_position.toLowerCase()}` }) ],
              [ intl.formatMessage({id: "au.vehicle.engineCoolantTemp"}), asset.engine_coolant_temp ],
              [ intl.formatMessage({id: "au.vehicle.engineOilTemp"}),     asset.engine_oil_temp ? asset.engine_oil_temp.toFixed(2) : unknownVal],
              [ intl.formatMessage({id: "au.vehicle.transOilTemp"}),      asset.transmission_oil_temperature ? asset.transmission_oil_temperature.toFixed(2) : unknownVal],
              [ intl.formatMessage({id: "au.vehicle.lastUpdate"}),        lastUpdate ],
              [ intl.formatMessage({id: "au.vehicle.speed"}),             asset.speed ],
              [ intl.formatMessage({id: "au.vehicle.engineSpeed"}),       asset.engine_speed ],
              [ intl.formatMessage({id: "au.vehicle.fuelLevel"}),         intl.formatMessage({ id: 'au.percentage' }, { number: asset.fuel_level }) ],
              [ intl.formatMessage({id: "au.vehicle.odometer"}),          asset.odometer ],
            ]}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(AssetMetricTable);
