import React, { useRef, useEffect } from 'react';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { MarkerClusterer } from '@googlemaps/markerclusterer';

import { AuClusterRenderer } from './AuMarkerClusterer';
import shared from '../shared';

import styles from '../css/components/map.module.scss';
import auMapStyles from '../utils/AuMapStyle.json';

const render = (status) => {
  switch (status) {
    case Status.LOADING:
      return <div>Loading...</div>;
    case Status.FAILURE:
      return <div>Failed to load the map</div>;
    default:
      break;
  }
}

function AuMap({ mapType, assets, customZoomControl, onMapMounted,
  markerProps, onMarkerClick, onMarkerOver, onMarkerOut, otherElements, ...mapProps 
}) {
  const mapRef = useRef(null);
  const mapElRef = useRef(null);
  const clustererRef = useRef(null);

  useEffect(() => {
    const map = new window.google.maps.Map(mapElRef.current, { ...mapProps,
      mapTypeId: mapType ?? 'roadmap',
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false,
      styles: auMapStyles
    });
    mapRef.current = map;
    onMapMounted(map);
  }, []);

  useEffect(() => {
    const markers = assets.map(asset => {
      const marker = new google.maps.Marker({
        ...markerProps,
        position: {
          lat: asset.getIn(['location', 'lat']),
          lng: asset.getIn(['location', 'lon'])
        }
      });
      marker.addListener('click', () => {
        onMarkerClick(asset, marker);
      });
      marker.addListener('mouseover', () => {
        onMarkerOver(asset, marker);
      });
      marker.addListener('mouseout', () => {
        onMarkerOut(asset, marker);
      })

      return marker;
    });

    clustererRef.current = new MarkerClusterer({ 
      map: mapRef.current, 
      renderer: new AuClusterRenderer(),
      markers
    });
    return () => {
      clustererRef.current.clearMarkers();
    };
  }, [assets]);

  return (
    <div className={styles.container}>
      { customZoomControl }
      <div ref={mapElRef} id="map" className={styles.map}>
        { otherElements }
      </div>
    </div>
  );
}

export default props => {
  return (
    <Wrapper apiKey={shared.config.gMapsKey} render={render}>
      <AuMap {...props} />
    </Wrapper>
  )
};