import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as T from 'prop-types';
import get from 'lodash/get';

import Constants from '../constants';

import styles from '../css/components/breadcrumbs.module.scss';

class Breadcrumbs extends Component {
  static propTypes = {
    pageTitle: T.string,
    pageTitleId: T.string,
  }

  static contextTypes = {
    router: T.object.isRequired
  };

  render() {
    let { intl, pageTitleId, pageTitle, schema } = this.props;
    const router = this.context.router;
    const routePageTitleId = get(router.route.location, 'state.pageTitleId');

    pageTitle = pageTitle || (intl.formatMessage({id: pageTitleId}) || '').trim() || (routePageTitleId && intl.formatMessage({id: routePageTitleId})) || '';
    const backBtnClick = router.route.location.hash ? () => router.history.push('/dashboard') : router.history.goBack;

    const showAddBtn    = schema === 'list-add';
    const showSaveBtn   = ['create', 'edit'].includes(schema);
    const showCancelBtn = showSaveBtn;
    const showBackBtn   = !(showAddBtn || showSaveBtn || showCancelBtn);

    pageTitle = pageTitle.trim();
    if (router.route.location.pathname === '/dashboard' && !pageTitle) {
      return false;
    }

    return (
      <div className={showSaveBtn ? styles.ontop : styles.container}>
        {showBackBtn && <div className={styles.button} onClick={backBtnClick}>
          <FormattedMessage id="au.breadcrumbs.back" />
        </div>}
        {showAddBtn && <div className={styles.add}
          onClick={() => window.dispatchEvent(new CustomEvent(Constants.EVENT_ADD_ENTITY_BTN_CLICK))}
        >
          <FormattedMessage id="au.breadcrumbs.add" />
        </div>}
        {showSaveBtn && <div className={styles.save}
          onClick={() => window.dispatchEvent(new CustomEvent(Constants.EVENT_SAVE_ENTITY_BTN_CLICK))}
        >
          <FormattedMessage id="au.breadcrumbs.save" />
        </div>}
        {showCancelBtn && <div className={styles.button} onClick={backBtnClick}>
          <FormattedMessage id="au.breadcrumbs.cancel" />
        </div>}

        <div className={styles.title}>{pageTitle}</div>
      </div>
    );
  }

}

export default injectIntl(Breadcrumbs);
