import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { VEHICLE_ID_PROP_NAME } from './constants';
import { HOME_PATH } from './constants';
import IntlProvider from './containers/IntlProvider';
import UnitProvider from './containers/UnitProvider';
import GlobalNavigation from './containers/GlobalNavigation';

import App from './containers/App';
// import ComponentGallery from './components/ComponentGallery';
// import MapGallery from './components/MapGallery';

import ConnectedAsset from './containers/Asset';
import ConnectedAssets from './containers/Assets';
import ConnectedAssetCurrentLocation from './containers/AssetCurrentLocation';
import ConnectedAssetData from './containers/AssetData';
import ConnectedAssetMetrics from './containers/AssetMetrics';
// import ConnectedAssetGeofences from './containers/AssetGeofences';
import ConnectedDashboard from './containers/Dashboard';
// import ConnectedGeoFences from './containers/GeoFences';
// import ConnectedGeoFencePage from './containers/GeoFencePage';
// import ScenarioBuilder from './containers/ScenarioBuilder';

import styles from './css/components/root.module.scss';

const VehiclesRoutes = ({ match }) => (
  <div className={styles.page}>
    <Switch>
      <Redirect exact from={match.path} to={match.path + '/details'} />
      <Route path={match.path + '/details'}                        component={ConnectedAssets} />
      {/* <Route path={match.path + `/:${VEHICLE_ID_PROP_NAME}/trips`} component={ConnectedAssetTrips} /> */}
      <Route path={match.path + `/:${VEHICLE_ID_PROP_NAME}`}       component={VehicleRoutes} />
    </Switch>
  </div>
);

const VehicleRoutes = props => {
  const { match } = props;
  return (
    <ConnectedAsset {...props}>
      <Switch>
        <Redirect exact from={match.path} to={match.path + '/metrics'} />
        <Route path={match.path + '/metrics'}                   component={ConnectedAssetMetrics} />
        <Route path={match.path + '/current-location'}          component={ConnectedAssetCurrentLocation} />
        <Route path={match.path + '/data'}                      component={ConnectedAssetData} />
        {/* <Route path={match.path + '/geofence_history'} component={ConnectedAssetGeofences} /> */}
        <Route component={ConnectedAssetMetrics} />
      </Switch>
    </ConnectedAsset>
  );
};

// const GeofenceRoutes = ({ match }) => (
//   <Switch>
//     <Route path={match.path + `/detail/:${GEOFENCE_ID_PROP_NAME}`} component={ConnectedGeoFencePage} />
//     <Route component={ConnectedGeoFences} />
//   </Switch>
// );

const Routes = (props) => {
  // const isDevEnv = process.env.NODE_ENV === 'development';
  return (
    <GlobalNavigation {...props}>
      <Switch>
        <Redirect exact from="/"        to={HOME_PATH} />
        <Route path={HOME_PATH}         component={ConnectedDashboard} />
        <Route path="/vehicles"          component={VehiclesRoutes} />
        {/* <Route path="/geofences"         component={GeofenceRoutes} /> */}
        {/* isDev && <Route path="/component_gallery" component={ComponentGallery} /> */}
        {/* isDev && <Route path="/map_gallery"       component={MapGallery} /> */}
        {/* isDev && <Route paty="/scenarios"         component={ScenarioBuilder} /> */}
      </Switch>
    </GlobalNavigation>
  );
}

export default class Root extends Component {
  render() {
    const { store, history } = this.props;

    return (
      <Provider store={store}>
        <IntlProvider>
          <UnitProvider>
            <Router history={history}>
              <App>
                <Switch>
                  <Route path={`${HOME_PATH}/:page`} component={Routes} />
                  <Route                             component={Routes} />
                </Switch>
              </App>
            </Router>
          </UnitProvider>
        </IntlProvider>
      </Provider>
    );
  }
}
