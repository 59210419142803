// candidate for au-core
import React, { PureComponent } from 'react';
import * as T from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider as IP } from 'react-intl';

import PropProvider from '../PropProvider';
import localeData from '../locales';

class IntlProvider extends PureComponent {
  static propTypes = {
    language: T.string.isRequired
  }

  render() {
    let { language, children } = this.props;
    const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

    let messages;
    if (localeData[language]) {
      messages = localeData[language];
    }
    else if (localeData[languageWithoutRegionCode]) {
      messages = localeData[languageWithoutRegionCode];
      language = languageWithoutRegionCode;
    }
    else {
      messages = localeData['en'];
      language = 'en';
    }

    return (
      <IP locale={language} messages={messages}>
        {children}
      </IP>
    );
  }
}

export default connect(
  state => PropProvider(state).IntlProvider()
)(IntlProvider);
