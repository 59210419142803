import React from 'react';
import * as T from 'prop-types';
import AssetCheckList from './AssetCheckList';
import { AssetProps } from '../AuPropTypes';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import styles from '../css/components/asset_occupancy.module.scss';

class AssetOccupancy extends React.Component {
  static propTypes = {
    asset: T.shape(AssetProps),
  };

  renderALabel(labelId) {
    return <div className={this.props.fullScreen ? styles.compact : styles.full}>
      <AutoIntl displayId={labelId} className={styles.label} tag="div"/>
    </div>;
  }

  occupancySorter(a, b) {
    let res = a.row - b.row;
    return res === 0 ? a.seat - b.seat : res;
  }

  renderOccupancy(seats=[]) {
    const occupancy = [];
    seats.sort(this.occupancySorter).forEach((seat) => {
      occupancy.push(
        <div className={styles.occupancy_container} key={"occupancy_id" + seat.row + seat.seat}>
          <div className={styles.value}>
            {seat.role.toLowerCase()}
          </div>
          <div className={styles.value}>
            {seat.row}
          </div>
          <div className={styles.value}>
            {seat.seat}
          </div>
          <div className={styles.value}>
            {seat.occupied.toString()}
          </div>
          <div className={styles.value}>
            {seat.buckled.toString()}
          </div>
        </div>
      );
    });

    return occupancy;
  }

  render() {
    const asset = this.props.asset.toJS();
    const { seats } = asset;

    return (
      <div className={styles.container}>
        <div className={styles.checklist}>
          <AutoIntl displayId="au.vehicle.tiresAndDoors" className={styles.header} tag="div"/>
          <AssetCheckList
            asset={asset}
          />
          <AutoIntl displayId="au.vehicle.occupancy" className={styles.header} tag="div"/>
          <div className={styles.occupancy_label}>
            {this.renderALabel("au.vehicle.role")}
            {this.renderALabel("au.vehicle.row")}
            {this.renderALabel("au.vehicle.seat")}
            {this.renderALabel("au.vehicle.vacancy")}
            {this.renderALabel("au.vehicle.seatbelt")}
          </div>
          <div>{ this.renderOccupancy(seats) }</div>
        </div>
      </div>
    );
  }
}

export default AssetOccupancy;