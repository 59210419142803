import React from 'react';

import { ResponsiveTablePropTypes } from '../AuPropTypes';
import { formatterFactory } from '../utils/formatters';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuComponent from '@au/core/lib/components/elements/AuComponent';
import ResponsiveTable from '@au/core/lib/components/elements/ResponsiveTable';

import GroupSelector from './GroupSelector';

import styles from '../css/components/assets.module.scss';

export default class Assets extends AuComponent {
  static propTypes = {
    ...ResponsiveTablePropTypes
  }

  render() {
    const { tableDef, tableData, groupId, showGroupSelector } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <AutoIntl className={styles.title} displayId="au.section.title.vehicles" tag="h2" />
          { showGroupSelector && groupId &&
            <GroupSelector groupId={groupId} onClick={this.props.actions.showGroupsDialog} />
          }
        </div>
        <ResponsiveTable
          tableDef={tableDef}
          tableData={tableData}
          formatters={formatterFactory}
        />
      </div>
    );
  }
}
